import React from "react";
import Colors from "../constants/Colors";

import { useWindowSize } from "../utils/customHooks";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import moment from "moment";

// const demoTime = new Date(Date.now() + 300000);

const Reset = (props) => {
  const [width, height] = useWindowSize();

  const [loading, setLoading] = React.useState(false);
  const [lock, setLock] = React.useState(false);

  const [show_pass_1, setShow_pass_1] = React.useState(false);

  const [pass_1, setPass_1] = React.useState("");
  const [pass_2, setPass_2] = React.useState("");
  const [err, setErr] = React.useState("");
  const [good, setGood] = React.useState("");

  const [time, setTime] = React.useState(new Date(Date.now() + 15000));
  // let time = 300000;

  var valid = false;

  const passVal = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{7,50}$/;

  if (
    !lock &&
    pass_1 === pass_2 &&
    pass_1.length > 8 &&
    pass_2.length > 8 &&
    new Date() < time &&
    passVal.test(String(pass_1))
  ) {
    valid = true;
  } else {
    valid = false;
  }

  React.useEffect(() => {
    props.setHideNav(true);

    var newInterval = setTimeout(() => {
      setLock(true);
      console.log("LOCKED!!!");
    }, time - new Date());

    return () => {
      clearTimeout(newInterval);
      props.setHideNav(false);
    };
  }, []);

  const styles = {
    icon_: { fontSize: 20, margin: 0, color: "white" },
    inputStyles: {
      height: 32,
      minWidth: 200,
      border: `1px ${Colors.primary} solid`,
      textAlign: "left",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRight: "0px",
      marginLeft: 0,
      marginRight: 0,
    },
    hideBtn: {
      margin: 0,
      // display: "inline-block",
      opacity: new Date() > time || loading ? 0.3 : 1,
      height: 32,
      width: 30,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginRight: 0,
      marginBottom: 15,
      backgroundColor: Colors.primary,
    },
    listStyles: {
      border: `1px ${Colors.primary} solid`,
      borderRadius: 5,
      marginRight: 0,
      marginLeft: 0,
      marginTop: 0,
      minWidth: 50,
    },
    errMsg: {
      color: good ? Colors.good : Colors.err,
      margin: 0,
      textAlign: "Left",
    },
  };

  return (
    <React.Fragment>
      {loading ? (
        <LogoLoading />
      ) : (
        <React.Fragment>
          <div
            style={{
              backgroundColor: Colors.primary_darker,
              padding: 5,
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // cursor: "pointer",
              }}
            >
              <img src={"/logo.svg"} alt="logo" style={{ height: 35 }} />
              {/* {width < 1090 ? null : ( */}
              <React.Fragment>
                <div style={{ marginLeft: 10, color: "white" }}>
                  BidSquawk Marketplace
                </div>
              </React.Fragment>
              {/* )} */}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              padding: 50,
              margin: "auto",
              height: 600,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 50,
                margin: "auto",
              }}
            >
              <p
                style={{
                  color: Colors.primary,
                  fontWeight: "bold",
                  fontSize: 16,
                  margin: 0,
                }}
              >
                Please enter your new password below.
              </p>
              <p style={{ color: lock ? Colors.err : Colors.primary }}>
                {`This link will expire${lock ? "d" : ""} at ${moment(
                  time
                ).format("MMMM Do YYYY, h:mm a")}`}
              </p>
              <p style={{ color: Colors.err, fontWeight: "bold" }}>{err}</p>
              {/* <input
                className={"textInput"}
                placeholder="New Password"
                style={{ margin: 10, border: `1px solid ${Colors.primary}` }}
              />
              <input
                className={"textInput"}
                placeholder="Retype Password"
                style={{ margin: 10, border: `1px solid ${Colors.primary}` }}
              />
              <br /> */}

              <div
                style={
                  {
                    // display: "flex",
                    // justifyContent: "flex-start",
                    // alignItems: "center",
                    // flexWrap: "wrap",
                  }
                }
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <input
                    className={"textInput"}
                    style={{
                      ...styles.inputStyles,
                      opacity: loading ? 0.3 : 1,
                    }}
                    disabled={lock || loading}
                    type={show_pass_1 && !loading ? "text" : "password"}
                    name="new password"
                    placeholder={"Type Password"}
                    maxLength="60"
                    max="60"
                    value={pass_1}
                    onChange={(value) => {
                      setErr("");
                      setGood("");
                      setPass_1(value.target.value);
                    }}
                  />
                  <button
                    className={
                      lock && valid && !loading
                        ? "universalBtn"
                        : "universalBtnStatic"
                    }
                    style={styles.hideBtn}
                    disabled={lock || loading}
                    onClick={() => setShow_pass_1((state) => !state)}
                  >
                    {!show_pass_1 ? (
                      <AiFillEyeInvisible style={styles.icon_} />
                    ) : (
                      <AiFillEye style={styles.icon_} />
                    )}
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <input
                    className={"textInput"}
                    style={{
                      ...styles.inputStyles,
                      opacity: loading ? 0.3 : 1,
                    }}
                    disabled={lock || loading}
                    type={show_pass_1 && !loading ? "text" : "password"}
                    name="retype password"
                    placeholder={"Re-Type Password"}
                    maxLength="60"
                    max="60"
                    value={pass_2}
                    onChange={(value) => {
                      setErr("");
                      setGood("");
                      setPass_2(value.target.value);
                    }}
                  />
                  <button
                    className={
                      lock && valid && !loading
                        ? "universalBtn"
                        : "universalBtnStatic"
                    }
                    style={styles.hideBtn}
                    disabled={lock || loading}
                    onClick={() => setShow_pass_1((state) => !state)}
                  >
                    {!show_pass_1 ? (
                      <AiFillEyeInvisible style={styles.icon_} />
                    ) : (
                      <AiFillEye style={styles.icon_} />
                    )}
                  </button>
                </div>
              </div>
              <button
                className={
                  lock && valid && !loading
                    ? "universalBtn"
                    : "universalBtnStatic"
                }
                style={{
                  backgroundColor: Colors.primary,
                  color: "white",
                  opacity: lock ? 0.3 : 1,
                }}
                onClick={() => {
                  if (!valid) return;
                  if (loading) return;
                  setShow_pass_1(false);
                  // updatePassword();
                }}
                disabled={lock || loading}
              >
                Save
              </button>
              <p
                style={{
                  marginTop: 10,
                  marginRight: "auto",
                  marginLeft: "auto",
                  fontSize: 10,
                  textAlign: "center",
                  width: 200,
                }}
              >
                By clicking the "Save" button above you agree to our{" "}
                <a
                  href={"/legal.html"}
                  style={{ fontSize: 10, fontWeight: "bold" }}
                  target={"_blank"}
                >
                  Terms and Conditions.
                </a>
              </p>
              {lock ? (
                <p
                  style={{
                    marginTop: 10,
                    marginRight: "auto",
                    marginLeft: "auto",
                    // fontSize: 10,
                    fontWeight: "bold",
                    textAlign: "center",
                    width: 200,
                  }}
                >
                  Your password reset link has expired please{" "}
                  <a
                    href={"/contact.html"}
                    style={{
                      fontWeight: "bolder",
                      textDecoration: "underline",
                    }}
                    target={"_blank"}
                  >
                    contact us
                  </a>{" "}
                  for a new link.
                </p>
              ) : null}
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const LogoLoading = (props) => (
  <div
    style={{
      width: "100%",
      height: "100vh",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <img
      className={"App-logo"}
      src={"/logo.svg"}
      alt="logo"
      style={{
        height: 100,
        margin: 10,
        marginRight: "auto",
        marginLeft: "auto",
      }}
    />
  </div>
);

export default Reset;
