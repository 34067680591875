import React, {
  forwardRef,
  useImperativeHandle,
  createRef,
  useRef,
} from "react";

import Colors from "../../constants/Colors";
import { FaChevronRight } from "react-icons/fa";
import { MdEmail, MdClose } from "react-icons/md";
import {
  useIsMountedRef,
  useWindowSize,
  useRefDimensions,
} from "../../utils/customHooks";
import { useHistory, useLocation } from "react-router-dom";

import AlertsMsgs from "./AlertsMsgs";

import "./AlertsTab.css";
import HoverComp from "../HoverComp";
import { BsArrowLeftRight } from "react-icons/bs";

const maxWidth = 1200;
const trans_time = "500ms";

const AlertsTab = forwardRef((props, ref) => {
  const location = useLocation();
  const isMountRef = useIsMountedRef();
  const history = useHistory();
  const [width, height] = useWindowSize();
  const alert_msgs_refFunc = useRef();

  const btnRef = createRef();
  // const btnDimensions = useRefDimensions(btnRef);
  // const [show, setShow] = React.useState(false);
  const [show, setShow] = React.useState(false);

  const [showTab, setShowTab] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);

  const [stopHeight, setStopHeight] = React.useState(0);
  const [stopHeightNext, setStopHeightNext] = React.useState(0);
  const [responsiveHeight, setResponsiveHeight] = React.useState(0);

  const [btnData, setBtnData] = React.useState({});

  const [fromDir, setFromDir] = React.useState(true);

  useImperativeHandle(ref, () => ({
    toggleTab(state, type, payload) {
      // console.log({ payloadData: payload });
      if (type === "MSG") {
        // if (payload.data.chatId) {
        //   props.setChatId(payload.data.chatId);
        // }
        alert_msgs_refFunc.current.refreshMsgs();
      }
      setShow(state);
      alert_msgs_refFunc.current.setTab(
        type ? (type === "MSG" ? 2 : type === "ALERT" ? 1 : null) : null
      );
    },
    updateMsg(data) {
      console.log({ data });
    },
    positionAlertTab() {
      checkNavBarHeight();
    },
    refreshMsg() {
      alert_msgs_refFunc.current.refreshMsgs();
    },
  }));

  React.useEffect(() => {
    // console.log({ btnRef, data: btnRef.current.getBoundingClientRect() });
    const x = btnRef.current.getBoundingClientRect();
    setBtnData(x);
  }, [width]);

  React.useEffect(() => {
    window.addEventListener("scroll", checkNavBarHeight);

    checkNavBarHeight();

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  });

  React.useEffect(() => {
    checkNavBarHeight();
  }, [location.pathname]);

  const checkNavBarHeight = () => {
    if (!isMountRef.current) return;
    const ele = document.getElementById("navBar");
    if (ele) {
      setStopHeight(ele.getBoundingClientRect().height);
    } else {
      setStopHeight(0);
    }
    // const ele_1 = document.getElementById("secondaryNav");
    // if (ele_1) {
    //   const x =
    //     ele_1.getBoundingClientRect().top < 0
    //       ? 0
    //       : ele_1.getBoundingClientRect().top;
    //   setStopHeightNext(x);
    // } else {
    //   setStopHeightNext(0);
    // }
    const ele_2 = document.getElementById("responsive-navbar-nav");
  };

  return (
    <React.Fragment>
      <div
        style={{
          opacity: location.pathname === "/alerts" && !show ? 0.3 : 1,
          position: "fixed",
          // top: stopHeight + stopHeightNext,
          top: 57,
          zIndex: 201,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            ...(fromDir
              ? { borderRadius: "0px 0px 5px 0px" }
              : { borderRadius: "0px 5px 0px 0px" }),
            position: "relative",
          }}
        >
          <div
            style={{
              marginLeft: show
                ? fromDir
                  ? 0
                  : width > maxWidth
                  ? width - maxWidth
                  : 0
                : fromDir
                ? -width
                : width + width,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              ...(fromDir
                ? { borderRadius: "0px 0px 5px 0px" }
                : { borderRadius: "0px 5px 0px 0px" }),
              border: "1px solid white",
              ...(fromDir
                ? { width: width - (width < 870 ? 0 : 10) }
                : { width: width - (width < 870 ? 0 : 10) }),
              maxWidth,
              height: height - (stopHeight + stopHeightNext + responsiveHeight),
              transition: `${trans_time} ease-in-out`,
              backgroundColor: Colors.primary,
              zIndex: 56,
              padding: 5,
            }}
          >
            <div
              id="holderAlerts"
              style={{
                overflow: "hidden",
                borderRadius: 5,
                height: "100%",
                width: "100%",
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              <AlertsMsgs
                setShow={setShow}
                show={show}
                ref={alert_msgs_refFunc}
                setTotalCount={setTotalCount}
                chatId={props.chatId}
                alertId={props.alertId}
                setAlertId={props.setAlertId}
                setChatId={props.setChatId}
                msgFunction={props.msgFunction}
              />
            </div>
          </div>
          <div
            id={"alerts_toggle"}
            ref={btnRef}
            style={{
              zIndex: 60,
              backgroundColor: Colors.primary,
              position: "absolute",
              padding: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: `1px solid white`,
              borderBottom: (show ? "0px" : "1px") + " solid white",
              borderRadius: `0px 5px ${show ? "0px" : "5px"} 0px`,
              borderLeft: "0px",
              cursor: "pointer",
              transition: `${trans_time} ease-in-out`,
              right: show ? 0 : -34,
            }}
            onClick={() => {
              // alert_msgs_refFunc.current.refreshMsgs();
              setShow((state) => !state);
            }}
          >
            <MdClose
              style={{
                color: "white",
                fontSize: 25,
                opacity: show ? 1 : 0,
                transition: `${trans_time} ease-in-out`,
                ...(fromDir
                  ? {
                      transform: show
                        ? "rotate(-180deg) scale(100%)"
                        : "rotate(0deg) scale(0%)",
                    }
                  : {
                      transform: show
                        ? "rotate(0deg) scale(100%)"
                        : "rotate(-180deg) scale(0%)",
                    }),
              }}
            />
            <MdEmail
              style={{
                color: "white",
                fontSize: show ? 0 : 25,
                opacity: show ? 0 : 1,
                transition: `${trans_time} ease-in-out`,
                ...(fromDir
                  ? {
                      transform: show
                        ? "rotate(-180deg) scale(0%)"
                        : "rotate(0deg) scale(100%)",
                    }
                  : {
                      transform: show
                        ? "rotate(0deg) scale(0%)"
                        : "rotate(-180deg) scale(100%)",
                    }),
              }}
            />
            <HoverComp
              eleId={"alerts_toggle"}
              text={`Toggle Notification and Messages Panel`}
              style={show ? { right: 5, top: 5 } : { left: 30, top: 5 }}
            />
            {show || totalCount < 1 ? null : (
              <div
                style={{
                  position: "absolute",
                  margin: 0,
                  bottom: -15,
                  // right: 0,
                  backgroundColor: Colors.good,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 20,
                  minWidth: 20,
                  minHeight: 20,
                }}
              >
                <p
                  style={{
                    color: Colors.primary,
                    margin: 3,
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  {totalCount > 99 ? "99+" : totalCount}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

export default AlertsTab;
