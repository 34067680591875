/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

import { validateEmail, phoneValidator } from "../../utils/helper";

import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import { callServer } from "../../store/helpers/actionHelper";

import Colors from "../../constants/Colors";

const ForgotPass = (props) => {
  const [type, setType] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");

  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");

  const [secure, setSecure] = useState(true);

  const [passShow, setPassShow] = React.useState(true);

  const [complete, setComplete] = useState(false);

  const [screen, setScreen] = useState("password");

  React.useEffect(() => {
    props.setShowBack(true);
    return () => props.setShowBack(false);
  });

  const sendResetRequest = async () => {
    props.setLoading(true);
    try {
      const validEmail = await validateEmail(email);
      if (!validEmail) {
        throw { msg: "Please enter a valid email." };
      }

      const resData = await callServer({
        apiMethod: "post",
        endPoint: "server/auth/request-reset",
        noToken: true,
        theBody: { type: "email", email: email.toLowerCase(), phone },
      });

      props.setLoading(false);
      props.setShowAuth(3.1);
      setErr("");
    } catch (err) {
      console.log({ err: err });
      if (err) {
        if (err.msg) {
          setErr(err.msg);
        } else if (err.message) {
          setErr(err.message);
        } else {
          setErr(err);
        }
      }
      props.setLoading(false);
    }
  };

  const resetPassword = async () => {
    props.setLoading(true);
    setPassShow(true);

    try {
      const resData = await callServer({
        apiMethod: "post",
        endPoint: "server/auth/password-reset",
        theBody: {
          reset_token: token,
          email: email.toLowerCase().trim(),
          password,
          confirmPassword: password,
        },
      });

      props.setLoading(false);
      setComplete(true);
    } catch (err) {
      console.log({ err });
      let newErr = "";
      if (Array.isArray(err.msg)) {
        newErr = err.msg[err.msg.length - 1].msg;
        setErr(newErr);
      } else {
        setErr(err.message);
      }
      props.setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {props.showAuth === 3 ? (
        <div
          style={{
            color: "white",
            display: "flex",
            flexDirection: "column",
            marginBottom: 0,

            alignItems: "center",
            justifyContent: "center",
          }}
          // onClick={() => props.setType("reset")}
        >
          <p
            style={{
              color: "white",
              fontSize: 20,
              marginBottom: 0,
              textAlign: "center",
            }}
          >
            Password Reset
          </p>
          <p
            style={{
              color: "white",
              fontSize: 15,
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            Step 1 of 2
          </p>
          <p style={{ color: Colors.err, fontSize: 15, textAlign: "center" }}>
            {err}
          </p>
          <p style={{ color: "white", fontSize: 15, textAlign: "center" }}>
            Please enter your email below
          </p>
          <input
            className={"textInput"}
            id={"sel"}
            style={{}}
            disabled={props.loading}
            type="email"
            name="Email"
            placeholder={"Email"}
            maxLength="60"
            max="60"
            value={email}
            onChange={(value) => {
              setEmail(value.target.value);
            }}
          />

          <button
            className={
              props.loading || email.length < 5
                ? "universalBtnStatic"
                : "universalBtn"
            }
            style={{
              marginTop: 20,
              backgroundColor: Colors.good,
              opacity: props.loading || email.length < 5 ? 0.5 : 1,
            }}
            onClick={() => {
              if (props.loading || email.length < 5) {
                return;
              }
              sendResetRequest();
            }}
          >
            Next
          </button>
        </div>
      ) : props.showAuth === 3.1 ? (
        <div
          style={{
            color: "white",
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
            justifyContent: "center",
          }}
          // onClick={() => props.setType("reset")}
        >
          {!complete ? (
            <React.Fragment>
              <p
                style={{
                  color: "white",
                  fontSize: 20,
                  marginBottom: 0,
                  textAlign: "center",
                }}
              >
                Password Reset
              </p>
              <p
                style={{
                  color: "white",
                  fontSize: 15,
                  textAlign: "center",
                  marginBottom: 20,
                }}
              >
                Step 2 of 2
              </p>
              <p
                style={{ color: Colors.err, fontSize: 15, textAlign: "center" }}
              >
                {err}
              </p>
              <p style={{ color: "white", fontSize: 15, textAlign: "center" }}>
                A reset token was sent to your email, please enter the token
                below and your new password.
              </p>
              <input
                className={"textInput"}
                id={"sel"}
                style={{}}
                disabled={props.loading}
                type="number"
                name="token"
                placeholder={"Reset Token"}
                maxLength="6"
                max="6"
                value={token}
                onChange={(value) => {
                  setToken(value.target.value);
                }}
              />
              <input
                className={"textInput"}
                id={"sel"}
                style={{}}
                disabled={props.loading}
                type={passShow ? "password" : "text"}
                name="password"
                placeholder={"New Password"}
                maxLength="60"
                max="60"
                value={password}
                onChange={(value) => {
                  setPassword(value.target.value);
                }}
              />
              <div
                style={{
                  paddingTop: 10,
                  cursor: "pointer",
                  opacity: props.loading ? 0.3 : 1,
                }}
                onClick={() => {
                  if (props.loading) return;
                  setPassShow((state) => !state);
                }}
              >
                {passShow ? (
                  <AiFillEyeInvisible
                    color={"white"}
                    style={{ fontSize: 25 }}
                  />
                ) : (
                  <AiFillEye color={"white"} style={{ fontSize: 25 }} />
                )}
              </div>
              <button
                className={"universalBtn"}
                style={{
                  marginTop: 20,
                  backgroundColor: Colors.good,
                  opacity: props.loading ? 0.5 : 1,
                }}
                onClick={() => {
                  if (props.loading) {
                    return;
                  }
                  resetPassword();
                }}
              >
                Reset
              </button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p style={{ color: "white", fontSize: 20, textAlign: "center" }}>
                Password Reset Successfully
              </p>
              <button
                className={"universalBtn"}
                style={{
                  marginTop: 20,
                  backgroundColor: Colors.good,
                  opacity: props.loading ? 0.5 : 1,
                }}
                onClick={() => {
                  if (props.loading) {
                    return;
                  }
                  props.setShowAuth(1);
                }}
              >
                Login
              </button>
            </React.Fragment>
          )}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default ForgotPass;
