/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Colors from "../constants/Colors";

import { MdLocationOn } from "react-icons/md";
import { BsChevronDown } from "react-icons/bs";
import { callServer } from "../store/helpers/actionHelper";
import { useIsMountedRef, useWindowSize } from "../utils/customHooks";
import { useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";

/**
 * 
 * PROPS 
 * 
 *    visible = boolean
      setVisible = change state
      width = int
      location = state
      changeLocation = change state
      setCategoryReady = boolean
 *
 */

const LocationSearch = (props) => {
  const isMountRef = useIsMountedRef();
  const [width, height] = useWindowSize();

  const userInfo = useSelector((state) => state.auth.userInfo);
  const online = useSelector((state) => state.auth.token);
  const darkMode = useSelector((state) => state.display.darkMode);

  const [id, setId] = React.useState(uuidv4());
  const [closeAll, setCloseAll] = React.useState(Date.now());

  const [countries, setCountries] = React.useState([]);
  const [states, setState] = React.useState([]);
  const [stateSelected, setStateSelected] = React.useState([]);
  const [cityTown, setCityTown] = React.useState([]);
  const [cityTownSelected, setCityTownSelected] = React.useState([]);

  React.useEffect(() => {
    toggle();
  }, [props.visible]);

  React.useEffect(() => {
    finish();
  }, [countries.length, online]);

  React.useEffect(() => {
    // console.log("FETCH LOCATION");
    fetchCountries();
  }, []);

  async function fetchCountries() {
    try {
      const countriesResData = await callServer({
        endPoint: "server/location/fetch-countries",
        apiMethod: "get",
      });
      if (!isMountRef.current) return;
      // console.log({ countriesResData });
      setCountries([...countriesResData.countries]);
    } catch (error) {
      console.log({ error });
    }
  }

  async function fetchStateCityTown(iso2, state) {
    setStateSelected(state);
    if (iso2 && !state) {
      setState([]);
      setCityTown([]);
    } else if (iso2 && state) {
      setCityTown([]);
    }
    try {
      const countryInfoResData = await callServer({
        endPoint: `server/location/country-info?iso2=${iso2}&state=${
          state || ""
        }`,
        apiMethod: "get",
      });

      if (iso2 && !state) {
        setState([...countryInfoResData.states]);
      } else if (iso2 && state) {
        setCityTown([...countryInfoResData.cityTown]);
      }
    } catch (error) {
      console.log({ error });
    }
  }

  const show = () => {
    if (!isMountRef.current) return;

    const ele = document.getElementById(id);
    if (ele) {
      ele.style.display = "block";
      ele.style.opacity = 1;
      setTimeout(() => {}, 100);
    }
  };
  const hide = () => {
    if (!isMountRef.current) return;

    const ele = document.getElementById(id);
    if (ele) {
      ele.style.opacity = 0;
      setTimeout(() => {
        ele.style.display = "none";
      }, 200);
    }
  };
  const toggle = () => {
    if (props.visible) {
      show();
    } else {
      hide();
    }
  };

  const finish = () => {
    if (
      countries.length > 0 &&
      props.changeLocation &&
      props.setLocationReady
    ) {
      if (!isMountRef.current) return;

      props.setLocationReady(true);

      if (props.notSearch) {
        // console.log("INSIDE LOCATION", props.location, 123);
        if (props.location) {
          props.changeLocation(props.location);
        }
      } else if (!props.location && !props.notSearch) {
        // console.log({ title: "All", value_0: "all", value_1: "all" });
        props.changeLocation({
          title: "All",
          value_0: online ? userInfo.location.value_0 : "all",
          value_1: "all",
        });
      } else {
        props.changeLocation({
          title: "All",
          value_0: online ? userInfo.location.value_0 : "all",
          value_1: "all",
        });
      }
    }
  };

  return (
    <React.Fragment>
      <div
        id={id}
        className={"shadow"}
        style={{
          opacity: 0,
          position: "absolute",
          // display: "none",
          // left: -40,
          left: width > 667 ? -40 : -40,
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: 1,
          zIndex: 58,
          padding: 5,
          backgroundColor: "white",
          width: props.width
            ? props.width
            : width >= 1200
            ? 380
            : width < 480
            ? 210
            : 300,
          // width: "100%",
          // maxWidth: 1000,
          // width: width - 40 > 400 ? 400 : width - 40,
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          borderTop: "1px solid #ccc",
          transition: "200ms",
          top: "100%",
          overflow: "hidden",
          ...props.styles,
        }}
      >
        {countries.length > 0 ? (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "1px solid #ccc",
                paddingBottom: 5,
                marginBottom: 5,
              }}
            >
              <MdLocationOn
                color={"#ccc"}
                style={{
                  // fontSize: 15,
                  color: Colors.primary,
                  marginRight: 10,
                }}
              />
              {props.notSearch && !props.location ? (
                <p
                  style={{
                    fontSize: 12,
                    margin: 0,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Choose your location
                </p>
              ) : props.location.value_0 === "all" ? (
                <p
                  style={{
                    fontSize: 12,
                    margin: 0,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  All Locations
                </p>
              ) : (
                <p
                  style={{
                    fontSize: 12,
                    margin: 0,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {props.location.value_0.toUpperCase()} -{" "}
                  {props.location.title}
                  {props.location.value_1 ? (
                    <React.Fragment>
                      {` - ${
                        props.location.value_1 === "all"
                          ? `All`
                          : props.location.value_1
                      }`}
                    </React.Fragment>
                  ) : null}
                  {props.location.value_2 ? (
                    <React.Fragment>
                      {` - ${
                        props.location.value_2 === "all"
                          ? `All`
                          : props.location.value_2
                      }`}
                    </React.Fragment>
                  ) : null}
                </p>
              )}
            </div>
            <div
              id={"sel"}
              style={{
                overflow: "scroll",
                borderRadius: "10px !important",
                scrollbarWidth: "thin !important ",
                maxHeight: 220,
                overflowX: "hidden",
              }}
            >
              {props.notSearch ? null : (
                <div
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      props.location.value_0 === "all"
                        ? Colors.primary
                        : "white",
                    color:
                      props.location.value_0 === "all"
                        ? "white"
                        : Colors.primary,
                    fontWeight:
                      props.location.value_0 === "all" ? "bold" : "normal",
                    padding: 5,
                    borderRadius: 5,
                    // fontSize: 15,
                  }}
                  onClick={() => {
                    setCloseAll("all");
                    props.changeLocation({
                      title: "All",
                      value_0: "all",
                      value_1: "all",
                    });
                    props.setVisible(false);
                  }}
                >
                  All
                </div>
              )}

              {countries.map((loc, index) => {
                // console.log(loc.iso2);
                // if (!["JM", "US"].includes(loc.iso2))
                //   return <React.Fragment key={index}></React.Fragment>;

                return (
                  <Location
                    key={index}
                    loc={loc}
                    location={props.location}
                    general={props.general}
                    changeLocation={props.changeLocation}
                    notSearch={props.notSearch}
                    closeAll={closeAll}
                    setCloseAll={setCloseAll}
                    states={states}
                    setState={setState}
                    stateSelected={stateSelected}
                    setStateSelected={setStateSelected}
                    cityTown={cityTown}
                    setCityTown={setCityTown}
                    cityTownSelected={cityTownSelected}
                    setCityTownSelected={setCityTownSelected}
                    fetchStateCityTown={fetchStateCityTown}
                  />
                );
              })}
            </div>
          </React.Fragment>
        ) : (
          <p style={{ textAlign: "center", margin: 0 }}>Loading Location...</p>
        )}
        {props.setVisible ? (
          <button
            className="universalBtn"
            style={{
              width: 100,
              backgroundColor: Colors.good,
              borderWidth: 0,
              borderRadius: 200,
              marginLeft: "auto",
              marginRight: "auto",
              color: Colors.primary,
              textAlign: "center",
              fontWeight: "bold",
              padding: 0,
            }}
            onClick={() => {
              props.setVisible(false);
            }}
          >
            Ok
          </button>
        ) : null}
      </div>
    </React.Fragment>
  );
};

const Location = (props) => {
  const [showLocations, setShowLocations] = React.useState(false);
  const [showLocationCityTown, setShowLocationCityTown] = React.useState(null);
  const darkMode = useSelector((state) => state.display.darkMode);

  React.useEffect(() => {
    // console.log({ locationProps: props.location });
    if (props.closeAll !== props.loc.iso2) {
      setShowLocations(false);
    }
  }, [props.closeAll]);

  React.useEffect(() => {
    if (showLocations) {
      props.fetchStateCityTown(props.loc.iso2);
    }
  }, [showLocations]);

  return (
    <React.Fragment>
      <div
        //   key={key}
        //   value={x.value}
        style={{
          cursor: "pointer",
          padding: 5,
          // fontSize: 15,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor:
            props.location.value_0 === props.loc.iso2
              ? Colors.primary
              : "white",
          fontWeight:
            props.location.value_0 === props.loc.iso2 ? "bold" : "normal",
          color:
            props.location.value_0 === props.loc.iso2
              ? "white"
              : Colors.primary,
          borderRadius: 5,
        }}
        onClick={() => {
          props.setCloseAll(props.loc.iso2);
          if (props.loc.states <= 0) {
            props.changeLocation({
              title: props.loc.name,
              value_0: props.loc.iso2.toUpperCase(),
              value_1: "all",
            });
          } else {
            setShowLocations((state) => !state);
          }
        }}
      >
        <p style={{ marginBottom: 0 }}>{props.loc.name}</p>
        {props.loc.states > 0 ? (
          <div style={{}}>
            <BsChevronDown
              color={"#ccc"}
              style={{
                transform: showLocations ? "rotateX(0deg)" : "rotateX(180deg)",
                transition: "200ms",
                // fontSize: 15,
                color:
                  props.location.value_0 === props.loc.iso2
                    ? "white"
                    : Colors.primary,
                marginRight: 10,
              }}
            />
          </div>
        ) : null}
      </div>

      {showLocations && props.loc.states > 0 ? (
        <div
          className="shadow"
          id="Location"
          style={{
            // border: "1px solid " + Colors.primary,
            borderRadius: 5,
            padding: 5,
            marginLeft: 10,
            marginRight: 10,
            marginTop: 10,
            marginBottom: 20,
          }}
        >
          {props.states.length > 0 ? (
            <>
              {props.notSearch ? null : (
                <div
                  key={props.loc.value_1}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: 5,
                    marginBottom: 1,
                    borderRadius: 5,
                    fontWeight:
                      props.location.value_0 === props.loc.iso2 &&
                      props.location.value_1 === "all"
                        ? "bold"
                        : "normal",
                    // marginLeft: 20,
                    // fontSize: 15,
                    backgroundColor:
                      props.location.value_0 === props.loc.iso2 &&
                      props.location.value_1 === "all"
                        ? Colors.primary
                        : "white",
                    color:
                      props.location.value_0 === props.loc.iso2 &&
                      props.location.value_1 === "all"
                        ? "white"
                        : Colors.primary,
                  }}
                  onClick={() => {
                    props.changeLocation({
                      title: props.loc.name,
                      value_0: props.loc.iso2.toUpperCase(),
                      value_1: "all",
                    });
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      marginLeft: 0,
                      color:
                        props.location.value_0 === props.loc.iso2 &&
                        props.location.value_1 === "all"
                          ? "white"
                          : Colors.primary,
                    }}
                  >
                    {`All Locations in ${props.loc.name}`}
                  </p>
                </div>
              )}

              {props.states.map((loc, index) => {
                return (
                  <React.Fragment key={index}>
                    {/* {loc.name}-{loc.city_town} */}
                    <Location_1
                      key={index}
                      location={props.location}
                      general={props.general}
                      loc={loc}
                      notSearch={props.notSearch}
                      country_code={props.loc.iso2}
                      country_name={props.loc.name}
                      showLocationCityTown={showLocationCityTown}
                      setShowLocationCityTown={setShowLocationCityTown}
                      changeLocation={props.changeLocation}
                      fetchStateCityTown={props.fetchStateCityTown}
                      cityTown={props.cityTown}
                      setCityTown={props.setCityTown}
                      cityTownSelected={props.cityTownSelected}
                      setCityTownSelected={props.setCityTownSelected}
                    />
                  </React.Fragment>
                );
              })}
            </>
          ) : (
            <React.Fragment>...Loading</React.Fragment>
          )}
        </div>
      ) : null}
    </React.Fragment>
  );
};

const Location_1 = (props) => {
  const [showLocations, setShowLocations] = React.useState(false);
  const darkMode = useSelector((state) => state.display.darkMode);

  React.useEffect(() => {
    if (props.showLocationCityTown === props.loc.name) {
      props.fetchStateCityTown(props.country_code, props.loc.name);
    }
  }, [props.showLocationCityTown]);

  return (
    <React.Fragment>
      <div
        id="Location_1"
        key={props.loc.value_1}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          padding: 5,
          margin: 5,
          borderRadius: 5,
          // marginBottom: 20,
          // marginLeft: 20,
          // fontSize: 15,
          backgroundColor:
            props.location.value_0 === props.country_code &&
            props.location.value_1 === props.loc.name
              ? Colors.primary
              : "white",
          color:
            props.location.value_0 === props.country_code &&
            props.location.value_1 === props.loc.name
              ? "white"
              : Colors.primary,
        }}
        onClick={() => {
          if (props.loc.city_town > 0) {
            if (props.showLocationCityTown === props.loc.name) {
              props.setShowLocationCityTown(null);
            } else {
              props.setShowLocationCityTown(props.loc.name);
            }
          } else {
            props.changeLocation({
              title: props.country_name,
              value_0: props.country_code,
              value_1: props.loc.name,
              value_2: "all",
            });
          }
          // setShowLocations((state) => !state);
        }}
      >
        <p
          style={{
            margin: 0,
            marginLeft: 0,
            color:
              props.location.value_0 === props.country_code &&
              props.location.value_1 === props.loc.name
                ? "white"
                : Colors.primary,
          }}
        >
          {props.loc.name}
        </p>
        {props.loc.city_town > 0 ? (
          <div style={{}}>
            <BsChevronDown
              color={"#ccc"}
              style={{
                transform: showLocations ? "rotateX(0deg)" : "rotateX(180deg)",
                transition: "200ms",
                // fontSize: 15,
                color:
                  props.location.value_0 === props.country_code &&
                  props.location.value_1 === props.loc.name
                    ? "white"
                    : Colors.primary,
                marginRight: 10,
              }}
            />
          </div>
        ) : null}
      </div>

      {props.showLocationCityTown === props.loc.name &&
      props.loc.city_town > 0 ? (
        <React.Fragment>
          {props.cityTown.length > 0 ? (
            <div
              className="shadow"
              style={{
                marginLeft: 10,
                marginRight: 10,
                // border: "2px solid " + Colors.primary,
                // borderRight: "0px",
                padding: 5,
                borderRadius: 8,
                marginBottom: 20,
                marginTop: 10,
              }}
            >
              {props.notSearch && !props.general ? null : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: 5,
                      borderRadius: 5,
                      marginBottom: 1,
                      // marginLeft: 20,
                      // fontSize: 15,
                      backgroundColor:
                        props.location.value_0 === props.country_code &&
                        props.location.value_1 === props.loc.name &&
                        props.location.value_2 === "all"
                          ? Colors.primary
                          : "white",
                      color:
                        props.location.value_0 === props.country_code &&
                        props.location.value_1 === props.loc.name &&
                        props.location.value_2 === "all"
                          ? "white"
                          : Colors.primary,
                    }}
                    onClick={() => {
                      props.changeLocation({
                        title: props.country_name,
                        value_0: props.country_code,
                        value_1: props.loc.name,
                        value_2: "all",
                      });
                      // setShowLocations((state) => !state);
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        color:
                          props.location.value_0 === props.country_code &&
                          props.location.value_1 === props.loc.name &&
                          props.location.value_2 === "all"
                            ? "white"
                            : Colors.primary,
                      }}
                    >
                      {`All Locations in ${props.loc.name}`}
                    </p>
                  </div>
                  <div
                    style={{
                      borderTop: "1px solid " + Colors.primary,
                      opacity: 0.2,
                    }}
                  />
                </>
              )}
              {props.cityTown.map((cityTown, index) => {
                return (
                  <React.Fragment key={index}>
                    {/* <p>{cityTown}</p> */}
                    <Location_2
                      key={index}
                      location={props.location}
                      changeLocation={props.changeLocation}
                      //
                      cityTown={cityTown}
                      state={props.loc.name}
                      country_code={props.country_code}
                      country_name={props.country_name}
                    />
                    {/* {props.cityTown.length !== index + 1 ? (
                      <div
                        style={{
                          borderTop: "1px solid " + Colors.primary,
                          opacity: 0.2,
                        }}
                      />
                    ) : null} */}
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            <p>...Loading</p>
          )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

const Location_2 = (props) => {
  const darkMode = useSelector((state) => state.display.darkMode);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          padding: 5,
          borderRadius: 5,
          marginBottom: 1,
          // marginLeft: 20,
          // fontSize: 15,
          backgroundColor:
            props.location.value_0 === props.country_code &&
            props.location.value_1 === props.state &&
            props.location.value_2 === props.cityTown
              ? Colors.primary
              : "white",
          color:
            props.location.value_0 === props.country_code &&
            props.location.value_1 === props.state &&
            props.location.value_2 === props.cityTown
              ? "white"
              : Colors.primary,
        }}
        onClick={() => {
          props.changeLocation({
            title: props.country_name,
            value_0: props.country_code,
            value_1: props.state,
            value_2: props.cityTown,
          });
        }}
      >
        <p
          style={{
            margin: 0,
            color:
              props.location.value_0 === props.country_code &&
              props.location.value_1 === props.state &&
              props.location.value_2 === props.cityTown
                ? "white"
                : Colors.primary,
          }}
        >
          {props.cityTown}
        </p>
        {/* {props.loc_1.value_3 ? (
          <div style={{}}>
            <BsChevronDown
              color={"#ccc"}
              style={{
                transform: showLocations ? "rotateX(0deg)" : "rotateX(180deg)",
                transition: "200ms",
                // fontSize: 15,
                color:
                  props.location.value_0 === props.loc.value_0 &&
                  props.location.value_1 === props.loc.value_1 &&
                  props.location.value_2.value_2 === props.loc_1.value_2
                    ? "white"
                    : Colors.primary,
                marginRight: 10,
              }}
            />
          </div>
        ) : null} */}
      </div>

      {/* {showLocations && props.loc_1.value_3 ? (
        <React.Fragment>
          <div
            style={{
              marginLeft: 10,
              border: "1px solid " + Colors.primary,
              borderRight: "0px",
            }}
          >
            {props.loc_1.value_3.map((loc_1, index) => {
              return (
                <div
                  key={`${loc_1.value_2}-${index}`}
                  style={{
                    cursor: "pointer",
                    padding: 5,
                    marginBottom: 1,
                    // fontSize: 15,
                    backgroundColor:
                      props.location.value_0 === props.loc.value_0 &&
                      props.location.value_1 === props.loc.value_1 &&
                      props.location.value_2.value_2 === props.loc_1.value_2 &&
                      props.location.value_2.value_3.value_3 === loc_1.value_3
                        ? Colors.primary
                        : "white",
                    color:
                      props.location.value_0 === props.loc.value_0 &&
                      props.location.value_1 === props.loc.value_1 &&
                      props.location.value_2.value_2 === props.loc_1.value_2 &&
                      props.location.value_2.value_3.value_3 === loc_1.value_3
                        ? "white"
                        : Colors.primary,
                  }}
                  onClick={() => {
                    props.changeLocation({
                      ...props.loc,
                      value_2: {
                        ...props.loc_1,
                        value_3: loc_1,
                      },
                    });
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      color:
                        props.location.value_0 === props.loc.value_0 &&
                        props.location.value_1 === props.loc.value_1 &&
                        props.location.value_2.value_2 ===
                          props.loc_1.value_2 &&
                        props.location.value_2.value_3.value_3 === loc_1.value_3
                          ? "white"
                          : Colors.primary,
                    }}
                  >
                    {loc_1.title}
                  </p>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      ) : null} */}
    </React.Fragment>
  );
};

export default LocationSearch;
