import { callServer } from "../helpers/actionHelper";

export const getMyInfo = async () => {
  try {
    const resData = await callServer({
      apiMethod: "get",
      endPoint: "server/auth/get-user-info",
    });
    return { type: "UPDATE_INFO", userInfo: resData.userInfo };
  } catch (err) {
    throw err;
  }
};

export const getAllMyInfo = async () => {
  try {
    const resData = await callServer({
      apiMethod: "get",
      endPoint: "server/auth/get-all-user-info",
    });
    return { type: "UPDATE_INFO", userInfo: resData.userInfo };
  } catch (err) {
    throw err;
  }
};

export const updateSocketId = async (socketId) => {
  try {
    if (!socketId) {
      throw { msg: "No socketId" };
    }
    const resData = await callServer({
      apiMethod: "get",
      endPoint: "server/auth/update-socket/" + socketId,
    });
    return resData;
  } catch (err) {
    throw err;
  }
};
