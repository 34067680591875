import React, { useState } from "react";
import ReactGA from "react-ga";

import { deviceDetect } from "react-device-detect";

import Colors from "../../constants/Colors";

import List from "../List";

import { useDispatch } from "react-redux";

import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { FiCheck } from "react-icons/fi";

import NumberFormat from "react-number-format";

import {
  getCurrentLocation,
  updateLocation,
} from "../../store/actions/location_action";
import { callServer } from "../../store/helpers/actionHelper";
import { useDebounce, useIsMountedRef } from "../../utils/customHooks";
import {
  isEmpty,
  passwordValidate,
  phoneValidator,
  validateEmail,
} from "../../utils/helper";
import LocationSearch from "../LocationSearch";

const month = [
  { value: 1, title: "January" },
  { value: 2, title: "February" },
  { value: 3, title: "March" },
  { value: 4, title: "April" },
  { value: 5, title: "May" },
  { value: 6, title: "June" },
  { value: 7, title: "July" },
  { value: 8, title: "August" },
  { value: 9, title: "September" },
  { value: 10, title: "October" },
  { value: 11, title: "November" },
  { value: 12, title: "December" },
];

const validator = /^([a-z0-9]|[-._]){3,60}$/;

const SignUpEmail = (props) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  const [phase, setPhase] = React.useState(1);
  const [days, setDays] = React.useState([]);
  const [years, setYears] = React.useState([]);

  const [checked, setChecked] = React.useState(false);

  const [referralCode, set_referralCode] = React.useState("");

  const [userName, setUserName] = React.useState("");
  const debounce_userName = useDebounce(userName, 1000);
  const [userNameGood, setUserNameGood] = React.useState(false);
  const [first_name, set_first_name] = React.useState("");
  const [last_name, set_last_name] = React.useState("");
  const [gender, set_gender] = React.useState("");
  const [email, set_email] = React.useState("");
  const [phone, set_phone] = React.useState("");
  const [password, set_password] = React.useState("");
  const [retype_password, set_retype_password] = React.useState("");

  const [dob_month, set_dob_month] = React.useState("");
  const [dob_day, set_dob_day] = React.useState("");
  const [dob_year, set_dob_year] = React.useState("");

  const [main_err, setMain_err] = useState("");
  const [userName_err, setUserName_err] = useState("");
  const [first_name_err, setFirst_name_err] = useState("");
  const [last_name_err, setLast_name_err] = useState("");
  const [gender_err, setGender_err] = useState(false);
  const [dobErr, setDobErr] = useState(false);
  const [phone_err, setPhone_err] = useState("");
  const [password_err, setPassword_err] = useState("");
  const [email_err, setEmail_err] = useState(null);
  const [location_err, setLocation_err] = useState(null);

  const [passShow, setPassShow] = React.useState(true);

  const [location, setLocation] = React.useState("");
  const [showLoc, setShowLoc] = React.useState(false);
  const [locationReady, setLocationReady] = React.useState(false);

  const [completed, setCompleted] = React.useState(false);
  const [final, setFinal] = useState(false);

  const [data, setData] = useState(null);
  const [sent, setSent] = useState("");
  let timer;
  const [show, setShow] = useState(false);
  const [pin, setPin] = useState("");
  const [err, setErr] = useState("");

  React.useEffect(() => {
    if (userName.length > 0 && userName.length < 3) {
      validateUserName();
    } else if (userName.length > 0) {
      setUserName_err(`Checking if ${userName} is available...`);
      setUserNameGood(false);
    } else {
      setUserName_err(``);
      setUserNameGood(false);
    }
  }, [userName]);

  React.useEffect(() => {
    validateUserName();
  }, [debounce_userName]);

  const updateUserName = (value) => {
    setUserName_err("");

    setUserName(value.target.value.toLowerCase());
  };

  const validateUserName = async () => {
    if (userName.length < 3) {
      return;
    }

    if (!validator.test(userName)) {
      setUserName_err("Invalid character used.");
      setUserNameGood(false);
      return;
    }

    try {
      const resData = await callServer({
        apiMethod: "post",
        endPoint: "server/auth/username-check",
        theBody: {
          userName,
        },
      });

      if (resData.validUserName) {
        setUserName_err("");
        setUserNameGood(true);
      } else {
        setUserName_err(`${userName} is not available.`);
        setUserNameGood(false);
      }
    } catch (err) {
      setUserNameGood(false);
      console.log({ err });
    }
  };

  const changeLocation = (value) => {
    setLocation(value);
    setLocation_err("");
  };

  const createDate = () => {
    // setDays([]);
    let tempt = [];
    for (let i = 0; i < 31; ) {
      i++;
      tempt.push({ value: i, title: i });
      if (i >= 31) {
        setDays(tempt);
        // console.log({ days: tempt });
      }
    }
  };

  const createYear = () => {
    // setYears([]);
    let tempt = [];
    const currentYear = new Date().getFullYear();
    // console.log({ currentYear });
    let startYear = currentYear - 90;
    for (let i = startYear; i < currentYear; ) {
      i++;
      tempt.push({ value: i, title: i });
      if (i >= currentYear) {
        setYears(tempt.reverse());
        // console.log({ years: tempt });
      }
    }
  };

  const getLocation = async () => {
    try {
      const data = await getCurrentLocation();
      return data;
    } catch (err) {
      console.log({ err });
      return false;
    }
  };

  const validateInfo = async () => {
    setMain_err("");
    props.setLoading(true);
    // console.log("HIT!");

    try {
      let ok = true;
      if (
        !userName ||
        userName.length < 3 ||
        !validator.test(userName) ||
        !userNameGood
      ) {
        if (!userName_err) {
          setUserName_err("You need to enter a valid username.");
        }
        ok = false;
      }
      if (!dob_year || !dob_day || !dob_month) {
        setDobErr("Your Date Of Birth is required.");
        ok = false;
      }
      const genderVal = await isEmpty(gender);
      if (!genderVal) {
        setGender_err("Please select a gender.");
        ok = false;
      }
      const firstVal = await isEmpty(first_name);
      if (!firstVal) {
        setFirst_name_err("Please enter a valid first name.");
        ok = false;
      }
      const lastVal = await isEmpty(last_name);
      if (!lastVal) {
        setLast_name_err("Please enter a valid last name.");
        ok = false;
      }
      const emailVal = await validateEmail(email);
      if (!emailVal) {
        setEmail_err("Please enter a valid email.");
        ok = false;
      }
      const phoneVal = await phoneValidator(phone);
      if (!phoneVal) {
        setPhone_err("Please enter a valid phone number.");
        ok = false;
      }
      const passVal = await passwordValidate(password);
      if (!passVal) {
        setPassword_err(
          "Password should be 8 or more characters, must contain at least one lowercase letter, one uppercase letter and one numeric digit."
        );
        ok = false;
      }
      let locVal = true;
      if (!location) {
        locVal = false;
        setLocation_err("Please select your general location.");
        ok = false;
      }

      if (
        userName &&
        firstVal &&
        lastVal &&
        emailVal &&
        phoneVal &&
        passVal &&
        gender &&
        locVal &&
        ok
      ) {
        return signUp();
      }
      props.setLoading(false);
    } catch (err) {
      console.log({ err });
      setMain_err(err);
      props.setLoading(false);
    }
  };

  const getIpAddress = async () => {
    try {
      const data = await fetch("https://api64.ipify.org?format=json");
      return data;
    } catch (err) {
      return null;
    }
  };

  const signUp = async () => {
    try {
      let ipInfo = null;
      let geolocation = null;
      let clientData = null;

      const raw_ipInfo = await getIpAddress();
      if (raw_ipInfo.ok) {
        ipInfo = await raw_ipInfo.json();
      }
      geolocation = await getLocation();

      clientData = await deviceDetect();

      const deviceData = { ipInfo, geolocation, clientData };

      const newLocation = {
        title: location.title,
        value_0: location.value_0,
        value_1: location.value_1,
      };

      if (location.value_2) {
        newLocation.value_2 = location.value_2.value_2;
        if (location.value_2.value_3) {
          newLocation.value_3 = location.value_2.value_3.value_3;
        }
      }

      /** Creating User Object */

      const theBody = {
        signUpType: "email",
        referralCode,
        email: email.toLowerCase(),
        first_name: first_name.toLowerCase(),
        last_name: last_name.toLowerCase(),
        gender: gender.toLowerCase(),
        day: dob_day.value,
        month: dob_month.value,
        year: dob_year.value,
        phone,
        password,
        confirmPassword: password,
        country: "JM",
        deviceData,
        expoToken: null,
        location: newLocation,
        userName,
        fcmToken: props.fcmToken,
      };
      // console.log({ theBody });

      const resData = await callServer({
        apiMethod: "post",
        endPoint: "server/auth/email-sign-up",
        theBody: theBody,
      });

      // console.log({ resData });

      // ReactGA.event({
      //   category: "Signup",
      //   action: "Signup Successful",
      // });

      // console.log({ resData });

      await localStorage.setItem(
        "JWT_token",
        JSON.stringify({ JWT_token: resData.tokenInfo.token })
      );
      // await localStorage.setItem(
      //   "userData",
      //   JSON.stringify({
      //     token: resData.tokenInfo.token,
      //     expDate: parseInt(resData.tokenInfo.expires),
      //   })
      // );

      setData(resData);

      // /**FInish */
      setCompleted(true);
      props.setDisable(true);
      setTimer();
      props.setLoading(false);
    } catch (err) {
      console.log("creatUser", { err });
      if (Array.isArray(err.msg)) {
        err.msg.forEach((er) => {
          if (er.param === "password") {
            if (isMountedRef.current) {
              setMain_err(er.msg);
            }
          }
        });
      } else if (err.msg) {
        setMain_err(err.msg);
      } else if (err.message) {
        setMain_err(err.message);
      }

      props.setLoading(false);
    }
  };

  /** Finalizing Registration */

  const setTimer = () => {
    timer = setTimeout(() => {
      setShow(true);
    }, 10000);
  };

  React.useEffect(() => {
    props.setShowBack(true);
    setPhase(1);
    createDate();
    createYear();
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const requestToken = async () => {
    try {
      if (timer) {
        clearTimeout(timer);
        setShow(false);
      }
      setErr("");
      setSent(false);
      props.setLoading(true);
      const resData = await callServer({
        apiMethod: "post",
        endPoint: "server/auth/resend-verify-token",
        token: data.tokenInfo.token,
        theBody: { type: "email" },
      });
      setSent(true);
      props.setLoading(false);
      setTimer();
    } catch (err) {
      setSent(false);

      if (err.msg) {
        setErr(err.msg);
      } else {
        setErr(err.message);
      }

      props.setLoading(false);
    }
  };

  const verify = async () => {
    try {
      setErr("");
      setSent(false);
      props.setLoading(true);

      const resData = await callServer({
        apiMethod: "post",
        endPoint: `server/auth/verify-email`,
        token: data.tokenInfo.token,
        theBody: { token: pin },
      });
      let tempt = data;
      tempt.userInfo.verifiedEmail = true;

      setData(tempt);
      props.setLoading(false);
      setFinal(true);
    } catch (err) {
      setSent(false);
      ReactGA.event({
        category: "Signup",
        action: "Signup Failed",
      });

      if (err.msg) {
        setErr(err.msg);
      } else {
        setErr(err.message);
      }

      props.setLoading(false);
    }
  };

  const theDispatch = async () => {
    try {
      // const newData = await getMyInfo();

      // console.log({ dispatchData: data });

      props.setLoading(false);
      props.setDisable(false);

      await dispatch({
        type: "AUTH",
        valid: data.valid,
        userId: data.userId,
        tokenInfo: data.tokenInfo,
        userInfo: data.userInfo,
        token: data.token,
      });
      props.close();
    } catch (err) {
      setErr(err.msg);
      console.log({ signup: err });
    }
  };

  return (
    <React.Fragment>
      {!completed ? (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={"/logo.svg"}
              alt="logo"
              style={{ height: 100, margin: 10 }}
            />

            {main_err ? (
              <p style={{ ...styles.errMsg, marginBottom: 15 }}>{main_err}</p>
            ) : null}
            <input
              className={"textInput"}
              style={{
                marginBottom: 0,
                opacity: props.loading ? 0.3 : 1,
                backgroundColor: Colors.primary,
                color: "white",
              }}
              disabled={props.loading}
              type="text"
              name="firstName"
              placeholder={"Referral Code"}
              maxLength="60"
              max="60"
              value={referralCode.toUpperCase()}
              onChange={(value) => {
                set_referralCode(value.target.value);
              }}
            />
            <p style={{ color: "#ccc", fontSize: 12, marginBottom: 10 }}>
              Optional
            </p>
            {userName_err ? (
              <p style={{ ...styles.errMsg }}>{userName_err}</p>
            ) : userName.length >= 3 ? (
              <p
                style={{ ...styles.errMsg, color: Colors.good }}
              >{`${userName} is available.`}</p>
            ) : null}
            <input
              className={"textInput"}
              style={{
                opacity: props.loading ? 0.3 : 1,
              }}
              disabled={props.loading}
              type="text"
              name="userName"
              placeholder={"Username*"}
              maxLength="60"
              max="60"
              value={userName}
              onChange={updateUserName}
              // onChange={(value) => {
              //   setUserName_err("");
              //   if (validator.test(value.target.value)) {
              //     setUserName(value.target.value);
              //   }
              // }}
            />
            {first_name_err ? (
              <p style={{ ...styles.errMsg }}>{first_name_err}</p>
            ) : null}
            <input
              className={"textInput"}
              style={{
                opacity: props.loading ? 0.3 : 1,
              }}
              disabled={props.loading}
              type="text"
              name="firstName"
              placeholder={"First Name*"}
              maxLength="60"
              max="60"
              value={first_name}
              onChange={(value) => {
                setFirst_name_err("");
                set_first_name(value.target.value);
              }}
            />
            {last_name_err ? (
              <p style={{ ...styles.errMsg }}>{last_name_err}</p>
            ) : null}
            <input
              className={"textInput"}
              style={{
                opacity: props.loading ? 0.3 : 1,
              }}
              disabled={props.loading}
              type="text"
              name="lastName"
              placeholder={"Last Name*"}
              maxLength="60"
              max="60"
              value={last_name}
              onChange={(value) => {
                setLast_name_err("");
                set_last_name(value.target.value);
              }}
            />

            {location_err ? (
              <p style={{ ...styles.errMsg }}>{location_err}</p>
            ) : null}

            <div
              style={{
                position: "relative",
                marginBottom: 15,
                cursor: "pointer",
                opacity: props.loading ? 0.3 : 1,
              }}
            >
              <input
                className={"textInput"}
                placeholder={"Location"}
                value={""}
                disabled={props.loading}
                style={{ margin: 0 }}
                onChange={(value) => {
                  return;
                }}
              ></input>
              <div
                onClick={() => {
                  if (props.loading) {
                    return;
                  }
                  setShowLoc((state) => !state);
                }}
                style={{
                  top: 0,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor:
                    location || showLoc ? "white" : "transparent",
                  borderRadius: 5,
                  opacity: location || showLoc ? 1 : 0,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {location && !showLoc && !props.loading ? (
                  <p style={{ fontSize: 12, margin: 0, textAlign: "center" }}>
                    {location.value_0.toUpperCase()} - {location.title}
                    {location.value_2 ? (
                      <React.Fragment>{"..."}</React.Fragment>
                    ) : null}
                  </p>
                ) : (
                  <React.Fragment>
                    <div
                      style={{
                        width: 100,
                        backgroundColor: Colors.err,
                        borderRadius: 200,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <p
                        style={{
                          whiteSpace: "nowrap",
                          marginTop: 0,
                          marginBottom: 0,
                          marginLeft: 0,
                          color: Colors.primary,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {"Close"}
                      </p>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <LocationSearch
                visible={props.loading ? false : showLoc}
                setVisible={setShowLoc}
                width={200}
                location={location}
                setLocationReady={(value) => setLocationReady(value)}
                changeLocation={(value) => changeLocation(value)}
                fixed={true}
                notSearch={true}
                styles={{ left: 0 }}
              />
            </div>

            <p style={{ color: "white", marginBottom: 0, marginTop: 0 }}>
              Gender
            </p>
            {gender_err ? (
              <p style={{ ...styles.errMsg }}>{gender_err}</p>
            ) : null}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: 150,
                marginTop: 0,
                opacity: props.loading ? 0.5 : 1,
              }}
            >
              <div className={"radioContainer"}>
                <label style={{ color: "white" }} htmlFor="male">
                  Male
                </label>
                <span
                  className="checkmark"
                  style={{
                    backgroundColor: gender === "m" ? Colors.primary : "white",
                  }}
                  onClick={() => {
                    if (props.loading) {
                      return;
                    }
                    set_gender("m");
                  }}
                >
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value="m"
                    checked={gender === "m" ? true : false}
                    onChange={(value) => {
                      setGender_err("");
                      set_gender(value.target.value);
                    }}
                  />
                </span>
              </div>
              <div className={"radioContainer"}>
                <label style={{ color: "white" }} htmlFor="female">
                  Female
                </label>
                <span
                  className="checkmark"
                  style={{
                    backgroundColor: gender === "f" ? Colors.primary : "white",
                  }}
                  onClick={() => {
                    if (props.loading) {
                      return;
                    }
                    set_gender("f");
                  }}
                >
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="f"
                    checked={gender === "f" ? true : false}
                    onChange={(value) => {
                      setGender_err("");
                      set_gender(value.target.value);
                    }}
                  />
                </span>
              </div>
            </div>

            <p style={{ marginTop: 10, color: "white", marginBottom: 0 }}>
              Date of Birth*
            </p>
            {dobErr ? <p style={{ ...styles.errMsg }}>{dobErr}</p> : null}

            <div
              style={{
                opacity: props.loading ? 0.5 : 1,
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                marginBottom: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ color: "white", marginBottom: 0 }}>Day</p>
                <List
                  style={{ marginRight: 5, marginLeft: 5 }}
                  width={60}
                  list={days}
                  setValue={(value) => {
                    set_dob_day(value);
                    setDobErr("");
                  }}
                  value={dob_day}
                  loading={props.loading}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ color: "white", marginBottom: 0 }}>Month</p>
                <List
                  style={{ marginRight: 5, marginLeft: 5 }}
                  list={month}
                  setValue={(value) => {
                    set_dob_month(value);
                    setDobErr("");
                  }}
                  value={dob_month}
                  loading={props.loading}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ color: "white", marginBottom: 0 }}>Year</p>
                <List
                  style={{ marginRight: 5, marginLeft: 5 }}
                  width={60}
                  list={years}
                  setValue={(value) => {
                    setDobErr("");
                    set_dob_year(value);
                  }}
                  value={dob_year}
                  loading={props.loading}
                />
              </div>
            </div>

            {email_err ? <p style={{ ...styles.errMsg }}>{email_err}</p> : null}

            <input
              autoComplete="off"
              className={"textInput"}
              style={{
                opacity: props.loading ? 0.3 : 1,
                // marginTop: 15,
              }}
              disabled={props.loading}
              type="email"
              name="Email"
              placeholder={"Email*"}
              maxLength="60"
              value={email}
              onChange={(value) => {
                setEmail_err("");
                set_email(value.target.value);
              }}
            />
            {phone_err ? <p style={{ ...styles.errMsg }}>{phone_err}</p> : null}

            <NumberFormat
              className={"textInput"}
              style={{
                opacity: props.loading ? 0.3 : 1,
                marginLeft: "auto",
                marginRight: "auto",
              }}
              type="tel"
              disabled={props.loading}
              format={"(###) ### ####"}
              name="company_number"
              placeholder={"Phone"}
              value={phone}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                set_phone(parseInt(value));
              }}
            />

            {password_err ? (
              <p style={{ ...styles.errMsg }}>{password_err}</p>
            ) : null}
            <input
              autoComplete="off"
              className={"textInput"}
              style={{
                opacity: props.loading ? 0.3 : 1,
                marginBottom: 0,
              }}
              disabled={props.loading}
              type={passShow ? "password" : "text"}
              name="password"
              placeholder={"Password*"}
              maxLength="60"
              max="60"
              value={password}
              onChange={(value) => {
                setPassword_err("");
                set_password(value.target.value);
              }}
            />
            {/* <input
              className={"textInput"}
              style={{
                opacity: props.loading ? 0.3 : 1,
              }}
              disabled={props.loading}
              type={props.passShow ? "password" : "text"}
              name="retypepassword"
              placeholder={"Retype Password*"}
              maxLength={60}
              value={retype_password}
              onChange={(value) => {
                set_retype_password(value.target.value);
              }}
            /> */}
            <div
              style={{
                paddingTop: 10,
                cursor: props.loading ? "inherit" : "pointer",
                opacity: props.loading ? 0.3 : 1,
              }}
              onClick={() => {
                if (props.loading) {
                  return;
                }
                setPassShow((state) => !state);
              }}
            >
              {passShow ? (
                <AiFillEyeInvisible color={"white"} style={{ fontSize: 25 }} />
              ) : (
                <AiFillEye color={"white"} style={{ fontSize: 25 }} />
              )}
            </div>

            <div
              className={"checkContainer"}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                margin: 10,
                cursor: props.loading ? "inherit" : "pointer",
                opacity: props.loading ? 0.3 : 1,
              }}
              onClick={() => {
                if (props.loading) {
                  return;
                }
                setChecked((state) => !state);
              }}
            >
              <input
                type="checkbox"
                style={{ width: 20 }}
                checked={checked}
                onChange={(value) => {
                  // console.log({ value: value.target.value });
                }}
              />
              <span className="checkMarkBox">
                {checked ? (
                  <FiCheck color={"white"} style={{ fontSize: 18 }} />
                ) : null}
              </span>
              <p
                style={{
                  marginBottom: 0,
                  textAlign: "center",
                  color: "white",
                }}
              >
                I agree to the{" "}
                <a
                  href={"/legal.html"}
                  style={{ color: "white", fontWeight: "bold" }}
                  target={"_blank"}
                >
                  terms and conditions
                </a>
              </p>
            </div>

            <button
              className="universalBtn"
              style={{
                backgroundColor: Colors.good,
                // padding: 5,
                // borderRadius: 20,
                // width: 80,
                // margin: 10,
                cursor: !checked || props.loading ? "inherit" : "pointer",
                opacity: !checked || props.loading ? 0.3 : 1,
              }}
              onClick={() => {
                if (!checked || props.loading) {
                  return;
                }
                setShowLoc(false);
                props.setLoading(true);
                // console.log({
                //   referralCode,
                //   checked,
                //   first_name,
                //   last_name,
                //   gender,
                //   email,
                //   phone,
                //   password,
                //   retype_password,
                //   dob_month,
                //   dob_day,
                //   dob_year,
                // });
                validateInfo();
              }}
            >
              Sign Up
            </button>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!final ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={"/logo.svg"}
                alt="logo"
                style={{ height: 150, marginRight: 20 }}
              />

              <p
                style={{
                  margin: 10,
                  fontSize: 25,
                  color: "white",
                  textAlign: "center",
                }}
              >
                Welcome{" "}
                {data.userInfo.firstName.slice(0, 1).toUpperCase() +
                  data.userInfo.firstName.slice(1)}
              </p>
              <p
                style={{
                  margin: 10,
                  fontSize: 15,
                  color: "white",
                  textAlign: "center",
                }}
              >
                An email was sent with your verification token please enter it
                below.
              </p>
              {sent ? (
                <p
                  style={{
                    color: Colors.good,
                    textAlign: "center",
                    marginTop: 20,
                    fontSize: 20,
                    fontWeight: "600",
                  }}
                >
                  Token Sent!
                </p>
              ) : null}

              {err ? (
                <p
                  style={{
                    color: Colors.err,
                    textAlign: "center",
                    marginTop: 20,
                    // fontSize: 20,
                    fontWeight: "600",
                  }}
                >
                  {err}
                </p>
              ) : null}
              <input
                autoComplete={"off"}
                className={"textInput"}
                style={{
                  opacity: props.loading ? 0.3 : 1,
                }}
                disabled={props.loading}
                type="number"
                name="pin"
                placeholder={"Verification Token"}
                maxLength={6}
                value={pin}
                onChange={(value) => {
                  if (pin.length >= 6) {
                    return;
                  }
                  setErr("");
                  setPin(value.target.value);
                }}
              />
              <div
                className={"universalBtn"}
                style={{
                  backgroundColor: Colors.good,
                  opacity: pin.length < 6 || props.loading ? 0.3 : 1,
                }}
                onClick={() => {
                  if (pin.length < 6 || props.loading) {
                    return;
                  }
                  verify();
                }}
              >
                Verify
              </div>
              <div
                // className={"universalBtn"}
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid transparent",
                  opacity: props.loading ? 0.3 : 1,
                  marginTop: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (props.loading) {
                    return;
                  }
                  theDispatch();
                }}
              >
                <p style={{ color: "#ccc", fontWeight: "200" }}>Verify Later</p>
              </div>

              {show ? (
                <React.Fragment>
                  <p
                    style={{
                      color: Colors.err,
                      textAlign: "center",
                      marginTop: 20,
                    }}
                  >
                    If you didn't receive an email with your verification token
                    you can request another.
                  </p>
                  <button
                    className={"universalBtn"}
                    style={{
                      backgroundColor: Colors.caution,
                      opacity: props.loading ? 0.3 : 1,
                    }}
                    onClick={() => {
                      if (props.loading) {
                        return;
                      }
                      requestToken();
                    }}
                  >
                    Request Token
                  </button>
                </React.Fragment>
              ) : null}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={"/logo.svg"} alt="logo" style={{ height: 150 }} />

              <p
                style={{
                  margin: 10,
                  marginBottom: 0,
                  fontSize: 25,
                  color: "white",
                  textAlign: "center",
                }}
              >
                Great your email has been verified!
              </p>
              {/* <p
                style={{
                  margin: 0,
                  fontSize: 12,
                  color: "white",
                  textAlign: "center",
                }}
              >
                Please remember to verify your phone number.
              </p> */}

              <div
                className={"universalBtn"}
                style={{
                  margin: 10,
                  backgroundColor: Colors.good,
                  opacity: props.loading ? 0.3 : 1,
                }}
                onClick={() => {
                  if (props.loading) {
                    return;
                  }
                  theDispatch();
                }}
              >
                Finish
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const styles = {
  errMsg: {
    color: Colors.err,
    margin: 0,
    textAlign: "center",
  },
};

export default SignUpEmail;
