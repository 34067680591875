/* eslint-disable no-throw-literal */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { callServer } from "../store/helpers/actionHelper";

import { getAllMyInfo } from "../store/actions/auth_action";
import LoadComp from "../Components/LoadComp";
import Colors from "../constants/Colors";

const EmailVerify = (props) => {
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const online = useSelector((state) => state.auth.token);
  const darkMode = useSelector((state) => state.display.darkMode);
  const userInfo = useSelector((state) => state.auth.userInfo);

  const [loading, setLoading] = useState(true);
  const [errMsg, setErrMsg] = useState("");

  const queries = useMemo(() => {
    const queryData = new URLSearchParams(search);

    return {
      userId: queryData.get("validate"),
      verifyCode: queryData.get("valid"),
    };
  }, [search]);

  const verifyEmail = async () => {
    try {
      if (!queries || !queries.userId || !queries.verifyCode) {
        throw { msg: "Invalid Link." };
      }
      if (online) {
        if (userInfo._id !== queries.userId) {
          throw {
            msg: "Mis-matched information please logout to use this link.",
          };
        }
      }
      const resData = await callServer({
        endPoint: "server/auth/verify-email-link",
        theBody: queries,
        apiMethod: "post",
      });
      console.log({ resData });

      if (online) {
        const data = await getAllMyInfo();
        dispatch(data);
      }
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setErrMsg(
        error.msg ||
          "Something went wrong, please request another verification link."
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log({ id: props.id, queries });
    verifyEmail();
    return () => {};
  }, []);

  return (
    <>
      <Helmet>
        <title>Email Verification</title>
      </Helmet>
      <div></div>
      <div
        style={{
          display: "flex",
          flex: 1,
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          paddingTop: 20,
          // height: !online ? "100vh" : "auto",
        }}
      >
        {loading ? (
          <LoadComp show={!loading} />
        ) : (
          <>
            {online ? null : <img src={"/logo.svg"} width={400} alt="logo" />}
            <h1
              style={{
                marginTop: 20,
                color: darkMode ? Colors.darModeText : Colors.primary,
              }}
            >
              {errMsg ? "Oops!" : "Email Verified!"}
            </h1>
            <h3
              style={{
                textAlign: "center",
                maxWidth: 500,
                color: darkMode ? Colors.darModeText : Colors.primary,
              }}
            >
              {errMsg
                ? `${errMsg} If there is an issue please email us at support@bidsquawk.com.`
                : ""}
            </h3>
            <button
              className="universalBtn"
              style={{
                marginTop: 10,
                backgroundColor: Colors.primary,
                width: 150,
                transitionDuration: "500ms",
                color: "white",
              }}
              onClick={() => history.push("/")}
            >
              {"Home"}
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default EmailVerify;
