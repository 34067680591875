/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { AiOutlineMail } from "react-icons/ai";

import Colors from "../../constants/Colors";
/**
 * 
 * 
    PROPS
        style={{ height: 40 }}
        action={() => console.log("EMAIL_SIGNUP")}
 *
 */

const Email_OAuth = (props) => {
  React.useEffect(() => {
    const ele = document.getElementById("email_btn");
    if (ele) {
      ele.addEventListener("click", () => {
        ele.style.transform = "scale(0.8)";
        setTimeout(() => {
          ele.style.transform = "scale(1)";
        }, 100);
      });
    }

    return () => {
      if (ele) {
        ele.removeEventListener("scroll", () => {});
      }
    };
  }, []);

  return (
    <button
      className="universalBtn"
      id={"email_btn"}
      disabled={props.loading}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        margin: 5,
        border: "0px",
        width: 200,
        backgroundColor: "white",
        WebkitBoxShadow: "0px 0px 5px -2px rgba(0, 0, 0, 0.85)",
        MozBoxShadow: "0px 0px 5px -2px rgba(0, 0, 0, 0.85)",
        boxShadow: "0px 0px 5px -2px rgba(0, 0, 0, 0.85)",
        borderRadius: 5,
        padding: 5,
        transition: "100ms",
        opacity: props.loading ? 0.3 : 1,
        ...props.style,
      }}
      onClick={() => {
        if (props.action) {
          props.action();
        }
      }}
    >
      <AiOutlineMail
        style={{ marginRight: 10, fontSize: 20, color: Colors.primary }}
      />
      Sign up with Email
    </button>
  );
};

export default Email_OAuth;
