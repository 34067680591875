/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useImperativeHandle, createRef } from "react";

import moment from "moment";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Search from "./Search";
import AlertsTab from "./Alerts/AlertsTab";
import Auth from "./Auth/Auth";
import NewItemModal from "./NewItem/NewItemModal";

import { v4 as uuidv4 } from "uuid";

import { deviceDetect, isMobile } from "react-device-detect";

import { Navbar, NavDropdown, Nav } from "react-bootstrap";

import Colors from "../constants/Colors";

import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useWindowSize } from "../utils/customHooks";

import { getToken, messaging } from "../utils/firebase";

import "../css/scss/nav.css";
import { callServer } from "../store/helpers/actionHelper";

import { IoMdExit, IoMdNotifications } from "react-icons/io";
import { FaMoon, FaSun, FaUser } from "react-icons/fa";

import { GoPlus } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import HoverComp from "./HoverComp";

import notify__ding from "../assets/notify__ding.mp3";

import CheckBox from "./CheckBox";
import { RiInstallFill } from "react-icons/ri";
import Notice from "./Notice";
import SocialButtons from "./Social";
import Marquee from "react-fast-marquee";

toast.configure();

const foldingWidth = 870;

const NavBar = forwardRef((props, ref) => {
  const alertsRef = React.useRef();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [width, height] = useWindowSize();

  const navRef = createRef();
  // const dimensions = useRefDimensions(navRef);

  const notifyList = React.useRef([]);
  const toastId = React.useRef(null);
  const online = useSelector((state) => state.auth.token);
  const loc = useSelector((state) => state.loc.locations);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const live = useSelector((state) => state.connection.live);
  const darkMode = useSelector((state) => state.display.darkMode);
  const socketStatus = useSelector((state) => state.socket.status);

  const [chatId, setChatId] = React.useState(null);
  const [alertId, setAlertId] = React.useState(null);

  const [showAuth, setShowAuth] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [token, setToken] = React.useState("");

  const [visible, setVisible] = React.useState(false);

  const [mounted, setMounted] = React.useState(false);

  // const [socketId, setSocketId] = React.useState("");

  const [id_1, setId_1] = React.useState(uuidv4());
  const [canInstall, setCanInstall] = React.useState(false);

  const [serviceWorker, setServiceWorker] = React.useState(null);
  const [showAgain, setShowAgain] = React.useState(true);
  const [asked, setAsked] = React.useState(false);

  const [tint, setTint] = React.useState(false);

  React.useEffect(() => {
    if (!online) {
      setChatId(null);
    }
  }, [online]);

  React.useEffect(() => {
    const changeBackgroundColor = () => {
      const elements = document.getElementsByClassName("navSetting");

      for (let i = 0; i < elements.length; i++) {
        elements[i].style.backgroundColor = darkMode
          ? Colors.primary_darker
          : Colors.primary;
      }
    };
    changeBackgroundColor();
  }, [darkMode]);

  useImperativeHandle(ref, () => ({
    startSearch() {
      showAuthFunc();
    },
    openAuth() {
      setShowAuth(1);
    },
    closeAuth() {
      setShowAuth("");
    },
    toggleAlertTab(value) {
      if (alertsRef.current && online && live) {
        alertsRef.current.toggleTab(value);
      }
    },
    refreshMsg() {
      if (alertsRef.current && online && live) {
        alertsRef.current.refreshMsg();
      }
    },
    openNewItem() {
      setVisible(true);
    },
    // updateSocketId() {
    //   updateSocketId();
    // },
  }));

  const showAuthFunc = () => {
    setShowAuth(true);
  };

  const doCheck = () => {
    const ele = document.querySelector("dropdown-menu");
    if (ele) {
      // console.log({ ele: ele.style, width });
    }
  };

  const logout = async () => {
    try {
      await dispatch({ type: "LOGOUT" });
      history.push("/");
    } catch (err) {
      console.log({ err });
    }
  };

  const playTone = () => {
    try {
      new Audio(notify__ding).play();
    } catch (err) {
      console.log(err);
    }
  };

  const runToast = async (data) => {
    if (props.showAlerts) {
      console.log({ SHOWALERTS: props.showAlerts });

      try {
        if (!data) return;
        if (alertsRef.current && online && live) {
          // alertsRef.current.refreshMsg();
        }
        console.log({ data });
        const payload = {
          ...data,
        };

        if (payload.data.data) {
          if (typeof payload.data.data === "string") {
            payload.data.data = JSON.parse(data.data.data);
          }
        }

        let alert_type = null;
        // console.log(`This is the chatId ${chatId}`);

        if (payload.data.msg) {
          payload.data.body = payload.data.msg;
          alert_type = "MSG";

          if (payload.data.chatMsg.userId === userInfo._id) {
            // console.log("USER_ID IS A MATCH!!!");
            return;
          }
          // console.log("USER_ID IS NOT A MATCH!!!");

          // console.log({ payloadChatId: payload.data.chatId, chatId });
          if (payload.data.chatId !== chatId) {
            // console.log("IS NOT A MATCH!!!");
          } else {
            // console.log("IS A MATCH!!!");
            return;
          }
        } else {
          alert_type = "ALERT";
        }

        // console.log({ alert_type, payload });

        // console.log({ payload });
        props.setAlert(payload);
        if (!props.showAlerts) return;
        if (payload.data) {
          const theTabId = localStorage.getItem("tabId");
          if (!theTabId) {
            console.log({ theTabId, id_1 });
            if (theTabId === id_1) {
              playTone();
            }
          }
          const listCheck = notifyList.current.filter((ele, index) => {
            if (ele.id === payload.data.id) {
              ele.count++;
              return ele;
            }
          });
          console.log({ listCheck });

          if (props.showAlerts) {
            if (listCheck.length === 0) {
              // console.log(payload.data.id);
              const toastNoti = toast.dark(
                <ToastNotification
                  alertsRef={() =>
                    alertsRef.current.toggleTab(true, alert_type, payload)
                  }
                  history={history}
                  payload={{ ...payload }}
                  count={1}
                  notifyList={notifyList}
                  onClick={() => {
                    alertsRef.current.positionAlertTab();
                  }}
                />,
                {
                  position: "top-right",
                  autoClose: 8000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  onClick: () => {
                    /**
                     * Do something with payload
                     */
                    // console.log(payload, "NOTIFICATION ACTION HERE!!!");
                  },
                }
              );

              notifyList.current = [
                ...notifyList.current,
                { id: payload.data.id, toastNoti, count: 1 },
              ];
              // console.log({ notifyList });
            } else {
              toast.update(listCheck[0].toastNoti, {
                render: (
                  <ToastNotification
                    payload={{ ...payload, count: listCheck[0].count }}
                    count={listCheck[0].count}
                    notifyList={notifyList}
                    alertsRef={() =>
                      alertsRef.current.toggleTab(true, alert_type, payload)
                    }
                  />
                ),
              });
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const msgFunction = (data) => {
    try {
      return data;
    } catch (err) {
      console.log({ err });
    }
  };

  const sendToken = async () => {
    try {
      const resData = await callServer({
        apiMethod: "post",
        endPoint: "push_token",
        theBody: { token },
      });
      // console.log({ resData });
      if (resData.response.failureCount > 0) {
        messaging
          .deleteToken()
          .then((result) => {
            // console.log({ result });
            return getToken(setToken);
          })
          .catch((err) => console.log({ err }));
      }
    } catch (e) {
      console.log({ e });
    }
  };

  const setUpInstallBtn = async () => {
    try {
      // console.log("👍", "butInstall-clicked");
      const promptEvent = serviceWorker;
      if (!promptEvent) {
        // console.log("No promptEvent", { promptEvent });
        // The deferred prompt isn't available.
        return;
      }
      // Show the install prompt.

      console.log({ promptEvent });
      promptEvent.prompt();
      // Log the result
      const result = await promptEvent.userChoice;
      // console.log("👍", "userChoice", { result });

      if (result.outcome === "dismissed") {
        setAsked(true);
        setCanInstall(false);
        // console.log("DISMISSED!");
      }

      if (!showAgain) {
        cacheShowAgain();
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const installedApp = async () => {
    try {
      window.addEventListener("appinstalled", (event) => {
        // alert("2");
        // console.log("👍", "appinstalled", event);
        // Clear the deferredPrompt so it can be garbage collected
        // window.deferredPrompt = null;
        setServiceWorker(null);
      });

      initializeServiceWorker();
    } catch (err) {
      console.log({ err });
    }
  };

  const initializeServiceWorker = async () => {
    try {
      window.addEventListener("beforeinstallprompt", (event) => {
        // alert("3");
        // console.log("👍", "beforeinstallprompt", { event });

        // Stash the event so it can be triggered later.
        // window.deferredPrompt = event;
        setServiceWorker(event);
      });

      if ("serviceWorker" in navigator) {
        // alert("4");
        window.addEventListener("load", function () {
          navigator.serviceWorker
            .register(process.env.PUBLIC_URL + "/worker.js")
            .then(
              function (registration) {
                // console.log(
                //   "Worker registration successfull",
                //   registration,
                //   registration.scope
                // );
              },
              function (err) {
                console.log("Worker registration failed", err);
              }
            )
            .catch(function (err) {
              console.log(err);
            });
        });
      } else {
        console.log("Service Worker is not supported by browser.");
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const cacheShowAgain = () => {
    localStorage.setItem("showAgain", showAgain);
  };

  const checkToggleState = () => {
    try {
      const ele = document.getElementById("navBarToggle");

      if (ele.className === "navbar-toggler collapsed") {
        setTint(true);
      } else {
        setTint(false);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  if (messaging) {
    messaging.onMessage((payload) => {
      if (props.showAlerts) {
        runToast(payload);
      }
    });
  }

  // React.useEffect(() => {
  //   if (online) {
  //     sendToken();
  //   }
  // }, [online]);

  // React.useEffect(() => {
  //   if (token) {
  //     // console.log({ pushToken: token });
  //     sendToken();
  //   }
  // }, [token]);

  React.useEffect(() => {
    getToken(setToken);
  }, []);

  React.useEffect(() => {
    doCheck();
  }, [width]);

  React.useEffect(() => {
    const isSet = localStorage.getItem("showAgain");

    // console.log({ isSet, serviceWorker });

    if (!asked && serviceWorker && showAgain && !isSet) {
      // console.log("TRIGGER CAN SHOW!");
      setCanInstall(true);
    } else {
      setCanInstall(false);
    }
  }, [serviceWorker]);

  React.useEffect(() => {
    // console.log({ tabId: id_1 });
    // alert("1");

    installedApp();

    window.addEventListener("focus", () => localStorage.setItem("tabId", id_1));

    return () => {
      window.removeEventListener("focus", localStorage.removeItem("tabId"));
      window.removeEventListener("beforeinstallprompt", () => {});
      window.removeEventListener("load", () => {});
      window.removeEventListener("appinstalled", () => {});
    };
  }, []);

  // if ("serviceWorker" in navigator) {
  //   initializeServiceWorker();
  // }
  return (
    <React.Fragment>
      <ToastContainer
        limit={5}
        position="top-right"
        draggablePercent={60}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        closeOnClick
        rtl={false}
        draggable
      >
        <div></div>
      </ToastContainer>

      <Helmet>
        <link rel="manifest" href="/manifest.json" />
      </Helmet>

      {online ? (
        <NewItemModal
          loading={props.loading}
          setLoading={props.setLoading}
          visible={visible}
          close={() => setVisible(false)}
        />
      ) : null}

      {online && live ? (
        <AlertsTab
          ref={alertsRef}
          chatId={chatId}
          setChatId={setChatId}
          alertId={alertId}
          setAlertId={setAlertId}
          msgFunction={msgFunction}
        />
      ) : null}

      <Auth
        setShowAuth={setShowAuth}
        showAuth={showAuth}
        loading={props.loading}
        setLoading={props.setLoading}
        fcmToken={token}
      />

      <div
        ref={navRef}
        id="navBar"
        className="navSetting shadow"
        style={{
          backgroundColor: `${
            darkMode ? Colors.primary_darker : Colors.primary
          } !important`,
          // position: "relative",
          zIndex: 200,
        }}
      >
        <Notice />
        {online ? (
          <>
            {userInfo.verifiedEmail ? null : (
              <div style={{ backgroundColor: "	#ff4d4d" }}>
                <Marquee
                  pauseOnHover={true}
                  gradientWidth={50}
                  play={true}
                  gradientColor={[255, 77, 77]}
                  speed={12}
                  style={{
                    backgroundColor: "transparent",
                    flexDirection: "row",
                    width: "100%",
                    maxWidth: 1800,
                    margin: 0,
                  }}
                >
                  <p style={{ margin: 10, color: "white", fontWeight: "bold" }}>
                    Your account has not been verified.
                  </p>
                </Marquee>
              </div>
            )}
          </>
        ) : null}

        {props.children}
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: 1900,
            backgroundColor: `${
              darkMode ? Colors.primary_darker : Colors.primary
            } !important`,
          }}
          onClick={() => {
            // console.log("CLICKED NAV");
            if (alertsRef.current) {
              alertsRef.current.toggleTab(false);
            }
          }}
        >
          <Navbar
            collapseOnSelect
            expand="sm"
            // bg="dark"
            variant="dark"
            style={{
              backgroundColor: `${
                darkMode ? Colors.primary_darker : Colors.primary
              } !important`,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: width < foldingWidth ? "100%" : "auto",
                justifyContent: "space-between",
                alignItems: "center",
                // alignItems: width > 667 ? "center" : "flex-start",
              }}
            >
              <Navbar.Toggle
                id="navBarToggle"
                aria-controls="responsive-navbar-nav"
                style={{
                  marginTop: width < 667 ? 1 : 0,
                  transition: "300ms",
                }}
                onClick={() => checkToggleState()}
              />
              {width < foldingWidth ? (
                <React.Fragment>
                  <Search
                    searchRef={props.searchRef}
                    searchData={props.searchData}
                    setSearchData={props.setSearchData}
                    loading={props.loading}
                    setLoading={props.setLoading}
                    online={online}
                    setShowAuth={setShowAuth}
                    showAuth={showAuth}
                    mounted={mounted}
                    setMounted={setMounted}
                  />
                </React.Fragment>
              ) : null}

              {width < foldingWidth && online ? (
                <>
                  <NewItemBtn
                    visible={visible}
                    setVisible={setVisible}
                    loading={props.loading}
                    setLoading={props.setLoading}
                  />
                </>
              ) : null}

              {width < foldingWidth && online ? null : (
                <Navbar.Brand
                  onClick={() => {
                    if (location) {
                      if (location.pathname === "/" && online) {
                        if (props.homeRefFunc.current) {
                          props.homeRefFunc.current.newSearch();
                        }
                      } else {
                        history.push("/");
                      }
                    }
                  }}
                  style={{ padding: 0, marginRight: 0, display: "flex" }}
                >
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img src={"/logo.svg"} alt="logo" style={{ height: 35 }} />
                    {width < 1090 ? null : (
                      <React.Fragment>
                        <div style={{ marginLeft: 10 }}>
                          BidSquawk Marketplace
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </Navbar.Brand>
              )}
            </div>
            {serviceWorker && width > foldingWidth + 50 ? (
              <Nav.Link
                // eventKey={2}
                alt={"install-web-app"}
                style={{
                  color: Colors.good,
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 0,
                }}
                onClick={setUpInstallBtn}
              >
                <RiInstallFill
                  style={{
                    transition: "200ms",
                    fontSize: 20,
                    margin: 0,
                    color: Colors.good,
                  }}
                />
              </Nav.Link>
            ) : null}

            <Navbar.Collapse id="responsive-navbar-nav">
              {/* More Nav Items */}
              {width >= foldingWidth ? (
                <React.Fragment>
                  <Search
                    searchRef={props.searchRef}
                    searchData={props.searchData}
                    setSearchData={props.setSearchData}
                    loading={props.loading}
                    setLoading={props.setLoading}
                    online={online}
                    setShowAuth={setShowAuth}
                    showAuth={showAuth}
                    mounted={mounted}
                    setMounted={setMounted}
                  />
                </React.Fragment>
              ) : null}

              {width >= foldingWidth ? <DisplayMode /> : null}

              <div style={{ marginRight: 10 }} />

              {width >= foldingWidth && online ? (
                <NewItemBtn
                  visible={visible}
                  setVisible={setVisible}
                  loading={props.loading}
                  setLoading={props.setLoading}
                />
              ) : null}

              {online ? (
                <React.Fragment>
                  <Nav.Link
                    alt={"my-profile"}
                    href={"/my-profile/" + userInfo.userName}
                    eventKey={2}
                    onClick={(e) => {
                      e.preventDefault();
                      if (location.pathname.includes("my-profile")) return;
                      history.push("/my-profile/" + userInfo.userName);
                    }}
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      paddingLeft: width < 870 ? 0 : 10,
                      paddingRight: width < 870 ? 0 : 10,
                      // paddingLeft: 0,
                      // paddingRight: 0,
                      paddingTop: width < 870 ? 20 : 0,
                      paddingBottom: width < 870 ? 10 : 0,
                      borderRight: `${width < 870 ? 0 : 1}px solid white`,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid white",
                        borderRadius: "100%",
                        width: 30,
                        height: 30,
                        position: "relative",
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        cursor: location.pathname.includes("my-profile")
                          ? "default"
                          : "pointer",
                        // Image
                        backgroundImage: userInfo.profileImage
                          ? `${
                              userInfo.profileImage.local
                                ? `url(https://files.bidsquawk.com/file/bsq-public/profile/thumbnail/${userInfo.profileImage.local})`
                                : userInfo.profileImage.facebook
                                ? `url(${userInfo.profileImage.facebook})`
                                : userInfo.profileImage.google
                                ? `url(${userInfo.profileImage.google})`
                                : ""
                            }`
                          : "",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                      }}
                    >
                      {userInfo.profileImage ? null : (
                        <FaUser
                          onClick={() => {}}
                          style={{
                            color: darkMode
                              ? Colors.primary_darker
                              : Colors.primary,
                            margin: "auto",
                            fontSize: "150%",
                          }}
                        />
                      )}
                    </div>
                    {width < 870 ? (
                      <p style={{ color: "white", margin: 0, marginLeft: 10 }}>
                        My Profile
                      </p>
                    ) : null}
                  </Nav.Link>
                  {width < 870 ? (
                    <Nav.Link
                      eventKey={2}
                      href="/"
                      style={{ paddingLeft: 0, color: "white" }}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/");
                        checkToggleState();
                      }}
                    >
                      Home
                    </Nav.Link>
                  ) : null}
                </React.Fragment>
              ) : null}
              <Nav>
                {online ? null : (
                  <React.Fragment>
                    {width >= 870 ? (
                      <Nav.Link
                        href={"/contact.html"}
                        active={false}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open("/contact.html", "_blank");
                          checkToggleState();
                        }}
                      >
                        Contact Us
                      </Nav.Link>
                    ) : null}
                  </React.Fragment>
                )}

                {online ? (
                  <React.Fragment>
                    <NavDropdown title="Options" id="collasible-nav-dropdown">
                      <NavDropdown.Item
                        href={"/settings"}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push("/settings");
                          checkToggleState();
                        }}
                      >
                        Settings
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href={"/likes"}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push("/likes");
                          checkToggleState();
                        }}
                      >
                        Liked Items
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        href={"/contact.html"}
                        active={false}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open("/contact.html", "_blank");
                          checkToggleState();
                        }}
                      >
                        Contact Us
                      </NavDropdown.Item>
                      {/* <NavDropdown.Divider /> */}
                      <NavDropdown.Item
                        href={"/legal.html"}
                        active={false}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open("/legal.html", "_blank");
                          checkToggleState();
                        }}
                      >
                        Legal
                      </NavDropdown.Item>

                      <NavDropdown.Divider />
                      {serviceWorker && width >= 870 ? (
                        <React.Fragment>
                          <NavDropdown.Item
                            style={{
                              color: Colors.good,
                              fontWeight: "bold",
                            }}
                            onClick={setUpInstallBtn}
                          >
                            Install Web App
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                        </React.Fragment>
                      ) : null}
                      <NavDropdown.Item onClick={logout}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: Colors.err,
                              margin: 0,
                              fontWeight: "bold",
                              marginRight: 10,
                            }}
                          >
                            Logout
                          </p>
                          <IoMdExit
                            style={{ fontSize: 20, color: Colors.err }}
                          />
                        </div>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </React.Fragment>
                ) : (
                  <Nav.Link
                    eventKey={2}
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      // alignSelf: "center",
                      opacity: loading ? 0.3 : 1,
                      textAlign: "left",
                      marginTop: width < 870 ? 10 : 0,
                    }}
                    onClick={() => {
                      //   ReactGA.event({
                      //     category: "Login",
                      //     action: "Login Initiated",
                      //   });
                      setShowAuth(1);
                      checkToggleState();
                    }}
                    // disabled={loading}
                  >
                    Login
                  </Nav.Link>
                )}
                {!online ? (
                  <React.Fragment>
                    <Nav.Link
                      eventKey={2}
                      style={{
                        // alignSelf: "center",
                        opacity: loading ? 0.3 : 1,
                      }}
                      onClick={() => {
                        //   ReactGA.event({
                        //     category: "Signup",
                        //     action: "Signup Initiated",
                        //   });
                        setShowAuth(2);
                        checkToggleState();
                      }}
                    >
                      Sign Up
                    </Nav.Link>
                    {width < 870 ? (
                      <Nav.Link
                        eventKey={2}
                        style={{ paddingLeft: 0, color: "white" }}
                        onClick={() => {
                          history.push("/");
                          checkToggleState();
                        }}
                      >
                        Home
                      </Nav.Link>
                    ) : null}
                  </React.Fragment>
                ) : null}

                {width < 870 && online ? (
                  <React.Fragment>
                    <Nav.Link
                      eventKey={2}
                      style={{}}
                      onClick={() => {
                        window.open("/contact.html", "_blank");
                        checkToggleState();
                      }}
                    >
                      Contact Us
                    </Nav.Link>

                    <Nav.Link
                      onClick={() => {
                        window.open("/legal.html", "_blank");
                        // history.push("/legal.html");
                        checkToggleState();
                      }}
                    >
                      Legal
                    </Nav.Link>
                  </React.Fragment>
                ) : null}
                {width < 870 ? (
                  <React.Fragment>
                    {serviceWorker ? (
                      <Nav.Link
                        // eventKey={2}
                        style={{
                          color: Colors.good,
                          fontWeight: "bold",
                          marginBottom: 20,
                        }}
                        onClick={setUpInstallBtn}
                      >
                        Install Web App
                      </Nav.Link>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: 20,
                      }}
                    >
                      <DisplayMode />
                    </div>
                    <SocialButtons />
                  </React.Fragment>
                ) : null}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>

      {online && !socketStatus ? (
        <div
          style={{ padding: 5, backgroundColor: Colors.critical, zIndex: 50 }}
        >
          <div>
            <p
              style={{
                margin: 0,
                fontWeight: "bold",
                textAlign: "center",
                fontSize: 16,
                color: "white",
              }}
            >
              CONNECTION ISSUE!
            </p>
          </div>
        </div>
      ) : null}

      {online && live ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            bottom: width < 870 && !deviceDetect().isBrowser ? 70 : 10,
            marginBottom: 10,
            left: 10,
            zIndex: 61,
            opacity: location.pathname === "/alerts" ? 0.3 : 1,

            // backgroundColor: Colors.primary_darker,
          }}
          id={"alert_silence"}
        >
          <div
            onClick={() => props.setShowAlerts((state) => !state)}
            style={{
              marginTop: 10,
              borderRadius: 200,
              overflow: "hidden",
              border: "1px solid white",
              backgroundColor: props.showAlerts
                ? darkMode
                  ? Colors.primary_darker
                  : Colors.primary
                : Colors.err,
              height: 50,
              width: 50,
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // opacity: props.showAlerts ? 1 : 0.3,
              position: "relative",
            }}
          >
            {!props.showAlerts ? (
              <div
                style={{
                  position: "absolute",
                  width: 5,
                  height: 40,
                  backgroundColor: "white",
                  margin: "auto",
                  transform: "rotate(45deg)",
                  border: "1px solid " + Colors.err,
                }}
              />
            ) : null}
            <IoMdNotifications
              style={{
                transition: "200ms",
                fontSize: 35,
                color: "white",
              }}
            />
          </div>
          <HoverComp
            eleId={"alert_silence"}
            text={`${
              props.showAlerts ? "Disable" : "Enable"
            } popup notification`}
            style={{ top: 0, left: 0 }}
          />
        </div>
      ) : null}

      {null && width < foldingWidth && online && live ? (
        <div
          style={{
            width: "100%",
            minHeight: tint ? "100%" : "0%",
            backgroundColor: tint ? Colors.transparent_high : "transparent",
            position: "fixed",
            zIndex: 199,
            transitionDuration: "400ms",
          }}
          // onClick={() => {
          //   const ele = document.getElementById("responsive-navbar-nav");
          //   const ele2 = document.getElementById("navBarToggle");
          //   ele2.classList.add("collapsed");
          //   ele.classList.remove("show");
          //   setTimeout(() => {
          //     // ele.classList.remove("collapse");
          //     setTimeout(() => {
          //       ele.classList.add("collapsing");
          //       setTimeout(() => {
          //         ele.classList.remove("collapsing");
          //         setTimeout(() => {
          //           ele.classList.add("collapse");
          //           setTint(false);
          //         }, 100);
          //       }, 100);
          //     }, 100);
          //   }, 100);
          // }}
        />
      ) : null}

      {canInstall ? (
        <React.Fragment>
          <div
            className="shadow"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 1000,
              width: "100%",
              backgroundColor: Colors.primary,
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ color: "white", fontWeight: "bold", margin: 10 }}>
              {`For the best experience of BidSquawk install our Web Application to your ${
                isMobile ? "mobile device Home/App Screen" : "Desktop"
              } in just a few seconds.`}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "center",
                alignItems: "center",
                // paddingBottom: 10,
              }}
            >
              <button
                id={"install-button"}
                className="universalBtn"
                onClick={setUpInstallBtn}
                style={{ width: 80, backgroundColor: Colors.good }}
              >
                Install
              </button>
              <button
                id={"cancel-install-button"}
                className="universalBtn"
                onClick={() => {
                  setCanInstall(false);
                  if (!showAgain) {
                    cacheShowAgain();
                  }
                }}
                style={{ width: 80 }}
              >
                Cancel
              </button>
            </div>
            <CheckBox
              message={"Don't show again"}
              check={!showAgain}
              setCheck={() => setShowAgain(!showAgain)}
              frameStyle={{ transform: "scale(0.8)" }}
            />
          </div>
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              backgroundColor: Colors.transparent_high,
              zIndex: 900,
            }}
          />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
});

const ToastNotification = (props) => {
  const [date, setDate] = React.useState(new Date());
  const [time, setTime] = React.useState("...");

  const userInfo = useSelector((state) => state.auth.userInfo);
  const darkMode = useSelector((state) => state.display.darkMode);

  const [id_1, setId_1] = React.useState(uuidv4());

  const changeTime = () => {
    const timeInfo = moment(props.payload.data.time).fromNow();
    setTime(timeInfo);
  };

  React.useEffect(() => {
    console.log({ payload: props.payload });
    const interval = setInterval(() => {
      changeTime();
    }, 1000);
    return () => {
      if (props.notifyList.current) {
        const listCheck = props.notifyList.current.filter((ele, index) => {
          if (ele.id === props.payload.data.id) {
            props.notifyList.current.splice(index);
            console.log(props.notifyList.current);
          }
        });
        console.log({ listCheck });
      }
      if (interval) {
        clearInterval(interval);
        console.log({ interval });
      }
    };
  }, []);

  if (!props.payload) return <React.Fragment />;

  return (
    <React.Fragment key={id_1}>
      {!props.payload.data ? (
        <React.Fragment />
      ) : (
        <div
          style={{
            zIndex: 60,
            backgroundColor: darkMode ? Colors.primary_darker : Colors.primary,
          }}
          onClick={() => {
            console.log({ alertsRef: props.alertsRef });
            if (props.alertsRef) {
              props.alertsRef();
            }
          }}
        >
          <p style={{ margin: 0 }}>
            {props.payload.data.userName
              ? `${props.payload.data.userName}`
              : props.payload.data.title
              ? props.payload.data.title.length > 25
                ? props.payload.data.title.slice(0, 25)
                : `${props.payload.data.title} ${
                    props.payload.data.title.length > 25 ? "..." : ""
                  } `
              : "..."}{" "}
            {props.count ? `(${props.count})` : ""}
          </p>
          <p style={{ margin: 0 }}>
            {props.payload.data.body
              ? `${props.payload.data.body.slice(0, 25)} ${
                  props.payload.data.body.length > 25 ? "..." : ""
                }`
              : props.payload.data.msg
              ? `${props.payload.data.msg.slice(0, 25)} ${
                  props.payload.data.msg.length > 25 ? "..." : ""
                }`
              : "..."}
          </p>
          <p style={{ margin: 0, fontSize: 10, fontWeight: "100" }}>{time}</p>
        </div>
      )}
    </React.Fragment>
  );
};

const NewItemBtn = (props) => {
  const location = useLocation();
  const [width, height] = useWindowSize();
  const darkMode = useSelector((state) => state.display.darkMode);

  return (
    <React.Fragment>
      <div
        onClick={() => props.setVisible((state) => !state)}
        style={{
          border: "1px solid white",
          borderRadius: 20,
          width: 40,
          height: 30,
          position: "relative",
          backgroundColor: darkMode ? Colors.primary_darker : Colors.primary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          // marginRight: width < 870 ? 10 : 0,
          // Image
          // backgroundImage: `url(${"https://files.bidsquawk.com/file/bsq-public/post/regular/15836dpqiboalj6gsi1r-9d8dbb5f-2b22-487b-b4a2-7a5f38240620-1619968399149.jpeg"})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <GoPlus style={{ fontSize: 20, color: "white" }} />
      </div>
    </React.Fragment>
  );
};

const DisplayMode = () => {
  const darkMode = useSelector((state) => state.display.darkMode);
  const dispatch = useDispatch();
  return (
    <button
      onClick={() => {
        dispatch({ type: darkMode ? "LIGHT" : "DARK" });
      }}
      style={{
        border: "1px solid white",
        borderRadius: 20,
        width: 40,
        height: 30,
        position: "relative",
        backgroundColor: darkMode ? Colors.darkBackground : Colors.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        // marginRight: width < 870 ? 10 : 0,
        // Image
        // backgroundImage: `url(${"https://files.bidsquawk.com/file/bsq-public/post/regular/15836dpqiboalj6gsi1r-9d8dbb5f-2b22-487b-b4a2-7a5f38240620-1619968399149.jpeg"})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        transition: "300ms ease-in-out",
        overflow: "hidden",
      }}
    >
      <FaSun
        style={{
          fontSize: 20,
          color: "yellow",
          marginRight: 3,
          marginLeft: 3,
          transform: `scale(${darkMode ? "0.5" : "1"})`,
          transition: "600ms ease-in-out",
          position: "absolute",
          left: darkMode ? "-100%" : "16%",
          bottom: darkMode ? 0 : 4,
        }}
      />

      <FaMoon
        style={{
          fontSize: 20,
          color: "lightgrey",
          marginRight: 3,
          marginLeft: 3,
          transform: `scale(${darkMode ? "1" : "0.5"})`,
          transition: "600ms ease-in-out",
          position: "absolute",
          right: !darkMode ? "-100%" : "18%",
          bottom: !darkMode ? 0 : 4,
        }}
      />
    </button>
  );
};

export default NavBar;
