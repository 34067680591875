/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useImperativeHandle, lazy, Suspense } from "react";

import { useLocation, useParams, useHistory } from "react-router-dom";

import { FaChevronRight, FaUser } from "react-icons/fa";
import { MdVerifiedUser } from "react-icons/md";
import {
  BiChevronsLeft,
  BiChevronLeft,
  BiChevronsRight,
  BiChevronRight,
} from "react-icons/bi";

import ModalView from "../ModalView";
import InfinityScroll from "../InfinityScroll";

import { callServer } from "../../store/helpers/actionHelper";

import Rating, { Star } from "../Rating";

// import { socket } from "../../config/config";

import {
  useIsMountedRef,
  useWindowSize,
  useMemoryState,
} from "../../utils/customHooks";

import { demo } from "../../assets/data";
import Colors from "../../constants/Colors";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";
import { Alert } from "react-bootstrap";
import { TheUser } from "./ChatModal";
import NumberFormat from "react-number-format";
import CheckBox from "../CheckBox";

import { useSocket } from "../../socket/socketHook";

// let socket = {};

const iconsStyle = {
  color: "white",
  fontSize: 30,
  transition: "1000ms",
  marginRight: 10,
  marginLeft: 10,
  cursor: "pointer",
};

let messageListener_1,
  messageListener_2,
  messageListener_3,
  messageListener_4,
  type_listener_1,
  itemRef_listener,
  rate_limiter;

const Messages = forwardRef((props, ref) => {
  const online = useSelector((state) => state.auth.token);
  const darkMode = useSelector((state) => state.display.darkMode);

  const socket = useSocket();

  const theTabId = localStorage.getItem("tabId");

  const [width, height] = useWindowSize();
  const isMountRef = useIsMountedRef();
  const url_search = useLocation();
  const history = useHistory();
  const params = useParams();

  const [data, setData] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  // const [chatId, setChatId] = React.useState(null);
  const [sellerId, setSellerId] = React.useState(null);
  const [sellerInfo, setSellerInfo] = React.useState(null);
  const [allData, setAllData] = React.useState(null);
  const [initial, setInitial] = React.useState(false);

  const [messages, setMessages] = useMemoryState(`messages-${theTabId}`, []);
  const [lastMsgDate, set_lastMsgDate] = useMemoryState(
    `lastMsgDate-${theTabId}`,
    null
  );

  React.useEffect(() => {
    if (online) {
      fetchChats();
      setInitial(false);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [online]);

  const depsString = JSON.stringify(messages);
  React.useEffect(() => {
    messageListener_3 = socket.socketListenOn("msg", (data) => {
      updateMessages(data, "msg");
    });
    messageListener_4 = socket.socketListenOn("reply", (data) => {
      updateMessages(data, "reply");
    });

    // runCount();

    return () => {
      messageListener_3 = null;
      messageListener_4 = null;
    };
  }, [depsString]);

  React.useEffect(() => {
    /**
     *
     *
     * Load all messages from a chat
     *      Create a load chat function.
     *
     *
     */
  }, [props.chatId]);

  useImperativeHandle(ref, () => ({
    fetchChatsRef() {
      // fetchChats();
      console.log("AUTO LOAD");
    },
  }));

  const runCount = (chatId) => {
    return;
    // if (chatId) {
    //   messages.forEach((chat) => {
    //     if (chatId === chat.chatId) {
    //       console.log(12345, chat);

    //       let unReadCount = 0;
    //       props.setMsgCount(unReadCount);
    //     }
    //   });
    // }
  };

  const updateMessages = (data, type) => {
    console.log({ newMsg: data, messages });

    let theChatInfo = null;
    messages.forEach((ele, index) => {
      if (ele.chatId === data.chatId) {
        theChatInfo = { ele, index };
      }
    });

    const updatedMsg = {};

    console.log({ theChatInfo });

    updatedMsg.chatId = data.chatId;
    updatedMsg.createdAt = data.chatMsg.createdAt;
    updatedMsg.message = data.chatMsg.message;
    updatedMsg.sender = data.senderInfo._id;
    updatedMsg.unread = 10;
    updatedMsg.sellerInfo = data.senderInfo;

    if (type === "msg") {
    }

    var temptList = messages;
    console.log({ temptList });

    if (theChatInfo) {
      temptList.splice(theChatInfo.index, 1);
    }

    console.log({ temptList });

    temptList.unshift(updatedMsg);
    console.log({ theChatInfo, updatedMsg, temptList });

    setMessages(temptList);
  };

  const isTyping = (data) => {
    try {
      console.log(`INSIDE TYPING LISTENER ${new Date()}`, {
        chatId: props.chatId,
        data,
      });
    } catch (err) {
      console.log({ err });
    }
  };

  const checkIfChatOpen = () => {
    console.log(props.show, props.chatId === null);
    if (props.show && props.chatId === null) {
      // fetchChats();
      console.log("FETCHING CHAT UPDATE");
    }
  };

  const fetchChats = async () => {
    try {
      const resData = await callServer({
        apiMethod: "post",
        endPoint: `server/chat/fetch-all`,
        theBody: {
          startDate: null,
        },
      });
      console.log({ messageList: resData });
      if (resData.messages) {
        if (Array.isArray(resData.messages)) {
          setMessages(resData.messages);
          // props.setMsgCount(resData.totalUnread);
        }
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const loadMore = async () => {
    try {
      if (!isMountRef.current) return;
      setIsLoading(true);
      const resData = await callServer({
        apiMethod: "post",
        endPoint: `server/chat/fetch-all`,
        theBody: {
          startDate:
            messages.length > 0
              ? messages[messages.length - 1].createdAt
              : null,
        },
      });
      console.log({ resData });
      if (resData.messages) {
        if (Array.isArray(resData.messages)) {
          setMessages((state) => [...state, ...resData.messages]);
          // props.setMsgCount(resData.totalUnread);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  return (
    <React.Fragment>
      <ModalView
        style={{ maxWidth: 600 }}
        uniqueId={"AuthModal"}
        disable={false}
        visible={showModal}
        loading={loading}
        leftIcon={false}
        leftIconAction={() => {}}
        close={() => setShowModal(false)}
      ></ModalView>
      {messages.length > 0 ? (
        <MessagesList
          chatId={props.chatId}
          tabOpen={props.show}
          setChatId={props.setChatId}
          setShow={props.setShow}
          fetchChats={() => {}}
          loadMore={() => loadMore()}
          isLoading={isLoading}
          messages={messages}
          setMessages={setMessages}
          allData={allData}
          setAllData={setAllData}
          setMsgCount={() => {}}
          setSellerInfo={setSellerInfo}
        />
      ) : null}
      {props.chatId ? (
        <Chat
          fetchChats={() => {}}
          allData={allData}
          setAllData={setAllData}
          loading={loading}
          setLoading={setLoading}
          chatId={props.chatId}
          setChatId={props.setChatId}
          tabOpen={props.show}
          setShow={props.setShow}
          sellerId={sellerId}
          setSellerId={setSellerId}
          setMessages={setMessages}
          updateMessages={updateMessages}
          messages={messages}
          sellerInfo={sellerInfo}
          setSellerInfo={setSellerInfo}
          runCount={runCount}
          msgFunction={props.msgFunction}
        />
      ) : null}
    </React.Fragment>
  );
});

const MessagesList = (props) => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const darkMode = useSelector((state) => state.display.darkMode);
  const history = useHistory();

  React.useEffect(() => {
    console.log({ userInfo });
  }, [userInfo]);

  const updateMessages = (chatId) => {
    // console.log({ newMsg: data, messages });

    var temptList = props.messages;
    console.log({ temptList });

    temptList.map((ele) => {
      if (ele.chatId === chatId) {
        ele.unread = 0;
      }
    });

    props.setMessages(temptList);
  };

  return (
    <React.Fragment>
      <div
        style={{
          // position: "sticky",
          top: 0,
          height: props.chatId ? 0 : 40,
          // maxHeight: props.chatId ? 0 : 10,
          overflow: "hidden",
          width: "100%",
          backgroundColor: Colors.primary_darker,
          // transition: "400ms",
        }}
      ></div>
      <InfinityScroll
        list={props.messages}
        hasMore={false}
        loadMore={() => {}}
        // loadMore={props.loadMore}
        loading={props.isLoading}
        reverse={false}
        style={{
          flex: props.chatId ? "0" : "auto",
          backgroundColor: darkMode
            ? Colors.darkBackground
            : Colors.lightBackground,
        }}
        loadComp={() => (
          <Loading
            style={{
              position: props.messages.length < 1 ? "relative" : "absolute",
              // position: "relative",
              // [messages.length > 0 ? "top" : "bottom"]: 0,
              bottom: 0,
              padding: 10,
            }}
          />
        )}
      >
        {props.messages.length > 0 ? (
          <React.Fragment>
            {props.messages.map((x, index) => {
              return (
                <React.Fragment key={x.chatId}>
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      // marginTop: 5,
                      // marginBottom: 5,
                      width: "98%",
                      // height: 50,
                      padding: 5,
                      // backgroundColor: Colors.harmony_4,
                      borderRadius: 5,
                      marginBottom: 20,
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        // flex: "auto",
                        display: "flex",
                        flexDirection: "row",
                        // flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          height: 60,
                          minHeight: 60,
                          width: 60,
                          minWidth: 60,
                          backgroundImage: x.sellerInfo
                            ? x.sellerInfo.picture
                              ? `${
                                  x.sellerInfo.picture.local
                                    ? `url(https://files.bidsquawk.com/file/bsq-public/profile/thumbnail/${x.sellerInfo.picture.local})`
                                    : x.sellerInfo.picture.facebook
                                    ? `url(${x.sellerInfo.picture.facebook})`
                                    : x.sellerInfo.picture.google
                                    ? `url(${x.sellerInfo.picture.google})`
                                    : ""
                                }`
                              : ""
                            : "",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                          borderRadius: 200,
                          marginRight: 10,
                          backgroundColor: "white",
                          cursor: "pointer",
                          position: "relative",
                          border: "1px solid " + Colors.primary,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          props.setShow(false);
                          history.push(
                            `/user/${x.sellerInfo ? x.sellerInfo.userName : ""}`
                          );
                        }}
                      >
                        {x.sellerInfo ? (
                          x.sellerInfo.picture ? null : (
                            <FaUser
                              onClick={() => {}}
                              style={{
                                color: Colors.primary_darker,
                                margin: "auto",
                                fontSize: "300%",
                              }}
                            />
                          )
                        ) : (
                          <FaUser
                            onClick={() => {}}
                            style={{
                              color: Colors.primary_darker,
                              margin: "auto",
                              fontSize: "300%",
                            }}
                          />
                        )}
                      </div>
                      <div
                        style={{ flex: "auto", cursor: "pointer" }}
                        onClick={() => {
                          props.setChatId(x.chatId);
                          props.setSellerInfo(x.sellerInfo);
                          props.setAllData(x);
                          updateMessages(x.chatId);
                          // props.setMsgCount((state) => state - x.unread);
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <p
                            style={{
                              margin: 0,
                              color: darkMode
                                ? Colors.darModeText
                                : Colors.lightModeText,
                              fontWeight: x.read ? "normal" : "bold",
                            }}
                          >
                            {x.isProUser ? (
                              <MdVerifiedUser
                                style={{
                                  // position: "absolute",
                                  color: "#3FBF3F",
                                  fontSize: 14,
                                  // position: "absolute",
                                  right: -5,
                                  top: -2,
                                  marginRight: 5,
                                  display: "inline-block",
                                }}
                              />
                            ) : null}
                            {x.sellerInfo.userName}
                          </p>
                          {x.unread < 1 ? null : (
                            <div
                              style={{
                                backgroundColor: Colors.good,
                                borderRadius: 20,
                                marginLeft: 10,
                                width: 10,
                                height: 10,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {/* <p
                                style={{
                                  color: Colors.primary,
                                  fontWeight: x.read ? "normal" : "bold",
                                  fontSize: 10,
                                  margin: 0,
                                }}
                              >
                                {x.unread > 99 ? "99+" : x.unread}
                              </p> */}
                            </div>
                          )}
                        </div>
                        <p
                          style={{
                            margin: 0,
                            color: darkMode
                              ? Colors.darModeText
                              : Colors.lightModeText,
                            fontWeight: x.read ? "normal" : "bold",
                            fontSize: 12,
                          }}
                        >
                          {x.message.length > 50
                            ? `${x._id} - ${x.message}`.slice(0, 50) + "..."
                            : x.message}
                        </p>
                        <p
                          style={{
                            margin: 0,
                            color: darkMode
                              ? Colors.darModeText
                              : Colors.lightModeText,
                            fontSize: 10,
                            textAlign: "right",
                            width: "100%",
                            fontWeight: x.read ? "normal" : "bold",
                          }}
                        >
                          {moment(x.createAt).format("MMMM Do YYYY, h:mm:ss a")}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        minWidth: 10,
                        minHeight: 10,
                        // backgroundColor: x.read ? "read" : Colors.good,
                        borderRadius: 50,
                        position: "absolute",
                        display: "flex",
                        margin: 5,
                        top: 0,
                        right: 0,
                      }}
                    >
                      {x.sender !== userInfo._id ? (
                        <React.Fragment>
                          <BiChevronsRight
                            style={{
                              color: x.read ? "grey" : Colors.good,
                              fontWeight: x.read ? "normal" : "bold",
                              fontSize: 20,
                            }}
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <BiChevronsLeft
                            style={{
                              color: x.read ? "grey" : Colors.good,
                              fontWeight: x.read ? "normal" : "bold",
                              fontSize: 20,
                            }}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </InfinityScroll>
    </React.Fragment>
  );
};

const Chat = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMounted = useIsMountedRef();
  const userInfo = useSelector((state) => state.auth.userInfo);

  const socket = useSocket();

  const chatId = useSelector((state) => state.chat.chatId);

  const [lastChatDate, setLastChatDate] = React.useState(new Date());

  const content = React.createRef();
  const scrollArea = React.createRef();

  // const [messages, setMessages] = React.useState(new Array(10).fill(null));
  const [messages, setMessages] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [msg, setMsg] = React.useState("");
  const [sent, setSent] = React.useState(true);

  React.useEffect(() => {
    console.log("MESSAGES CHANGED ", { messages });
  }, [messages.length]);

  React.useEffect(() => {
    return () => {};
  }, []);

  const depsString_1 = JSON.stringify(messages);

  React.useEffect(() => {
    if (socket) {
      console.log(`Socket inside Messages`);

      messageListener_1 = socket.socketListenOn("msg", (data) => {
        if (props.chatId === data.chatId) {
          console.log({ chatMsg: data.chatMsg });
          pushMsg(data.chatMsg);
        }
      });
      messageListener_2 = socket.socketListenOn("reply", (data) => {
        if (props.chatId === data.chatId) {
          console.log({ reply: data });
          pushMsg(data.chatMsg);
        }
      });
    }

    return () => {
      messageListener_1 = null;
      messageListener_2 = null;
    };
  }, [socket]);

  React.useEffect(() => {
    fetch();
    readMsgs();
  }, [props.chatId]);

  const pushMsg = (msg) => {
    console.log("SOCKET TRIGGER");
    readMsgs();
    setMessages((state) => [...state, msg]);
  };

  const updateMessages = (data, type) => {
    console.log({ newMsg: data, messages });

    let theChatInfo = null;
    props.messages.forEach((ele, index) => {
      if (ele.chatId === props.chatId) {
        theChatInfo = { ele, index };
      }
    });

    const updatedMsg = theChatInfo.ele;
    updatedMsg.unread = 0;

    var temptList = props.messages;
    console.log({ temptList });

    if (theChatInfo) {
      temptList.splice(theChatInfo.index, 1);
      console.log({ temptList });

      temptList.unshift(updatedMsg);

      console.log({ theChatInfo, updatedMsg, temptList });

      props.setMessages(temptList);
      props.runCount(props.chatId);
    }
  };

  const fetch = async () => {
    try {
      setIsLoading(true);
      const resData = await callServer({
        apiMethod: "post",
        endPoint: `server/chat/fetch-chat`,
        theBody: {
          chatId: props.chatId,
          lastChatDate: new Date(),
        },
      });
      setIsLoading(false);
      console.log({ chatMsgs: resData });
      if (resData.lastMsgDate) setLastChatDate(resData.lastMsgDate);
      if (resData.chat) {
        if (resData.chat.length > 0) {
          setMessages(resData.chat);
        }
      }
      console.log({ resData });
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const loadMore = async () => {
    console.log("LOAD INITIATED");
    if (!isMounted.current) return;
    setIsLoading(true);
    try {
      const resData = await callServer({
        apiMethod: "post",
        endPoint: `server/chat/fetch-chat`,
        theBody: {
          chatId: props.chatId,
          lastChatDate,
        },
      });

      console.log({ chatMsgs: resData });
      if (resData.lastMsgDate) setLastChatDate(resData.lastMsgDate);
      if (resData.chat) {
        if (resData.chat.length > 0) {
          setMessages((state) => [...resData.chat, ...messages]);
          //  setLastChatDate(resData.chat[0].createAt);
        }
      }
      console.log({ resData });

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log({ err });
    }
  };

  const sendMsg = async () => {
    try {
      const resData = await callServer({
        apiMethod: "post",
        endPoint: `server/chat/send-msg-chat`,
        theBody: {
          sellerId: props.sellerInfo ? props.sellerInfo._id : null,
          message: msg ? msg : "",
        },
      });
      console.log({ resData });

      setSent(true);
      // setMessages((state) => [...state, resData.returnMsg]);

      setMsg("");
      setTimeout(() => {
        props.setShow(!props.show);
      }, 2000);
    } catch (err) {
      console.log({ err });
    }
  };

  const readMsgs = async () => {
    try {
      await callServer({
        apiMethod: "post",
        endPoint: `server/chat/read`,
        theBody: {
          chatId: props.chatId,
        },
      });

      // updateMessages();
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          // position: "sticky",
          top: 0,
          height: 80,
          width: "100%",
          backgroundColor: Colors.primary_darker,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          padding: 5,
        }}
      >
        <FaChevronRight
          style={{
            ...iconsStyle,
            transform: "rotate(180deg)",
          }}
          onClick={() => {
            props.setChatId(null);
            props.setSellerInfo(null);
            props.setAllData(null);
            // props.fetchChats()
          }}
        />
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              height: 60,
              width: 60,
              backgroundImage: props.sellerInfo
                ? props.sellerInfo.picture
                  ? `${
                      props.sellerInfo.picture.local
                        ? `url(https://files.bidsquawk.com/file/bsq-public/profile/thumbnail/${props.sellerInfo.picture.local})`
                        : props.sellerInfo.picture.facebook
                        ? `url(${props.sellerInfo.picture.facebook})`
                        : props.sellerInfo.picture.google
                        ? `url(${props.sellerInfo.picture.google})`
                        : ""
                    }`
                  : ""
                : "",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              borderRadius: 200,
              marginRight: 10,
              backgroundColor: "white",
              cursor: "pointer",
              position: "relative",
              border: "1px solid " + Colors.primary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              props.setShow(false);
              history.push(
                `/user/${props.sellerInfo ? props.sellerInfo.userName : ""}`
              );
            }}
          >
            {props.sellerInfo ? (
              props.sellerInfo.picture ? null : (
                <FaUser
                  onClick={() => {}}
                  style={{
                    color: Colors.primary_darker,
                    margin: "auto",
                    fontSize: "300%",
                  }}
                />
              )
            ) : (
              <FaUser
                onClick={() => {}}
                style={{
                  color: Colors.primary_darker,
                  margin: "auto",
                  fontSize: "300%",
                }}
              />
            )}
          </div>
          <div>
            <p style={{ margin: 0, color: "white", fontWeight: "bold" }}>
              {props.allData.isProUser ? (
                <MdVerifiedUser
                  style={{
                    // position: "absolute",
                    color: "#3FBF3F",
                    fontSize: 14,
                    // position: "absolute",
                    right: -5,
                    top: -2,
                    marginRight: 5,
                    display: "inline-block",
                  }}
                />
              ) : null}
              {props.sellerInfo
                ? props.sellerInfo.proUser
                  ? props.sellerInfo.proUser.company_name
                    ? props.sellerInfo.proUser.company_name
                    : props.sellerInfo.userName
                  : props.sellerInfo.userName
                  ? props.sellerInfo.userName
                  : "..."
                : "..."}{" "}
              -{" "}
              {props.sellerInfo
                ? props.sellerInfo.location.value_0.toUpperCase()
                : "..."}
            </p>
            <Star
              width={100}
              value={
                props.allData
                  ? props.allData.rating
                    ? props.allData.rating
                    : 0
                  : 0
              }
            />
            {/* <p
              style={{
                marginBottom: 0,
                color: "white",
                fontSize: 11,
                marginTop: 3,
              }}
            >{`${
              props.sellerInfo ? props.sellerInfo.userName : ""
            } is typing....`}</p> */}
          </div>
        </div>
        {/* <FaChevronRight
          style={{
            ...iconsStyle,
            transform: 'rotate(0deg)',
          }}
        /> */}
      </div>

      <InfinityScroll
        list={messages}
        hasMore={true}
        loadMore={loadMore}
        loading={isLoading}
        reverse={true}
        // threshold={100}
        // style={{}}
        loadComp={() => (
          <Loading
            style={{
              position: messages.length < 1 ? "relative" : "absolute",
              padding: 10,
            }}
          />
        )}
      >
        {messages.map((x, index) => {
          return (
            <React.Fragment key={x._id}>
              {/* * * myMsg * msg * itemRef * createdAt * */}
              <ChatMsg
                myMsg={x.userId === userInfo._id}
                createdAt={x.createdAt || new Date()}
                msg={x.message}
                itemRef={x.refItemId}
                refSellerId={x.refSellerId}
                close={() => props.setShow(false)}
                userName={props.sellerInfo ? props.sellerInfo.userName : ""}
              />
            </React.Fragment>
          );
        })}
      </InfinityScroll>
      <div
        style={{
          // position: "sticky",
          top: 0,
          height: 70,
          width: "100%",
          padding: 10,
          backgroundColor: Colors.primary_darker,
          display: "flex",
        }}
      >
        <textarea
          className={"textInput"}
          autoFocus={true}
          placeholder={"Type here..."}
          style={{
            textAlign: "left",
            width: "80%",
            fontSize: 14,
            height: "100%",
            margin: 0,
            marginRight: 5,
            flex: "1 1 auto",
            resize: "none",
          }}
          //   value={description}
          maxLength={2000}
          value={msg}
          onKeyUp={(event) => {
            if (event.key === "Enter" && event.target.value !== "") {
              sendMsg();
            } else {
              // typing();
            }
          }}
          onChange={(value) => {
            setMsg(value.target.value.replace("\n", ""));
          }}
        />
        <button
          className="universalBtn"
          onClick={sendMsg}
          disabled={msg.length < 1}
          style={{
            width: 70,
            height: "100%",
            margin: 0,
            backgroundColor: msg.length < 1 ? "lightgray" : Colors.good,
            fontWeight: "bold",
            transitionDuration: "500ms",
          }}
        >
          Send
        </button>
      </div>
    </React.Fragment>
  );
};

const ChatMsg = (props) => {
  /**
   *
   * myMsg
   * msg
   * itemRef
   * refSellerId
   * createdAt
   *
   */
  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <div
          style={{
            marginLeft: props.myMsg ? "auto" : 5,
            marginRight: props.myMsg ? 5 : "auto",
            marginTop: 5,
            marginBottom: 0,
            maxWidth: "80%",
            // width: 200,
            // minWidth: 10,
            minHeight: 20,
            // backgroundColor: Colors.primary,
            // borderRadius: 5,
            // padding: 5,
            display: "inline-block",
            flexDirection: "column",
            right: 0,
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          {props.itemRef ? (
            <React.Suspense fallback={<Loading />}>
              <ItemRefComp
                close={props.close}
                itemRef={props.itemRef}
                userName={props.userName}
              />
            </React.Suspense>
          ) : props.refSellerId ? (
            <SellerRefComp
              close={props.close}
              refSellerId={props.refSellerId}
            />
          ) : null}
          <div
            style={{
              backgroundColor: Colors.primary,
              padding: 5,
              borderRadius: 5,
            }}
          >
            <p
              style={{
                color: "white",
                margin: 0,
                // width: "auto",
                textAlign: "left",
              }}
            >
              {props.msg}
            </p>
          </div>
        </div>
      </div>
      <p
        style={{
          marginLeft: props.myMsg ? "auto" : 5,
          marginRight: props.myMsg ? 5 : "auto",
          marginTop: 0,
          // width: "80%",
          textAlign: props.myMsg ? "right" : "left",
          marginBottom: 5,
          fontSize: 12,
        }}
      >
        {moment(props.createdAt ? props.createdAt : "").format(
          "MMMM Do YYYY, h:mm:ss a"
        )}
      </p>
    </React.Fragment>
  );
};

const SellerRefComp = (props) => {
  const history = useHistory();

  const [seller, setSeller] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    console.log({ refSellerId: props.refSellerId });
  }, []);

  React.useEffect(() => {
    fetchSellerRef();
  }, []);

  const fetchSellerRef = async () => {
    try {
      const resSeller = await callServer({
        apiMethod: "get",
        endPoint: `server/chat/ref-seller/${props.refSellerId}`,
      });

      console.log({ sellerRefData: resSeller });

      setSeller(resSeller.refSeller);

      setLoading(false);
    } catch (err) {
      console.log({ err });
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : seller ? (
        <React.Fragment>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <TheUser
              x={seller}
              setShow={() => props.close()}
              invert={true}
              displayOnly={true}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            style={{
              margin: 5,
              padding: 5,
              borderRadius: 10,
              backgroundColor: Colors.primary_darker,
            }}
          >
            <p style={{ color: "white", fontWeight: "bold", margin: 0 }}>
              Something went wrong getting this items information...
            </p>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ItemRefComp = (props) => {
  const history = useHistory();

  const token = useSelector((state) => state.auth.token);

  const [item, setItem] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [loadingChoices, setLoadingChoices] = React.useState(false);
  const [sold, setSold] = React.useState(false);

  const [rating, setRating] = React.useState(4);
  const [experience, setExperience] = React.useState(4);

  const socket = useSocket();

  React.useEffect(() => {
    fetchItem();
  }, []);

  const refItemString = JSON.stringify(item);

  React.useEffect(() => {
    console.log("UPDATE LISTENER", { item });
    itemRef_listener = socket.socketListenOn(props.itemRef, (data) => {
      setItem({
        ...data.item,
        imageCount: data.imageCount,
        isYours: data.isYours,
        refData: data.refData,
      });
    });

    // rate_limiter = socket.on(`${props.itemRef}-rateFinish`, (data) => {
    //   setLoadingChoices(false);
    //   console.log({ data });
    // });

    return () => {
      itemRef_listener = null;
      rate_limiter = null;
    };
  }, [refItemString]);

  // const rate_process = async () => {
  //   try {
  //     setLoadingChoices(true);
  //     socket.emit("rate_process", {
  //       token,
  //       data: { rating, experience, itemRefId: props.itemRef },
  //     });
  //   } catch (err) {
  //     console.log({ err });
  //   }
  // };

  const cancelInterest = async () => {
    setLoadingChoices(true);
    try {
      console.log(props.itemRef);
      const resItem = await callServer({
        apiMethod: "get",
        endPoint: `server/chat/ref-item-cancel/${props.itemRef}`,
      });
      setItem({ ...item, refData: {} });

      setLoadingChoices(false);
    } catch (err) {
      console.log({ err });
      setLoadingChoices(false);
    }
  };

  const rateItem = async (accepted) => {
    setLoadingChoices(true);
    try {
      console.log(props.itemRef);
      const resItem = await callServer({
        apiMethod: "post",
        endPoint: `server/chat/ref-item-rate`,
        theBody: {
          itemRefId: props.itemRef,
          rating,
          experience,
        },
      });

      setLoadingChoices(false);
    } catch (err) {
      console.log({ err });
      setLoadingChoices(false);
    }
  };

  const acceptance = async (accepted) => {
    setLoadingChoices(true);
    try {
      console.log(props.itemRef);
      const resItem = await callServer({
        apiMethod: "post",
        endPoint: `server/chat/ref-item-accept`,
        theBody: {
          itemRefId: props.itemRef,
          sold,
          accepted,
        },
      });
      // setItem({ ...item, refData: {} });

      setLoadingChoices(false);
    } catch (err) {
      console.log({ err });
      setLoadingChoices(false);
    }
  };

  const updateInterest = (data) => {
    console.log({ data, item });
    setItem({ ...item, refData: data.refData });
  };

  const fetchItem = async () => {
    try {
      const resItem = await callServer({
        apiMethod: "get",
        endPoint: `server/chat/ref-item/${props.itemRef}`,
      });

      console.log({ resItem });
      setItem({
        ...resItem.item,
        imageCount: resItem.imageCount,
        isYours: resItem.isYours,
        refData: resItem.refData,
      });

      setLoading(false);
    } catch (err) {
      console.log({ err });
      setLoading(false);
    }
  };

  const formatNum = (currency, value) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    });

    return formatter.format(value);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : item ? (
        <React.Fragment>
          <div style={{ position: "relative" }}>
            {loadingChoices ? (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: Colors.transparent_high,
                  zIndex: 60,
                  borderRadius: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loading />
              </div>
            ) : null}
            <div
              style={{
                width: "100%",
                minHeight: 200,
                backgroundColor: "white",
                minWidth: 200,
                // marginBottom: 10,
                // margin: 5,
                padding: 5,
                borderRadius: 5,
                position: "relative",
              }}
            >
              <div
                onClick={() => {
                  props.close();
                  history.push(`/item/${item._id}`);
                }}
                style={{
                  width: "100%",
                  height: 180,
                  // backgroundColor: "#ccc",
                  backgroundImage: `url(https://files.bidsquawk.com/file/bsq-public/post/thumbnail/${
                    item.images ? item.images[item.images.length - 1] : ""
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "contain",
                  cursor: "pointer",
                }}
              />
              <h5
                style={{ textAlign: "left", color: Colors.primary, margin: 0 }}
              >
                {item.title}
              </h5>
              <h6
                style={{ textAlign: "left", color: Colors.primary, margin: 0 }}
              >
                {/* {`${formatNum(item.currency.toUpperCase(), item.price)}`} */}
                {item.currency.toUpperCase()}{" "}
                <NumberFormat
                  value={item.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </h6>
              <h6
                style={{ textAlign: "left", color: Colors.primary, margin: 0 }}
              >
                {`Negotiable: ${item.neg ? "Yes" : "No"}`}
              </h6>
              {/* {
              item.isYours ? (<p style={{ textAlign: 'left', color: Colors.primary, margin: 0 }}>
                This is your item.
              </p>) : null
            } */}
              {item ? (
                <>
                  {item.refData ? (
                    <>
                      {item.refData.interested && !item.refData.accepted ? (
                        <React.Fragment>
                          <div style={{ position: "relative" }} />
                          {item.isYours ? (
                            <React.Fragment>
                              <p
                                style={{ margin: 0 }}
                              >{`${props.userName} is interested in your item`}</p>
                              {item.price !== item.refData.newPrice ? (
                                <React.Fragment>
                                  <p
                                    style={{
                                      margin: 0,
                                      fontWeight: "bolder",
                                      fontSize: 20,
                                    }}
                                  >
                                    {`Offer: ${item.currency.toUpperCase()} `}
                                    <NumberFormat
                                      value={item.refData.newPrice}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  </p>
                                </React.Fragment>
                              ) : null}
                              <div style={{ position: "relative" }}>
                                <div style={{ padding: 5 }}>
                                  <p
                                    style={{
                                      margin: 0,
                                      fontWeight: "bolder",
                                      textAlign: "center",
                                      color: Colors.primary,
                                    }}
                                  >{`Confirm sale of this item to ${props.userName}`}</p>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <button
                                      className="universalBtn shadow"
                                      onClick={() => {
                                        acceptance(true);
                                      }}
                                      style={{
                                        backgroundColor: "green",
                                        color: "white",
                                        width: 100,
                                      }}
                                    >
                                      Yes
                                    </button>
                                    <button
                                      className="universalBtn shadow"
                                      onClick={() => {
                                        acceptance(false);
                                      }}
                                      style={{
                                        backgroundColor: Colors.critical,
                                        color: "white",
                                        width: 100,
                                      }}
                                    >
                                      No
                                    </button>
                                  </div>
                                  <CheckBox
                                    check={sold}
                                    message={"Mark item as sold"}
                                    setCheck={() => setSold((state) => !state)}
                                    frameStyle={{ marginTop: 0 }}
                                    textStyle={{ color: Colors.primary }}
                                    checkStyle={{
                                      border: `1px solid ${Colors.primary}`,
                                    }}
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  margin: 0,
                                  color: Colors.primary,
                                }}
                              >
                                You are interested in this Item
                              </p>
                              {item.price !== item.refData.newPrice ? (
                                <React.Fragment>
                                  <p
                                    style={{
                                      margin: 0,
                                      fontWeight: "bolder",
                                      fontSize: 20,
                                    }}
                                  >
                                    {`Your Offer: ${item.currency.toUpperCase()} `}
                                    <NumberFormat
                                      value={item.refData.newPrice}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                    />
                                  </p>
                                </React.Fragment>
                              ) : null}
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <button
                                  className="universalBtn shadow"
                                  onClick={cancelInterest}
                                  style={{
                                    backgroundColor: Colors.critical,
                                    color: "white",
                                    width: 100,
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : Object.keys(item.refData).length > 1 &&
                        item.refData.interested &&
                        item.refData.accepted ? (
                        <div
                          style={{
                            borderTop: `solid 1px ${Colors.primary}`,
                            marginTop: 10,
                            position: "relative",
                          }}
                        >
                          {/* <div
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              backgroundColor: Colors.transparent_high,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              zIndex: 20,
                            }}
                          >
                            <Loading />
                          </div> */}
                          {item.refData.interested &&
                          item.refData.accepted &&
                          !item.refData.rated &&
                          !item.isYours ? (
                            <>
                              <h5
                                style={{
                                  color: Colors.primary,
                                  margin: 0,
                                  marginTop: 10,
                                }}
                              >
                                Please rate this item & your experience
                              </h5>
                              <p
                                style={{
                                  margin: 0,
                                  fontWeight: "bold",
                                  display: "inline",
                                  color: Colors.primary,
                                }}
                              >
                                Item Rating
                              </p>
                              <p
                                style={{
                                  margin: 0,
                                  fontWeight: "bolder",
                                  display: "inline",
                                  fontSize: 16,
                                }}
                              >{` ${rating}`}</p>
                              <Rating
                                value={rating}
                                setValue={setRating}
                                width={150}
                              />
                              <p
                                style={{
                                  margin: 0,
                                  fontWeight: "bold",
                                  display: "inline",
                                  color: Colors.primary,
                                }}
                              >
                                Experience Rating
                              </p>
                              <p
                                style={{
                                  margin: 0,
                                  fontWeight: "bolder",
                                  display: "inline",
                                  fontSize: 16,
                                }}
                              >{` ${experience}`}</p>
                              <Rating
                                value={experience}
                                setValue={setExperience}
                                width={150}
                              />
                              <button
                                className="universalBtn shadow"
                                onClick={rateItem}
                                style={{
                                  backgroundColor: Colors.good,
                                  width: 100,
                                  color: Colors.primary,
                                  marginRight: "auto",
                                  marginLeft: "auto",
                                }}
                              >
                                Submit
                              </button>
                            </>
                          ) : item.refData.interested &&
                            item.refData.accepted &&
                            !item.refData.rated &&
                            item.isYours ? (
                            <>
                              <p
                                style={{
                                  marginTop: 10,
                                  marginLeft: 0,
                                  marginBottom: 10,
                                  marginRight: 0,
                                  fontWeight: "bold",
                                  color: Colors.good,
                                }}
                              >
                                Awaiting customer rating...
                              </p>
                            </>
                          ) : (
                            <React.Fragment>
                              <p
                                style={{
                                  marginTop: 10,
                                  marginBottom: 0,
                                  textAlign: "center",
                                  width: "100%",
                                  fontWeight: "bold",
                                  // marginRight: 0,
                                  // color: Colors.good,
                                }}
                              >
                                {`You ${
                                  item.isYours ? "SOLD" : "PURCHASED"
                                } this item:`}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    color: Colors.primary,
                                    margin: 0,
                                    marginRight: 10,
                                    fontWeight: "bold",
                                    // marginTop: 10,
                                  }}
                                >
                                  {`Rating: ${item.refData.rating}`}/5
                                </p>
                                <Star
                                  // width={60}
                                  // style={{
                                  //   marginRight: "auto",
                                  //   marginLeft: "auto",
                                  // }}
                                  value={parseFloat(item.refData.rating)}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            style={{
              margin: "auto",
              padding: 5,
              borderRadius: 10,
              backgroundColor: Colors.primary_darker,
            }}
          >
            <p style={{ color: "white", fontWeight: "bold", margin: 0 }}>
              Something went wrong getting this items information...
            </p>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Loading = (props) => (
  <div
    style={{
      // top: 5,
      justifySelf: "center",
      marginLeft: "auto",
      marginRight: "auto",
      zIndex: 30,
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "center",

      width: "100%",
      ...props.style,
    }}
  >
    <div
      style={{
        padding: 10,

        borderRadius: 20,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <img
        alt="logo"
        className={"App-logo"}
        src={"/logo.svg"}
        style={{ width: 50 }}
      />
    </div>
  </div>
);

export default Messages;
