/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import "./Auth.css";

import Colors from "../../constants/Colors";

import ModalView from "../ModalView";

import Login from "./Login";
import SignUp from "./SignUp";
import ForgotPass from "./ForgotPass";

const Auth = (props) => {
  const [showBack, setShowBack] = React.useState(false);
  const [disable, setDisable] = React.useState(false);

  const goBack = () => {
    if (props.showAuth > 2 && props.showAuth < 3) {
      if (props.showAuth === 2.2) {
        props.setShowAuth(2);
      } else {
        props.setShowAuth(props.showAuth - 0.1);
      }
    } else if (props.showAuth > 3 && props.showAuth < 4) {
      props.setShowAuth(props.showAuth - 0.1);
    } else if (props.showAuth > 4 && props.showAuth < 5) {
      props.setShowAuth(props.showAuth - 0.1);
    } else if (props.showAuth > 5 && props.showAuth < 6) {
      props.setShowAuth(props.showAuth - 0.1);
    } else {
      props.setShowAuth(1);
    }
  };

  React.useEffect(() => {
    // console.log({ currentAuth: props.showAuth });
  }, [props.showAuth]);

  return (
    <React.Fragment>
      <ModalView
        style={{ maxWidth: 600 }}
        uniqueId={"AuthModal"}
        disable={disable}
        visible={props.showAuth}
        loading={props.loading}
        leftIcon={showBack}
        leftIconAction={goBack}
        close={() => props.setShowAuth(false)}
      >
        {props.showAuth === 1 ? (
          <Login
            fcmToken={props.fcmToken}
            loading={props.loading}
            showAuth={props.showAuth}
            setLoading={props.setLoading}
            setShowAuth={props.setShowAuth}
            setDisable={setDisable}
            setShowBack={setShowBack}
            close={() => props.setShowAuth(false)}
          />
        ) : (props.showAuth >= 2 && props.showAuth < 3) ||
          (props.showAuth >= 4 && props.showAuth < 6) ? (
          <SignUp
            fcmToken={props.fcmToken}
            loading={props.loading}
            setLoading={props.setLoading}
            showAuth={props.showAuth}
            setShowAuth={props.setShowAuth}
            setShowBack={setShowBack}
            setDisable={setDisable}
            goBack={goBack}
            close={() => props.setShowAuth(false)}
          />
        ) : props.showAuth >= 3 && props.showAuth < 4 ? (
          <ForgotPass
            loading={props.loading}
            showAuth={props.showAuth}
            setLoading={props.setLoading}
            setShowAuth={props.setShowAuth}
            setShowBack={setShowBack}
            setDisable={setDisable}
            close={() => props.setShowAuth(false)}
          />
        ) : null}
      </ModalView>
    </React.Fragment>
  );
};

export default Auth;
