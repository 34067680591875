/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, useImperativeHandle } from "react";

import { useSelector } from "react-redux";

// import {SERVER_URL} from "../../config/config";

import { FiCheck } from "react-icons/fi";
import {
  FaTrashAlt,
  FaUpload,
  FaTrashRestore,
  FaTruck,
  FaGlobeAmericas,
} from "react-icons/fa";

import CurrencyInput from "react-currency-input-field";

import { useHistory } from "react-router-dom";
import ModalView from "../ModalView";
import Tags from "../Tags";

import { ProgressBar } from "react-bootstrap";

import axios from "axios";

import {
  useIsMountedRef,
  useWindowSize,
  useMemoryState,
} from "../../utils/customHooks";

import Colors from "../../constants/Colors";

import List from "../List";
import LocationSearch from "../LocationSearch";
import CategoryList from "../CatList";
import { SERVER_URL } from "../../config/config";
import Marquee from "react-fast-marquee";
import CheckBox from "../CheckBox";
import { MdPeople } from "react-icons/md";
import { sortLocation } from "../../utils/helper";

const maxWidth = 500;

const Create = forwardRef((props, ref) => {
  const [width, height] = useWindowSize();
  const isMountRef = useIsMountedRef();

  const limits = useSelector((state) => state.limits);

  const [picLimit, setPicLimit] = React.useState(limits.regUserImageLimit);
  const [tagsLimit, setTagsLimit] = React.useState(limits.regUserTagLimit);
  const [descriptionLimit, setDescriptionLimit] = React.useState(
    limits.descriptionLimit
  );

  const userInfo = useSelector((state) => state.auth.userInfo);
  const token = useSelector((state) => state.auth.token);
  const currencies = useSelector((state) => state.currency.currencies);
  const conditions = useSelector((state) => state.cond.conditions);
  const appLocations = useSelector((state) => state.loc.locations);

  const history = useHistory();

  const [data, setData] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [files, setFiles] = React.useState([]);
  const [storedFiles, setStoredFiles] = React.useState([]);
  const [storedFileSelect, setStoredFileSelect] = React.useState("");
  const [deletedFiles, setDeletedFiles] = React.useState([]);

  const [src, setSrc] = React.useState(0);

  const [title, setTitle] = React.useState("");
  const [titleErr, setTitleErr] = React.useState("");
  const [price, setPrice] = React.useState();
  const [priceErr, setPriceErr] = React.useState("");

  const [currency, setCurrency] = React.useState();
  const [currencyErr, setCurrencyErr] = React.useState();

  const [conditionLook, setConditionLook] = React.useState({
    title: "NEW",
    value: "new",
  });
  const [conditionLookErr, setConditionLookErr] = React.useState();

  const [conditionRate, setConditionRate] = React.useState({
    title: 10,
    value: 10,
  });
  const [conditionRateErr, setConditionRateErr] = React.useState();

  const [tags, setTags] = React.useState([]);

  const [mainErr, setMainErr] = React.useState("");

  const [uploading, setUploading] = React.useState(false);

  const [location, setLocation] = React.useState("");
  const [showLoc, setShowLoc] = React.useState(false);
  const [locationReady, setLocationReady] = React.useState(false);
  const [location_err, setLocation_err] = React.useState(null);

  const [categoryReady, setCategoryReady] = React.useState(false);
  const [showCat, setShowCat] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [valueErr, setValueErr] = React.useState();
  const [showList, setShowList] = React.useState(false);

  const negList = [
    {
      title: "Neg",
      value: true,
    },
    {
      title: "Firm",
      value: false,
    },
  ];

  const [neg, setNeg] = React.useState(negList[1]);

  const [description, setDescription] = React.useState("");
  const [descriptionErr, setDescriptionErr] = React.useState("");

  const [meetUp, setMeetUp] = React.useState(true);
  const [shipping, setShipping] = React.useState(true);
  const [international, setInternational] = React.useState(false);

  const [curList, setCurList] = React.useState();
  const [condList, setCondList] = React.useState();
  const [condListRate, setCondListRate] = React.useState();

  const [loaded, setLoaded] = React.useState(0);

  const [showMsg, setShowMsg] = React.useState(false);
  const [showAgain, setShowAgain] = React.useState(true);

  const [sold, setSold] = React.useState(true);

  const [reset, setReset] = React.useState(Date.now());

  const [symbol, setSymbol] = React.useState("");

  React.useEffect(() => {
    if (currencies.length > 0) {
      // console.log({ currency });
      const currency_select = currencies.find(
        (cur) =>
          cur.currency.toUpperCase() ===
          (currency ? currency.value.toUpperCase() : "")
      );
      if (!currency_select) {
        setSymbol("$ ");
      } else {
        setSymbol(`${currency_select.currency_symbol} `);
      }
    }
  }, [currency]);

  React.useEffect(() => {
    return () => {
      props.setLoading(false);
    };
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // console.log({ location });
  }, [location]);

  React.useEffect(() => {
    // console.log(props.loading, showLoc);
  }, [loaded, reset, showLoc]);

  React.useEffect(() => {
    // console.log({ files, storedFiles, src });
  }, [files, src, reset]);

  // Auto set Location
  React.useEffect(() => {
    if (!props.edit || !props.itemInfo) {
      // console.log({ userInfo, user_location: userInfo.location });
      // const parsedUserLocation = sortLocation(appLocations, userInfo.location);

      setLocation(userInfo.location);
    } else if (props.edit && props.itemInfo) {
      const itemLoc = { value_0: props.itemInfo.location.value_0 };
      // console.log({ appLocations });
      if (itemLoc) {
        setLocation(props.itemInfo.location);
      }
    } else {
    }

    if (userInfo) {
      if (userInfo.proUser) {
        setPicLimit(limits.proUserImageLimit);
        setTagsLimit(limits.proUserTagLimit);
        setDescriptionLimit(limits.descriptionLimit);
      }
    }
  }, [userInfo, reset, limits]);

  // Auto set currency
  React.useEffect(() => {
    if (currencies) {
      if (currencies.length > 0) {
        let tempt = [];

        currencies.forEach((cur) => {
          tempt.push({
            title: cur.currency.toUpperCase(),
            value: cur.currency,
          });
        });

        setCurList(tempt);

        // console.log(userInfo.location.value_0.toUpperCase());

        if (userInfo.location && !props.edit) {
          if (userInfo.location.value_0) {
            const curValue = currencies.find(
              (ele) =>
                ele.iso2.toUpperCase() ===
                userInfo.location.value_0.toUpperCase()
            );
            // console.log({ curValue });
            if (curValue) {
              setCurrency({
                title: curValue.currency.toUpperCase(),
                value: curValue.currency,
              });
            }
          }
        } else if (props.edit && props.itemInfo) {
          // console.log({ currencies }, props.itemInfo.currency);
          const theCurVal = currencies.find(
            (x) =>
              x.currency.toUpperCase() === props.itemInfo.currency.toUpperCase()
          );
          // console.log({ theCurVal });

          if (theCurVal) {
            setCurrency({
              title: theCurVal.currency.toUpperCase(),
              value: theCurVal.currency,
            });
          }
        }
      }
    }
  }, [currencies, reset]);

  // Auto set condition
  React.useEffect(() => {
    if (conditions) {
      if (conditions.length > 0) {
        let tempt = [];

        conditions.forEach((cond) => {
          tempt.push({ title: cond.toUpperCase(), value: cond });
        });

        setCondList(tempt);
      }
    }

    const temptRate = [];

    for (let i = 1; i <= 10; ) {
      temptRate.push({ title: i, value: i });
      if (i === 10) setCondListRate(temptRate);
      i++;
    }
  }, [conditions, reset]);

  // Auto set Edit option
  React.useEffect(() => {
    if (props.edit && props.itemInfo) {
      // console.log({ editItem: props.itemInfo });
      setTitle(props.itemInfo.title);
      setPrice(parseFloat(props.itemInfo.price));
      setTags(props.itemInfo.tags);
      setDescription(props.itemInfo.description);
      setSold(props.itemInfo.sold);
      setValue({
        title: props.itemInfo.category.mainCat,
        value: {
          ...props.itemInfo.category,
        },
      });
      setConditionRate({
        title: props.itemInfo.condition.rating,
        value: props.itemInfo.condition.rating,
      });
      setConditionLook({
        title: props.itemInfo.condition.look.toUpperCase(),
        value: props.itemInfo.condition.look,
      });
      const theNegVal = negList.find((x) => x.value === props.itemInfo.neg);

      setNeg(theNegVal);
      setMeetUp(props.itemInfo.pickUp.meetUp);
      setInternational(props.itemInfo.pickUp.international);
      setShipping(props.itemInfo.pickUp.shipping);
      setStoredFiles([...props.itemInfo.images]);
      setSrc(
        `https://files.bidsquawk.com/file/bsq-public/post/regular/${props.itemInfo.images[0]}`
      );
      setFiles([]);
      setDeletedFiles([]);
    }
  }, [reset]);

  React.useEffect(() => {
    const showCreateItem = localStorage.getItem("showCreateItem");
    if (!showCreateItem) {
      setShowMsg(true);
    }
    // console.log({ showCreateItem });
  }, []);

  useImperativeHandle(ref, () => ({
    doAction() {
      // console.log("Action");
    },
  }));

  const updateFile = (event) => {
    if (event.target !== undefined) {
      //   console.log({ file: event.target.files });
      if (event.target.files[0] !== undefined) {
        let formats = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
        if (formats.includes(event.target.files[0].type)) {
          const reader = new FileReader();
          const currentFile = event.target.files[0];
          // console.log({ currentFile });
          reader.addEventListener(
            "load",
            () => {
              if (reader.result) {
                setFiles([
                  ...files,
                  {
                    uri: reader.result,
                    name: event.target.files[0].name,
                    type: event.target.files[0].type,
                  },
                ]);
                setSrc(reader.result);
              }
            },
            false
          );
          reader.readAsDataURL(currentFile);

          // const tempt = file;
          // tempt.push({
          //   uri: event.target.files[0],
          // });

          setFiles([
            ...files,
            {
              uri: event.target.files[0],
            },
          ]);
          setSrc(event.target.files[0]);
        }
      }
    }
  };

  const validateValue = (value) => {
    const rawValue = value === undefined ? "undefined" : value;

    if (
      Number(rawValue.target.value.replace(/\$|,/g, "")).toString() === "NaN"
    ) {
      return;
    } else {
      setPrice(rawValue.target.value.replace(/\$|,/g, "") || " ");
    }
  };

  const changeLocation = (value) => {
    setLocation(value);
    setLocation_err("");
  };

  const changeValue = (value) => {
    setValue(value);
  };

  const deleteImage = () => {
    try {
      // if (
      //   props.itemInfo &&
      //   ((files.length <= 0 && storedFiles.length <= 1) ||
      //     (files.length <= 1 && storedFiles.length <= 0))
      // )
      //   return;
      let temptFiles;
      try {
        // console.log(src.split("/")[src.split("/").length - 1], {
        //   files,
        //   src,
        //   find: files.find((file) => file.uri === src),
        //   index: files.findIndex((file) => file.uri === src),
        //   valid: files[2].uri === src,
        // });
        // console.log(src.split("/")[src.split("/").length - 1], {
        //   storedFiles,
        //   src,
        //   find: storedFiles.find(
        //     (file) => file === src.split("/")[src.split("/").length - 1]
        //   ),
        //   index: storedFiles.indexOf(src.split("/")[src.split("/").length - 1]),
        //   valid: storedFiles[2] === src.split("/")[src.split("/").length - 1],
        // });
      } catch (err) {
        console.log("DELETE IMAGE ERROR", { err });
        throw new Error(err);
      }
      // return;

      // console.log({ storedFiles });

      if (files.length === 1) {
        setFiles([]);
        setStoredFiles([]);
      } else if (files.find((file) => file.uri === src)) {
        temptFiles = files;
        // console.log("IS IN FILES");

        const theIndex = files.findIndex((file) => file.uri === src);
        // console.log({ theIndex });
        temptFiles.splice(theIndex, 1);
        setFiles([...temptFiles]);

        // console.log({ temptFiles });

        if (theIndex > 0) {
          let temptSrc = theIndex - 1;
          setSrc(files[temptSrc].uri);
        } else if (temptFiles.length > 0) {
          setSrc(temptFiles[0].uri);
        } else if (storedFiles.length > 0) {
          setSrc(storedFiles[0]);
        } else {
          setSrc("");
        }

        // console.log({ temptFiles });
      } else if (
        storedFiles.find(
          (file) => file === src.split("/")[src.split("/").length - 1]
        )
      ) {
        // console.log("IS IN STOREDFILES");
        temptFiles = storedFiles;

        const theIndex = storedFiles.indexOf(
          src.split("/")[src.split("/").length - 1]
        );

        setDeletedFiles([
          ...deletedFiles,
          src.split("/")[src.split("/").length - 1],
        ]);

        // temptFiles.splice(theIndex, 1);
        // setStoredFiles([...temptFiles]);

        // console.log({ theIndex, temptFiles });

        // if (theIndex > 0) {
        //   let temptSrc = theIndex - 1;
        //   setSrc(`https://files.bidsquawk.com/file/bsq-public/post/regular/${temptFiles[temptSrc]}`);
        // } else if (temptFiles.length > 0) {
        //   setSrc(
        //     `https://files.bidsquawk.com/file/bsq-public/post/regular/${temptFiles[0]}`
        //   );
        // } else if (files.length > 0) {
        //   setSrc(files[0]);
        // } else {
        //   setSrc("");
        // }
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const restoreImage = () => {
    try {
      if (files.length + storedFiles.length - deletedFiles.length >= 10) return;
      // console.log({ src }, src.split("/")[src.split("/").length - 1]);
      const theIndex = deletedFiles.indexOf(
        src.split("/")[src.split("/").length - 1]
      );
      // console.log({ theIndex });
      const temptFiles = deletedFiles;
      temptFiles.splice(theIndex, 1);
      setDeletedFiles([...temptFiles]);
    } catch (err) {
      console.log({ err });
    }
  };

  /***
   *
   *
   */

  const validate = async () => {
    try {
    } catch (err) {
      console.log({ err });
    }
  };

  const createPost = async () => {
    try {
      // const blobs = await getImages(files);
      // console.log({ blobs });
      // console.log({ value, location });

      // return;
      props.setLoading(true);

      if (
        files.length + storedFiles.length - deletedFiles.length > 0 &&
        title.length > 3 &&
        currency &&
        conditionLook.value &&
        conditionRate.value &&
        location &&
        (value
          ? value.value
            ? value.value.subCat
              ? value.value.subCat.value !== "all"
                ? true
                : false
              : false
            : false
          : false) &&
        description.length > 10
      ) {
        let formData = new FormData();
        let count = 0;
        setUploading(true);
        const newLocation = {
          title: location.title,
          value_0: location.value_0,
          value_1: location.value_1,
        };

        if (location.value_2) {
          newLocation.value_2 = location.value_2;
        }

        // console.log({ newLocation });
        const doUpload = async () => {
          try {
            const stringData = {
              itemId: props.itemInfo ? props.itemInfo._id : null,
              title,
              currency: currency.value,
              price,
              condition: {
                look: conditionLook.value,
                rating: conditionRate.value,
              },
              location: newLocation,
              category: value.value,
              description,
              tags,
              pickUp: {
                meetUp,
                shipping,
                international,
              },
              neg: neg.value,
              sold,
              deletedFiles,
            };
            // console.log({ stringData });

            formData.append("stringData", JSON.stringify(stringData));

            const resData = await axios.post(
              `${SERVER_URL}/server/seller/${props.edit ? "edit" : "create"}`,
              formData,
              {
                withCredentials: true,
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "content-type": "multipart/form-data",
                  authorization: `Bearer ${token}`,
                },
                data: {},
                onUploadProgress: (ProgressEvent) => {
                  setLoaded((ProgressEvent.loaded / ProgressEvent.total) * 100);
                },
              }
            );

            // console.log({ resData });

            if (resData.data.err) {
              setUploading(false);
              setMainErr(resData.data.err.msg);
              throw resData.data.err;
            } else {
              // setUploading(false);

              // return;

              if (props.itemInfo) {
                if (props.fetchItemDetails) {
                  props.fetchItemDetails();
                }
              } else {
                history.push("/item/" + resData.data.itemId);
              }
              if (props.close) {
                props.setLoading(false);
                props.close();
              }

              // console.log("Upload", { resData });
              // setTimeout(() => setLoaded(0), 3000);
            }
          } catch (err) {
            setUploading(false);
            props.setLoading(false);
            console.log([err]);
          }
        };

        if (files.length > 0) {
          files.forEach(async (file) => {
            try {
              // console.log({ file });
              const rawData = await fetch(file.uri);
              const image = await rawData.blob();
              formData.append("files", image);
              count++;

              if (count === files.length) {
                return doUpload();
              }
            } catch (err) {
              setUploading(false);
              props.setLoading(false);
              console.log({ err });
            }
          });
        } else {
          return doUpload();
        }
      }
    } catch (err) {
      setUploading(false);
      props.setLoading(false);
      setMainErr(err.msg);
      console.log({ err });
    }
  };

  return (
    <React.Fragment>
      <ModalView
        style={{ maxWidth: 1200 }}
        uniqueId={"edit_modal"}
        disable={false}
        visible={showModal}
        loading={loading}
        leftIcon={false}
        leftIconAction={() => {}}
        close={() => setShowModal(false)}
      >
        {/*  */}
      </ModalView>

      {showMsg ? (
        <div
          style={{
            // position: "absolute",
            top: 0,
            left: 0,
            maxWidth: "100%",
            height: "100%",
            // backgroundColor: Colors.primary,
            zIndex: 60,
            // borderRadius: 20,
            display: "flex",
            flexDirection: "column",
            // flexWrap: "wrap",
            justifyContent: "center",
            paddingTop: 20,
            alignItems: "center",
          }}
        >
          <img
            alt="logo"
            // className="App-logo"
            src={"/logo.svg"}
            style={{ width: 150 }}
          />
          <p
            style={{
              color: "white",
              // backgroundColor: "#ccc",
              textAlign: "center",
              maxWidth: 300,
              margin: 10,
            }}
          >
            All fields should be filled out. All post are required to have a
            minimum of one image. Tags are optional but please be aware that
            tags help users to find your post in search, tags are also used when
            recommending post that are similar to a post being viewed.
          </p>
          {/* <div
            className={"checkContainer"}
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              margin: 10,
            }}
            onClick={() => setShowAgain(!showAgain)}
          >
            <p style={{ color: "white", marginBottom: 0, marginRight: 10 }}>
              Don't show again
            </p>
            <input
              type="checkbox"
              style={{ width: 20 }}
              checked={!showAgain}
              onChange={(value) => {
                console.log({ value: value.target.value });
              }}
            />
            <span className="checkMarkBox">
              {!showAgain ? (
                <FiCheck color={"white"} style={{ fontSize: 18 }} />
              ) : null}
            </span>
            
          </div> */}
          <button
            className="universalBtn"
            style={{ color: Colors.primary, backgroundColor: Colors.good }}
            onClick={() => {
              setShowMsg(false);
              if (!showAgain) {
                localStorage.setItem("showCreateItem", true);
              }
            }}
          >
            OK
          </button>
          <CheckBox
            message={"Don't show again"}
            check={!showAgain}
            setCheck={() => setShowAgain((state) => !state)}
            frameStyle={{ transform: "scale(0.8)" }}
          />
        </div>
      ) : (
        <div
          style={{ height: "100%", transition: "500ms", position: "relative" }}
        >
          {uploading ? (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: Colors.transparent_high,
                zIndex: 60,
                borderRadius: 20,
                display: "flex",
                flexDirection: "column",
                // flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="logo"
                className="App-logo"
                src={"/logo.svg"}
                style={{ width: "80%" }}
              />
              <div style={{ margin: 20, width: "80%" }}>
                <ProgressBar
                  striped
                  variant="success"
                  now={loaded.toFixed(0)}
                  label={`${loaded.toFixed(0)}%`}
                />
              </div>
              <p style={{ color: "white" }}>
                {loaded < 99 ? "Uploading" : "Processing your post."}
              </p>
            </div>
          ) : null}
          <h2 style={{ marginBottom: 30 }}>
            {props.edit ? "Edit" : "Create"} Post
          </h2>
          {props.edit && props.itemInfo ? (
            <React.Fragment>
              <CheckBox
                message={"Sold"}
                check={sold}
                setCheck={() => setSold((state) => !state)}
                frameStyle={{}}
              />
            </React.Fragment>
          ) : null}
          <p style={{ color: Colors.err, textAlign: "center" }}>{mainErr}</p>
          <div
            style={{
              // padding: 10,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-around",
              alignItems: "flex-start",
              position: "relative",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                // backgroundColor: "#ccc",
                width: width < 660 ? "100%" : "50%",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    maxWidth: 800,
                    minWidth: 200,
                    width: "100%",
                    height: "100%",
                    maxHeight: 800,
                    minHeight: 400,
                    // aspectRatio: "1/1",
                    // backgroundColor: "#ccc",
                    border: "1px solid #ccc",
                    backgroundImage: `url(${
                      files.length > 0 || storedFiles.length > 0 ? src : ""
                    })`,
                    backgroundPosition: "center center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  {files.length < 1 && storedFiles.length < 1 ? (
                    <p
                      style={{
                        color: "white",
                        textAlign: "center",
                        padding: 20,
                      }}
                    >
                      A minimum of 1 image is required.
                    </p>
                  ) : null}
                </div>
                {userInfo.proUser ? null : (
                  <p
                    style={{
                      color: "white",
                      fontSize: 12,
                      margin: 0,
                      textAlign: "center",
                    }}
                  >{`Get more images as a Pro User up to ${limits.proUserImageLimit} images per post, more images will make your post more interesting to users.`}</p>
                )}
                <p
                  style={{
                    color:
                      files.length + storedFiles.length - deletedFiles.length <
                        1 ||
                      files.length + storedFiles.length - deletedFiles.length >=
                        10
                        ? Colors.err
                        : "white",
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}
                >
                  {files.length + storedFiles.length - deletedFiles.length}/
                  {picLimit}
                </p>

                {files.length > 0 || storedFiles.length > 0 ? (
                  <button
                    className="universalBtn"
                    disabled={
                      props.itemInfo
                        ? storedFiles.length > 0
                          ? deletedFiles.includes(
                              typeof src === "string"
                                ? src.split("/")[src.split("/").length - 1]
                                : ""
                            )
                            ? files.length +
                                storedFiles.length -
                                deletedFiles.length >=
                              picLimit
                              ? true
                              : false
                            : false
                          : false
                        : false
                    }
                    style={{
                      padding: 5,
                      margin: 5,
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontWeight: "bold",
                      color: Colors.primary,
                      backgroundColor: props.itemInfo
                        ? storedFiles.length > 0
                          ? deletedFiles.includes(
                              typeof src === "string"
                                ? src.split("/")[src.split("/").length - 1]
                                : ""
                            )
                            ? Colors.good
                            : Colors.err
                          : Colors.err
                        : Colors.err,
                      cursor: "pointer",
                      textAlign: "center",
                      opacity: props.itemInfo
                        ? storedFiles.length > 0
                          ? deletedFiles.includes(
                              typeof src === "string"
                                ? src.split("/")[src.split("/").length - 1]
                                : ""
                            )
                            ? files.length +
                                storedFiles.length -
                                deletedFiles.length >=
                              10
                              ? 0.3
                              : 1
                            : 1
                          : 1
                        : 1,
                    }}
                    onClick={
                      props.itemInfo
                        ? storedFiles.length > 0
                          ? deletedFiles.includes(
                              typeof src === "string"
                                ? src.split("/")[src.split("/").length - 1]
                                : ""
                            )
                            ? restoreImage
                            : deleteImage
                          : deleteImage
                        : deleteImage
                    }
                  >
                    {props.itemInfo ? (
                      storedFiles.length > 0 ? (
                        deletedFiles.includes(
                          typeof src === "string"
                            ? src.split("/")[src.split("/").length - 1]
                            : ""
                        ) ? (
                          <FaTrashRestore
                            style={{ marginBottom: 0, marginRight: 10 }}
                          />
                        ) : (
                          <FaTrashAlt
                            style={{ marginBottom: 0, marginRight: 10 }}
                          />
                        )
                      ) : (
                        <FaTrashAlt
                          style={{ marginBottom: 0, marginRight: 10 }}
                        />
                      )
                    ) : (
                      <FaTrashAlt
                        style={{ marginBottom: 0, marginRight: 10 }}
                      />
                    )}
                    {props.itemInfo
                      ? storedFiles.length > 0
                        ? deletedFiles.includes(
                            typeof src === "string"
                              ? src.split("/")[src.split("/").length - 1]
                              : ""
                          )
                          ? "Keep Image"
                          : "Remove Image"
                        : "Remove Image"
                      : "Remove Image"}
                  </button>
                ) : null}
              </div>

              {props.itemInfo ? (
                <h5 style={{ color: "white", marginBottom: 0 }}>
                  Saved Images
                </h5>
              ) : null}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  maxWidth: 300,
                }}
              >
                {storedFiles.map((file, index) => {
                  const x = `https://files.bidsquawk.com/file/bsq-public/post/thumbnail/${file}`;
                  const y = `https://files.bidsquawk.com/file/bsq-public/post/regular/${file}`;
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setSrc(y);
                      }}
                      style={{
                        border: "1px solid #ccc",
                        position: "relative",
                        width: 50,
                        height: 50,
                        margin: 5,
                        backgroundImage: `url(${x})`,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        opacity: src === y ? 0.3 : 1,
                        cursor: "pointer",
                        borderRadius: 5,
                      }}
                    >
                      {deletedFiles.includes(file) ? (
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            top: 0,
                            left: 0,
                            backgroundColor: "red",
                            opacity: 0.5,
                          }}
                        >
                          <FaTrashAlt
                            color={"white"}
                            style={{ fontSize: 14, marginLeft: 5 }}
                          />
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
              {props.itemInfo && files.length > 0 ? (
                <h5 style={{ color: "white", marginBottom: 0 }}>New Images</h5>
              ) : null}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  maxWidth: 300,
                }}
              >
                {files.map((file, index) => {
                  // console.log({ file });
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setSrc(file.uri);
                      }}
                      style={{
                        border: "1px solid #ccc",
                        width: 50,
                        height: 50,
                        margin: 5,
                        backgroundImage: `url(${file.uri})`,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        opacity: src === file.uri ? 0.3 : 1,
                        cursor: "pointer",
                        borderRadius: 5,
                      }}
                    ></div>
                  );
                })}
              </div>
              <React.Fragment>
                <input
                  id={`file-upload`}
                  accept="image/png, image/jpeg, image/jpg, image/gif"
                  type="file"
                  onChange={updateFile}
                  multiple={false}
                  disabled={
                    files.length + storedFiles.length - deletedFiles.length >=
                    picLimit
                      ? true
                      : false
                  }
                  style={{ display: "none" }}
                />
                <label
                  className={
                    files.length + storedFiles.length - deletedFiles.length >=
                    picLimit
                      ? "universalBtnStatic"
                      : "universalBtn"
                  }
                  htmlFor={`file-upload`}
                  style={{
                    opacity:
                      files.length + storedFiles.length - deletedFiles.length >=
                      picLimit
                        ? 0.3
                        : 1,
                    marginBottom: 30,
                  }}
                >
                  <p
                    tabIndex="0"
                    role="button"
                    aria-controls="filename"
                    style={{
                      textDecoration: "none",
                      margin: 0,
                      color: Colors.primary,
                      fontWeight: "bold",
                    }}
                  >
                    {<FaUpload style={{ marginBottom: 4, marginRight: 10 }} />}
                    {"Add Image"}
                  </p>
                </label>
              </React.Fragment>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "flex-start",
                marginTop: 10,
                maxWidth,
                // padding: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                  maxWidth,
                  // padding: 10,
                }}
              >
                {titleErr ? (
                  <p style={{ ...styles.errMsg }}>{titleErr}</p>
                ) : null}
                <p
                  style={{
                    margin: 0,
                    color: "white",
                    textAlign: "center",
                    marginBottom: 5,
                  }}
                >
                  Enter Title of Post
                </p>
                <input
                  className={"textInput"}
                  style={{
                    opacity: props.loading ? 0.3 : 1,
                    margin: 0,
                    marginBottom: 15,
                    width: "100%",
                  }}
                  disabled={props.loading}
                  type={"text"}
                  name="title"
                  placeholder={"Title"}
                  maxLength="60"
                  value={title}
                  onChange={(value) => {
                    if (props.loading) return;
                    setTitleErr("");
                    setTitle(value.target.value);
                  }}
                />
                {/* <br /> */}
                {priceErr ? (
                  <p style={{ ...styles.errMsg }}>{priceErr}</p>
                ) : null}
                {/* <br /> */}
                <p
                  style={{
                    margin: 0,
                    color: "white",
                    textAlign: "center",
                    marginBottom: 5,
                  }}
                >
                  Select a Currency and enter Price
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    maxWidth,
                    width: "100%",
                    margin: 0,
                    padding: 0,
                    marginBottom: 15,
                  }}
                >
                  <List
                    style={{
                      // width: 50,
                      maxWidth: 150,
                      borderRadius: 5,
                      height: 200,
                      padding: 0,
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 70,
                      margin: 0,
                      marginRight: 20,
                    }}
                    // className="shadow"
                    value={currency}
                    setValue={setCurrency}
                    list={curList}
                  />
                  <CurrencyInput
                    className={"textInput"}
                    id="price"
                    name="price"
                    placeholder="Price"
                    value={price}
                    style={{
                      // width: 140,
                      margin: 0,
                      // marginBottom: 15,
                      // marginLeft: 10,
                      flex: 1,
                    }}
                    // defaultValue={1}
                    prefix={symbol}
                    decimalsLimit={2}
                    onChange={validateValue}
                  />
                </div>
                {/* <br /> */}
                <p
                  style={{
                    margin: 0,
                    color: "white",
                    textAlign: "center",
                    marginBottom: 5,
                  }}
                >
                  Stance on Pricing
                </p>
                <div style={{ flex: 1, marginBottom: 15 }}>
                  <List
                    style={{
                      width: 100,
                      borderRadius: 5,
                      height: 70,
                      padding: 0,
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 59,
                    }}
                    value={neg}
                    setValue={setNeg}
                    list={negList}
                  />
                </div>

                <div>
                  <p
                    style={{
                      color: "white",
                      textAlign: "center",
                      marginBottom: 5,
                    }}
                  >
                    Condition & Appearance Rating
                  </p>
                </div>
                {/* <br /> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    alignItems: "flex-start",
                    width: 200,
                    marginBottom: 15,
                  }}
                >
                  <List
                    style={{
                      width: 80,
                      borderRadius: 5,
                      height: 80,
                      padding: 0,
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 58,
                    }}
                    // className="shadow"
                    value={conditionLook}
                    setValue={setConditionLook}
                    list={condList}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <List
                      style={{
                        width: 50,
                        borderRadius: 5,
                        height: 80,
                        padding: 0,
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 58,
                      }}
                      // className="shadow"
                      value={conditionRate}
                      setValue={setConditionRate}
                      list={condListRate}
                    />
                    <p
                      style={{
                        color: "white",
                        marginBottom: 0,
                        marginLeft: 3,
                        fontSize: 18,
                      }}
                    >
                      {" / 10"}
                    </p>
                  </div>
                </div>
                {/* <br /> */}
                {location_err ? (
                  <p style={{ ...styles.errMsg }}>{location_err}</p>
                ) : null}
                {/* <br /> */}
                <p
                  style={{
                    margin: 0,
                    color: "white",
                    textAlign: "center",
                    marginBottom: 5,
                  }}
                >
                  Please select a Country and State
                </p>
                <div
                  style={{
                    position: "relative",
                    margin: 0,
                    marginBottom: 15,
                    cursor: "pointer",
                    opacity: props.loading ? 0.3 : 1,
                    maxWidth,
                    flex: 1,
                    width: "100%",
                    // zIndex: 56,
                  }}
                >
                  {/* <input
                    className={"textInput"}
                    placeholder={"Location"}
                    value={""}
                    style={{ margin: 0 }}
                    onChange={(value) => {
                      return;
                    }}
                  ></input> */}
                  <div
                    onClick={() => {
                      console.log({ location });
                      if (props.loading) return;
                      setShowLoc((state) => !state);
                      // changeValue(false);
                    }}
                    style={{
                      top: 0,
                      margin: 0,
                      padding: 0,
                      position: "absolute",
                      width: "100%",
                      // height: "100%",
                      backgroundColor:
                        location || showLoc ? "white" : "transparent",
                      borderRadius: 5,
                      opacity: location || showLoc ? 1 : 0,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 30,
                    }}
                  >
                    {location && !showLoc ? (
                      <Marquee
                        gradientWidth={20}
                        play={true}
                        // gradientColor={[100, 100, 100]}
                        speed={30}
                        style={{
                          backgroundColor: "transparent",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 12,
                            margin: 0,
                            whiteSpace: "nowrap",
                            marginRight: 20,
                          }}
                        >
                          {`${location.value_0.toUpperCase()}`}
                          {location.title ? ` - ${location.title}` : null}
                          {location.value_1 ? ` - ${location.value_1}` : null}
                          {location.value_2 ? ` - ${location.value_2}` : null}
                        </p>
                      </Marquee>
                    ) : (
                      <React.Fragment>
                        <div
                          style={{
                            width: 100,
                            backgroundColor: Colors.err,
                            borderRadius: 200,
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          <p
                            style={{
                              whiteSpace: "nowrap",
                              marginTop: 0,
                              marginBottom: 0,
                              marginLeft: 0,
                              color: Colors.primary,
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            {"Close"}
                          </p>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  <p
                    style={{
                      margin: 0,
                      color: "white",
                      textAlign: "center",
                      marginBottom: 5,
                    }}
                  >
                    Please select a Country and State
                  </p>
                  <LocationSearch
                    visible={props.loading ? false : showLoc}
                    width={"100%"}
                    location={location}
                    setLocationReady={setLocationReady}
                    changeLocation={changeLocation}
                    notSearch={true}
                    general={true}
                    styles={{
                      left: 0,
                      // marginRight: "auto",
                      // marginLeft: 'auto'
                      // left: 0,
                      // margin: 0,
                      // padding: 0,
                      // marginBottom: 15,
                    }}
                  />
                </div>
                <p
                  style={{
                    margin: 0,
                    color: "white",
                    textAlign: "center",
                    marginBottom: 5,
                  }}
                >
                  Please select a Category and Sub-Category
                </p>
                <div style={{ marginBottom: 15, width: "100%" }}>
                  <CategoryList
                    post={true}
                    loading={props.loading}
                    setLoading={props.setLoading}
                    width={"100%"}
                    style={{ borderRadius: 5 }}
                    value={value}
                    setValue={(value) => {
                      setShowLoc(false);
                      changeValue(value);
                    }}
                    setCategoryReady={setCategoryReady}
                    showList={showList}
                    setShowList={() => setShowList((state) => !state)}
                  />
                </div>
                <p
                  style={{
                    margin: 0,
                    color: "white",
                    textAlign: "center",
                    marginBottom: 5,
                  }}
                >
                  Enter description and/or details of item.
                </p>
                <textarea
                  className={"textInput"}
                  placeholder={"Description"}
                  style={{
                    textAlign:
                      !description || description.length < 1
                        ? "center"
                        : "left",
                    fontSize: 12,
                    height: 120,
                    margin: 0,
                    width: "100%",
                  }}
                  value={description}
                  maxLength={descriptionLimit}
                  onChange={(value) => {
                    setDescription(value.target.value);
                  }}
                />
                <p
                  style={{
                    textAlign: "center",
                    color:
                      description.length >= descriptionLimit ||
                      description.length < 10
                        ? Colors.err
                        : "#ccc",
                    width: "100%",
                    marginTop: 2,
                    marginBottom: 15,
                    fontSize: 10,
                  }}
                >
                  {description.length}/{descriptionLimit}
                </p>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className={"checkContainer"}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 10,
                      marginTop: 0,
                    }}
                    onClick={() => setMeetUp(!meetUp)}
                  >
                    <p
                      style={{
                        color: "white",
                        marginBottom: 0,
                        marginRight: 10,
                      }}
                    >
                      <MdPeople
                        style={{
                          marginRight: 5,
                          fontSize: 20,
                          marginBottom: 3,
                        }}
                      />
                      Meet up
                    </p>
                    <input
                      type="checkbox"
                      style={{ width: 20 }}
                      checked={meetUp}
                      onChange={(value) => {
                        console.log({ value: value.target.value });
                      }}
                    />
                    <span className="checkMarkBox">
                      {meetUp ? (
                        <FiCheck color={"white"} style={{ fontSize: 18 }} />
                      ) : null}
                    </span>
                  </div>

                  <div
                    className={"checkContainer"}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 10,
                    }}
                    onClick={() => setShipping(!shipping)}
                  >
                    <p
                      style={{
                        color: "white",
                        marginBottom: 0,
                        marginRight: 10,
                      }}
                    >
                      <FaTruck
                        style={{
                          marginRight: 5,
                          fontSize: 18,
                          marginBottom: 3,
                        }}
                      />
                      Delivery
                    </p>
                    <input
                      type="checkbox"
                      style={{ width: 20 }}
                      checked={shipping}
                      onChange={(value) => {
                        console.log({ value: value.target.value });
                      }}
                    />
                    <span className="checkMarkBox">
                      {shipping ? (
                        <FiCheck color={"white"} style={{ fontSize: 18 }} />
                      ) : null}
                    </span>
                  </div>

                  <div
                    className={"checkContainer"}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 10,
                    }}
                    onClick={() => setInternational(!international)}
                  >
                    <p
                      style={{
                        color: "white",
                        marginBottom: 0,
                        marginRight: 10,
                      }}
                    >
                      <FaGlobeAmericas
                        style={{
                          marginRight: 5,
                          fontSize: 16,
                          marginBottom: 3,
                        }}
                      />
                      International
                    </p>
                    <input
                      type="checkbox"
                      style={{ width: 20 }}
                      checked={international}
                      onChange={(value) => {
                        console.log({ value: value.target.value });
                      }}
                    />
                    <span className="checkMarkBox">
                      {international ? (
                        <FiCheck color={"white"} style={{ fontSize: 18 }} />
                      ) : null}
                    </span>
                  </div>
                </div>
              </div>

              <div style={{ width: "100%" }}>
                {userInfo.proUser ? null : (
                  <p
                    style={{
                      color: "white",
                      fontSize: 12,
                      margin: 0,
                      textAlign: "center",
                    }}
                  >{`Get more tags as a Pro User up to ${limits.proUserTagLimit} tags per post, tags makes it easier for users to find your post in search and it helps us to suggest your post with similar items.`}</p>
                )}
                <Tags
                  tags={tags}
                  setTags={setTags}
                  disable={false}
                  limit={tagsLimit}
                />
              </div>
            </div>
          </div>

          <button
            className="universalBtn"
            disabled={
              !uploading &&
              files.length + storedFiles.length - deletedFiles.length > 0 &&
              title.length > 3 &&
              currency &&
              conditionLook.value &&
              conditionRate.value &&
              location &&
              (value
                ? value.value
                  ? value.value.subCat
                    ? value.value.subCat.value !== "all"
                      ? true
                      : false
                    : false
                  : false
                : false) &&
              description.length > 10
                ? false
                : true
            }
            style={{
              marginTop: 30,
              backgroundColor: Colors.good,
              color: Colors.primary,
              fontWeight: "bold",
              marginRight: "auto",
              marginLeft: "auto",
              opacity:
                !uploading &&
                files.length + storedFiles.length - deletedFiles.length > 0 &&
                title.length > 3 &&
                currency &&
                conditionLook.value &&
                conditionRate.value &&
                location &&
                (value
                  ? value.value
                    ? value.value.subCat
                      ? value.value.subCat.value !== "all"
                        ? true
                        : false
                      : false
                    : false
                  : false) &&
                description.length > 10
                  ? 1
                  : 0.3,
            }}
            onClick={() => {
              createPost();
              // console.log({
              //   title,
              //   price,
              //   tags,
              //   currency,
              //   location,
              //   value,
              //   files,
              // });
            }}
          >
            {props.edit ? "Save" : "Post"}
          </button>
          {props.edit ? (
            <p
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                color: "white",
                textAlign: "center",
                width: 300,
                padding: 10,
                marginBottom: 0,
                cursor: "pointer",
              }}
              onClick={() => setReset(Date.now())}
            >
              Reset
            </p>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
});

const styles = {
  errMsg: {
    color: Colors.err,
    margin: 0,
    textAlign: "center",
  },
};

export default Create;
