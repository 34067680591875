export default function LoadComp(props) {
  return (
    <>
      {props.hide ? null : (
        <div
          style={{
            justifySelf: "center",
            marginLeft: "auto",
            marginRight: "auto",
            zIndex: 30,
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "center",

            width: props.width || 200,
          }}
        >
          <div
            style={{
              padding: 10,

              borderRadius: 20,
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <img
              alt="logo"
              className={"App-logo"}
              src={"/logo.svg"}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      )}
    </>
  );
}
