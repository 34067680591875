import React, { useEffect } from "react";

import Stars from "../assets/5star.svg";
import Stars_gold from "../assets/5star_gold.svg";

import "./Rating.css";

/**
 *
 * Star props
 *    width
 *    value
 *
 */

export const Star = (props) => {
  return (
    <div
      style={{
        // display: "block",
        position: "relative",
        padding: 0,
        width: props.width ? props.width : 100,
        height: props.width ? props.width / 5 : 20,
        ...props.style,
        overflow: "hidden",
      }}
    >
      <img
        src={Stars}
        // className="App-logo"
        alt="stars"
        style={{
          // display: "display",
          width: props.width ? props.width : 100,
          height: props.width ? props.width / 5 : 20,
          margin: 0,
          color: "white",
          //   backgroundColor: "white",
          opacity: 0.2,
          position: "absolute",
        }}
      />
      <div
        style={{
          position: "relative",
          width: `${props.value ? props.value * 2 * 10 : 0}%`,
          // width: props.value
          //   ? (((props.value / 5) * 100) / 100) * props.width
          //   : 0,
          height: "100%",
          overflow: "hidden",
          // backgroundColor: "#ccc",
          zIndex: 11,
          padding: 0,
          position: "absolute",
        }}
      >
        <img
          src={Stars_gold}
          // className="App-logo"
          alt="gold stars"
          style={{
            // display: "display",
            width: props.width ? props.width : 100,
            height: props.width ? props.width / 5 : 20,
            margin: 0,
            color: "white",
            top: 0,
            //   backgroundColor: "white",
            //   opacity: 0,
            position: "absolute",
            zIndex: 10,
          }}
        />
      </div>
    </div>
  );
};

const Rating = (props) => {
  const [value, setValue] = React.useState(0);
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Star value={props.value || value} width={props.width} />
      <div
        style={{
          borderRadius: 200,
          height: 18,
          overflow: "hidden",
          display: "inline-block",
          padding: 0,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <input
          className={"slider"}
          type="range"
          min="0"
          max="5"
          step="0.1"
          value={props.value || value}
          id="rating"
          onChange={(value) => {
            // console.log(value.target.value);
            if (props.setValue) {
              props.setValue(value.target.value);
            } else {
              setValue(value.target.value);
            }
          }}
          style={{
            width: props.width || 100,
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
};

export default Rating;
