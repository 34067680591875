/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Colors from "../constants/Colors";
import {
  TiSocialFacebook,
  TiSocialInstagram,
  TiSocialTwitter,
} from "react-icons/ti";
import SocialButtons from "./Social";
import { useSelector } from "react-redux";

const Footer = (props) => {
  const online = useSelector((state) => state.auth.token);
  const darkMode = useSelector((state) => state.display.darkMode);
  return (
    <>
      {online ? null : (
        <React.Fragment>
          <footer
            id="footerBar_0"
            className="shadow"
            style={{
              backgroundColor: darkMode
                ? Colors.primary_darker
                : Colors.primary,
              minHeight: 100,
              padding: 10,
              zIndex: 54,

              // position: "sticky",
              display: "block",
              bottom: 0,
              marginBottom: 0,
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "auto",
              width: "100%",
            }}
          >
            <div
              style={{
                position: "",
                bottom: 10,
                left: 80,
                right: 80,
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-around",
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              <div style={{ ...style.col }}>
                <a href={"#"} style={{ ...style.links }}>
                  Page 1
                </a>
                <a href={"#"} style={{ ...style.links }}>
                  Page 2
                </a>
                <a href={"#"} style={{ ...style.links }}>
                  Page 3
                </a>
                <a href={"#"} style={{ ...style.links }}>
                  Page 4
                </a>
                <a href={"#"} style={{ ...style.links }}>
                  Page 5
                </a>
              </div>
              <div style={{ ...style.col }}>
                <a href={"#"} style={{ ...style.links }}>
                  Page 1
                </a>
                <a href={"#"} style={{ ...style.links }}>
                  Page 2
                </a>
                <a href={"#"} style={{ ...style.links }}>
                  Page 3
                </a>
              </div>
              <div style={{ ...style.col }}>
                <a href={"#"} style={{ ...style.links }}>
                  Page 1
                </a>
                <a href={"#"} style={{ ...style.links }}>
                  Page 2
                </a>
                <a href={"#"} style={{ ...style.links }}>
                  Page 3
                </a>
                <a href={"#"} style={{ ...style.links }}>
                  Page 4
                </a>
              </div>
            </div>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <p style={{ color: "white", textAlign: "center" }}>
                BidSquawk Marketplace ©
              </p>
              <SocialButtons socialStyle={{ width: 180 }} />
            </div>
          </footer>
        </React.Fragment>
      )}
    </>
  );
};

const style = {
  links: { color: "lightgrey", textDecoration: "none" },
  col: { display: "flex", flexDirection: "column", minWidth: 200 },
};

export default Footer;
