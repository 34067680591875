import React from "react";

import { IoMdClose } from "react-icons/io";
import Colors from "../constants/Colors";

// import "../css/scss/tags.css";

const TagsInput = (props) => {
  const limit = 10;
  const [tags, setTags] = React.useState([]);
  const [showError, setShowError] = React.useState(false);

  const [value, setValue] = React.useState("");

  const [initial, setInitial] = React.useState(true);
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  // React.useEffect(() => {
  //   console.log({ initial }, props.tags);
  //   if (props.setTags && !initial) {
  //     console.log("INSIDE TAGS");
  //     props.setTags([...tags]);
  //   }
  // }, [tags]);

  // React.useEffect(() => {
  //   console.log({ initial }, props.tags);
  //   if (props.tags.length > 0) {
  //     setTags([...props.tags]);
  //     setInitial(false);
  //   }
  // }, []);

  const trigger = () => {
    try {
      if (value.length < 3) return;
      const tempt = value.split(" ");
      let word = "";
      tempt.forEach((x, index) => {
        if (!["", undefined, null].includes(x)) {
          if (index === 0) {
            word = x;
          } else {
            word = word + x;
          }
        }
      });
      if (word.length < 3) {
        // console.log("NO WORDS");
        setShowError(true);
        return;
      }
      const test = props.tags.filter(
        (tag) => tag.toLowerCase() === value.toLowerCase()
      );
      if (test.length === 0) {
        // console.log({
        //   tagText: value
        //     .toLowerCase()
        //     .replace(/[^A-Z0-9\s]/gi, "")
        //     .slice(0, 25),
        // });
        props.setTags([
          ...props.tags,
          value
            .toLowerCase()
            .replace(/[^A-Z0-9\s]/gi, "")
            .slice(0, 25),
        ]);
        setValue("");
      } else {
        setShowError(true);
        return;
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      const tempt = event.target.value.split(" ");
      let word = "";
      tempt.forEach((x, index) => {
        if (!["", undefined, null].includes(x)) {
          if (index === 0) {
            word = x;
          } else {
            word = word + x;
          }
        }
      });
      if (word.length < 3) {
        // console.log("NO WORDS");
        setShowError(true);
        return;
      }
      const test = props.tags.filter(
        (tag) => tag.toLowerCase() === event.target.value.toLowerCase()
      );
      if (test.length === 0) {
        // console.log({
        //   tagText: event.target.value
        //     .toLowerCase()
        //     // .trim()
        //     // .replace(/\s/g, "")
        //     .replace(/[^A-Z0-9\s]/gi, "")
        //     .slice(0, 25),
        // });
        props.setTags([
          ...props.tags,
          event.target.value
            .toLowerCase()
            // .trim()
            // .replace(/\s/g, "")
            .replace(/[^A-Z0-9\s]/gi, "")
            .slice(0, 25),
        ]);
        event.target.value = "";
        setValue("");
      } else {
        setShowError(true);
        return;
      }
    } else if (event.key === "Enter") {
      setShowError(true);
      return;
    }
  };

  const removeTags = (index) => {
    setShowError(false);
    props.setTags([
      ...props.tags.filter((tag) => props.tags.indexOf(tag) !== index),
    ]);
  };

  return (
    <React.Fragment>
      <div
        className="tags-input"
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "flex-start",
          border: `1px solid ${Colors.primary_darker}`,
          // maxWidth: 410,
          width: "100%",
          minHeight: 45,
          borderRadius: 5,
          ...props.style,
        }}
      >
        {showError ? (
          <p
            style={{
              margin: 5,
              fontSize: 12,
              color: Colors.err,
              textAlign: "center",
            }}
          >
            Tags must be 3 or more characters (a-z, 0-9) and they cannot be
            repeated
          </p>
        ) : null}
        <ul
          style={{
            margin: 0,
            padding: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.tags.map((tag, index) => (
            <li
              key={index}
              style={{
                textDecoration: "none",
                listStyleType: "none",
                color: "white",
                backgroundColor: Colors.harmony_3,
                padding: 5,
                borderRadius: 5,
                margin: 5,
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              <span style={{ marginLeft: 5 }}>{tag}</span>
              <div
                style={{
                  marginLeft: 5,
                  cursor: "pointer",
                  marginRight: 5,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => removeTags(index)}
              >
                <IoMdClose
                  style={{ fontWeight: "bold", margin: 0, fontSize: 18 }}
                />
              </div>
            </li>
          ))}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              type="text"
              name="tags_input"
              onKeyUp={(event) => {
                addTags(event);
              }}
              placeholder="Type tag here"
              maxLength="25"
              value={value}
              onChange={(value) => {
                setValue(value.target.value.replace(/[^A-Z0-9\s]/gi, ""));
                if (focused) {
                  // console.log({ tagValue: value.target.value });
                  value.target.value = value.target.value.replace(
                    /[^A-Z0-9\s]/gi,
                    ""
                  );

                  setShowError(false);
                }
              }}
              pattern="[a-zA-Z0-9-]+"
              disabled={props.disable || tags.length >= (props.limit || limit)}
              style={{
                margin: 0,
                border: "0px",
                appearance: "none",
                WebkitAppearance: "none",
                outline: "none",
                borderBottom: "1px solid #ccc",
                borderRadius: 5,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                marginRight: "auto",
                marginLeft: "auto",
                opacity:
                  props.disable || tags.length >= (props.limit || limit)
                    ? 0.3
                    : 1,
              }}
            />
            <button
              className={
                value.length < 3 ? "universalBtn_static" : "universalBtn"
              }
              style={{
                width: 30,
                margin: 0,
                height: 24,
                borderWidth: 0,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                fontWeight: "bolder",
                fontSize: 14,
                backgroundColor: Colors.good,
                opacity: value.length < 3 ? 0.3 : 1,
              }}
              onClick={() => {
                trigger();
              }}
            >
              +
            </button>
          </div>
        </ul>
      </div>
      <p
        style={{
          color: "white",
          marginRight: "auto",
          marginLeft: "auto",
          textAlign: "center",
          fontSize: 10,
        }}
      >
        {props.tags.length}/{props.limit || limit}
      </p>
    </React.Fragment>
  );
};
export default TagsInput;
