import React from "react";
import Colors from "../constants/Colors";
import Marquee from "react-fast-marquee";
import ModalView from "./ModalView";

import { IoMdClose } from "react-icons/io";

const Notice = (props) => {
  const [loading, setLoading] = React.useState(null);
  const [hideNotice, setHideNotice] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const [notice, setNotice] = React.useState({
    priority: 0,
    title: "",
    message: "",
    image_uri: "",
    link_uri: "",
    button_title: "",
  });

  React.useEffect(() => {
    fetchNotice();
    return () => {};
  });

  const fetchNotice = async () => {
    try {
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <React.Fragment>
      {!notice.title ? null : hideNotice ? (
        <div
          style={{
            width: "100%",
            backgroundColor:
              notice.priority === 2
                ? Colors.caution
                : notice.priority === 1
                ? Colors.unique
                : notice.priority === 3
                ? Colors.critical
                : Colors.primary,
            display: "flex",
            cursor: "pointer",
            padding: 5,
          }}
          onClick={() => setHideNotice(false)}
        ></div>
      ) : (
        <React.Fragment>
          <TheNotice
            showModal={showModal}
            setShowModal={setShowModal}
            loading={loading}
            notice={notice}
          />
          <div
            style={{
              width: "100%",
              backgroundColor:
                notice.priority === 2
                  ? Colors.caution
                  : notice.priority === 1
                  ? Colors.unique
                  : notice.priority === 3
                  ? Colors.critical
                  : Colors.primary,
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%" }} onClick={() => setShowModal(true)}>
              <Marquee
                pauseOnHover={true}
                gradientWidth={50}
                play={true}
                gradientColor={
                  notice.priority === 2
                    ? [233, 233, 36]
                    : notice.priority === 1
                    ? [203, 89, 252]
                    : notice.priority === 3
                    ? [233, 16, 16]
                    : [47, 62, 87]
                }
                speed={15}
                style={{
                  backgroundColor: "transparent",
                  flexDirection: "row",
                  width: "100%",
                  //   maxWidth: 1200,
                  //   marginRight: "auto",
                  //   marginLeft: "auto",
                  //   marginBottom: 20,
                  // height: 200
                }}
              >
                <p
                  style={{
                    color:
                      notice.priority === 2
                        ? Colors.primary
                        : notice.priority === 1
                        ? "white"
                        : notice.priority === 3
                        ? "white"
                        : "white",
                    margin: 0,
                    fontSize: 16,
                    fontWeight: "bolder",
                    marginRight: 10,
                    marginLeft: 30,
                  }}
                >
                  {`${notice.title}`}
                </p>
                <p
                  style={{
                    color:
                      notice.priority === 2
                        ? Colors.primary
                        : notice.priority === 1
                        ? "white"
                        : notice.priority === 3
                        ? "white"
                        : "white",
                    margin: 0,
                    fontSize: 16,
                    fontWeight: "bolder",
                    marginRight: 10,
                  }}
                >
                  ► ►
                </p>
                <p
                  style={{
                    color:
                      notice.priority === 2
                        ? Colors.primary
                        : notice.priority === 1
                        ? "white"
                        : notice.priority === 3
                        ? "white"
                        : "white",
                    margin: 0,
                    fontSize: 16,
                  }}
                >
                  {`${notice.message.replaceAll("/n/", "")}`}
                </p>
                <p
                  style={{
                    color:
                      notice.priority === 2
                        ? Colors.primary
                        : notice.priority === 1
                        ? "white"
                        : notice.priority === 3
                        ? "white"
                        : "white",
                    margin: 0,
                    fontSize: 16,
                    fontWeight: "bolder",
                    marginLeft: 30,
                  }}
                >
                  |
                </p>
              </Marquee>
            </div>
            <div onClick={() => setHideNotice(true)}>
              <IoMdClose
                color={
                  notice.priority === 2
                    ? Colors.primary
                    : notice.priority === 1
                    ? "white"
                    : notice.priority === 3
                    ? "white"
                    : "white"
                }
                style={{ fontSize: 30 }}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

/**
 *
      uniqueId - String
      visible - Boolean
      loading - Boolean
      disable - Boolean
      leftIcon - Boolean
      hideIcons - Boolean
      leftIconAction - Function
      close - Function
      children - html/jsx
 *
 */

const TheNotice = (props) => {
  return (
    <React.Fragment>
      <>
        <ModalView
          visible={props.showModal}
          close={() => props.setShowModal(false)}
          leftIcon={false}
          uniqueId={"main_notice"}
          style={{ maxWidth: 600 }}
        >
          <div
            style={{
              width: "100%",
              height: 10,
              borderRadius: 5,
              marginBottom: 20,
              backgroundColor: !props.notice
                ? "white"
                : props.notice.priority === 2
                ? Colors.caution
                : props.notice.priority === 1
                ? Colors.unique
                : props.notice.priority === 3
                ? Colors.critical
                : Colors.primary,
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
            }}
          ></div>
          <p
            style={{
              color: "white",
              margin: 0,
              fontSize: 20,
              fontWeight: "bolder",
              marginBottom: 20,
            }}
          >
            {props.notice ? props.notice.title : null}
          </p>

          <div
            style={{
              marginRight: "auto",
              marginLeft: "auto",
              minWidth: 250,
              maxWidth: 600,
              width: "100%",
              minHeight: 300,
              backgroundColor: Colors.primary,
              marginBottom: 20,
            }}
          >
            <img
              alt="notice"
              src={props.notice.image_uri}
              style={{ width: "100%" }}
            ></img>
          </div>

          {props.notice
            ? props.notice.message.split("/n/").map((msg, index) => (
                <React.Fragment key={index}>
                  <p style={{ color: "white", margin: 0, fontSize: 16 }}>
                    {msg}
                  </p>
                  <br />
                </React.Fragment>
              ))
            : null}

          <button
            className="universalBtn"
            style={{
              marginRight: "auto",
              marginLeft: "auto",
              backgroundColor: !props.notice
                ? "white"
                : props.notice.priority === 2
                ? Colors.caution
                : props.notice.priority === 1
                ? Colors.unique
                : props.notice.priority === 3
                ? Colors.critical
                : Colors.primary,
              color:
                props.notice.priority === 2
                  ? Colors.primary
                  : props.notice.priority === 1
                  ? "white"
                  : props.notice.priority === 3
                  ? "white"
                  : "white",
            }}
            onClick={() => {
              window.open("https://google.com", "_blank");
            }}
          >
            {props.notice ? props.notice.button_title : "Go"}
          </button>
        </ModalView>
      </>
    </React.Fragment>
  );
};

export default Notice;
