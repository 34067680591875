import React from "react";
import { HiHome } from "react-icons/hi";
import { useSelector } from "react-redux";

import { useHistory, useLocation } from "react-router-dom";

import Colors from "../constants/Colors";
import { FaBell, FaHeart, FaUser } from "react-icons/fa";
import { GoPlus } from "react-icons/go";

const style = {
  icons: {
    backgroundColor: "white",
    width: 50,
    height: 50,
    borderRadius: 100,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const StaticBar = (props) => {
  const history = useHistory();
  const location = useLocation();

  const online = useSelector((state) => state.auth.token);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const darkMode = useSelector((state) => state.display.darkMode);

  React.useEffect(() => {
    // console.log({ userInfo });
  }, []);

  return (
    <div
      id="footerBar_1"
      className="shadow"
      style={{
        zIndex: 61,
        backgroundColor: darkMode ? Colors.primary_darker : Colors.primary,
        position: "fixed",
        bottom: 0,
        marginBottom: 0,
        left: 0,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          paddingTop: 10,
          paddingBottom: 10,
          marginBottom: 0,
        }}
      >
        <div
          style={{ ...style.icons }}
          onClick={() => {
            history.push("/");
          }}
        >
          <HiHome
            style={{
              // marginBottom: 5,
              fontSize: 30,
              color: darkMode ? Colors.primary_darker : Colors.primary,
              cursor: "pointer",
            }}
            onClick={() => {
              console.log("FB");
            }}
          />
        </div>
        {online ? (
          <React.Fragment>
            <div
              style={{ ...style.icons }}
              onClick={() => {
                if (props.navRefFunc) {
                  props.navRefFunc.current.toggleAlertTab(true);
                }
              }}
            >
              <FaBell
                onClick={() => {}}
                style={{
                  color: darkMode ? Colors.primary_darker : Colors.primary,
                  margin: "auto",
                  fontSize: 25,
                  // backgroundColor: Colors.primary_darker,
                  // transform: "rotate(-90deg)",
                }}
              />
            </div>
            <div
              style={{
                ...style.icons,
                backgroundColor: darkMode
                  ? Colors.primary_darker
                  : Colors.primary,
                width: 50,
                height: 35,
                border: "1px solid white",
              }}
              onClick={() => {
                props.navRefFunc.current.openNewItem();
              }}
            >
              <GoPlus style={{ fontSize: 20, color: "white" }} />
            </div>
            <div
              style={{ ...style.icons }}
              onClick={() => {
                history.push("/likes");
              }}
            >
              <FaHeart
                onClick={() => {}}
                style={{
                  color: darkMode ? Colors.primary_darker : Colors.primary,
                  margin: "auto",
                  fontSize: 25,
                  // backgroundColor: Colors.primary_darker,
                  // transform: "rotate(-90deg)",
                }}
              />
            </div>
            <div
              style={{
                ...style.icons,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundImage: !userInfo
                  ? ``
                  : userInfo.profileImage
                  ? `${
                      userInfo.profileImage.local
                        ? `url(https://files.bidsquawk.com/file/bsq-public/profile/thumbnail/${userInfo.profileImage.local})`
                        : userInfo.profileImage.facebook
                        ? `url(${userInfo.profileImage.facebook})`
                        : userInfo.profileImage.google
                        ? `url(${userInfo.profileImage.google})`
                        : ``
                    }`
                  : ``,
              }}
              onClick={() => history.push("/user/" + userInfo.userName)}
            >
              {userInfo.profileImage ? null : (
                <FaUser
                  onClick={() => {}}
                  style={{
                    color: darkMode ? Colors.primary_darker : Colors.primary,
                    margin: "auto",
                    fontSize: 25,
                    // backgroundColor: Colors.primary_darker,
                    // transform: "rotate(-90deg)",
                  }}
                />
              )}
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default StaticBar;
