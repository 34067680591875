// Redux Scripts
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";

import authReducer from "../reducers/auth_reducer";
import locationsReducer from "../reducers/locations_reducer";
import categoriesReducer from "../reducers/categories_reducer";
import conditionsReducer from "../reducers/conditions_reducer";
import connectionReducer from "../reducers/connection_reducer";
import chatReducer from "../reducers/chat_reducer";
import limitReducer from "../reducers/limits_reducer";
import displayReducer from "../reducers/display_reducer";
import currencyReducer from "../reducers/currency_reducer";
import socketReducer from "../reducers/socket_reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  loc: locationsReducer,
  cats: categoriesReducer,
  cond: conditionsReducer,
  connection: connectionReducer,
  chat: chatReducer,
  limits: limitReducer,
  display: displayReducer,
  currency: currencyReducer,
  socket: socketReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

export default store;
