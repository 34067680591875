import React from "react";
import { FiCheck } from "react-icons/fi";
import { useSelector } from "react-redux";
import Colors from "../constants/Colors";

const CheckBox = (props) => {
  const darkMode = useSelector((state) => state.display.darkMode);
  return (
    <div
      className={"checkContainer"}
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        margin: 10,
        ...props.frameStyle,
      }}
      onClick={() => {
        props.setCheck();
      }}
    >
      <p
        style={{
          marginBottom: 0,
          marginRight: 10,
          ...props.textStyle,
          color: darkMode
            ? Colors.darModeText
            : props.color || Colors.lightModeText,
        }}
      >
        {props.message}
      </p>
      <input
        type="checkbox"
        alt="checkbox"
        style={{ width: 20 }}
        checked={props.check}
        onChange={(value) => {
          console.log({ value: value.target.value });
        }}
      />
      <span className="checkMarkBox" style={{ ...props.checkStyle }}>
        {props.check ? (
          <FiCheck
            color={"white"}
            style={{ fontSize: 18, ...props.iconStyle }}
          />
        ) : null}
      </span>
    </div>
  );
};

export default CheckBox;
