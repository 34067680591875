import firebase from "firebase/app";

import { callServer } from "../store/helpers/actionHelper";
import "firebase/messaging";

const FCM_SERVER_KEY =
  "BJvLZCuF4jW2QsPSP-HP7e-7R7v7hCEWLvzJwak4PmRbXEpjKKdAdMpqElD5chbf-wiPGQNp-SPFwzE__wbalI0";

var firebaseConfig = {
  apiKey: "AIzaSyBVWKRK-UZn3GwIOUYlgevKS-jX6iB1x0M",
  authDomain: "bidsquawk.firebaseapp.com",
  projectId: "bidsquawk",
  storageBucket: "bidsquawk.appspot.com",
  messagingSenderId: "140147000391",
  appId: "1:140147000391:web:74cd6366d74ef7a7929d62",
  measurementId: "G-VKYM953VL1",
};

const fireActive = firebase.initializeApp(firebaseConfig);
// const fireAnalytics = fireActive.analytics;
// const getPerformance = fireActive.performance;

// const messaging = firebase.messaging();

var firebase_core,
  supported,
  messaging,
  getToken = () => null,
  onMessageListener = () => null;

if (typeof window !== "undefined") {
  firebase_core = firebase;

  supported = firebase.messaging.isSupported();

  messaging = supported ? firebase.messaging() : null;

  getToken = async (setTokenFound) => {
    // console.log("FETCHING NEW TOKEN");
    const result = firebase.messaging.isSupported();
    // console.log({ supported: result });
    if (messaging) {
      // const check = localStorage.getItem("pushCheck", "pushCheck");
      // if (!check) {
      //   alert(
      //     "In order to receive updates and alerts in real time BidSquawk requires permission to send push notifications to this device/browser. To allow this please click 'Allow' on the next prompt."
      //   );
      //   localStorage.setItem("pushCheck", "pushCheck");
      // }
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            return messaging.getToken({
              vapidKey: FCM_SERVER_KEY,
            });
          } else {
            throw { notificationPermission: permission };
          }
        })
        .then(async (currentToken) => {
          if (currentToken) {
            // console.log({ currentToken });
            setTokenFound(currentToken);
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
            setTokenFound(false);
          }
        })
        .catch((err) => {
          setTokenFound(false);
          console.log("An error occurred while retrieving token. ", err);
        });
    } else {
      const check = localStorage.getItem("noPush");
      if (!check) {
        alert(
          "Please note push notifications (messages, alerts, updates, etc.) from BidSquawk are not supported for this device/browser.",
          result
        );
        localStorage.setItem("noPush", "noPush");
      }
      setTokenFound(false);
      console.log(
        firebase.messaging.isSupported(),
        "An error occurred while retrieving token. "
      );
    }
  };

  onMessageListener = () =>
    new Promise((resolve, reject) => {
      // console.log("RECEIVED!!");
      if (messaging) {
        messaging.onMessage((payload) => {
          resolve(payload);
        });
      } else {
        reject([
          firebase.messaging.isSupported(),
          "An error occurred while retrieving token. ",
        ]);
      }
    });
}

export {
  fireActive,
  firebase_core,
  // getPerformance,
  // fireAnalytics,
  supported,
  messaging,
  getToken,
  onMessageListener,
};
