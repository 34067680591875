/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  connection: null,
  status: false,
};

export default (state = initialState, action) => {
  // console.log(action.type, { action });
  switch (action.type) {
    case "CONNECT_SOCKET":
      return {
        connection: action.connection,
        status: true,
      };
    case "DISCONNECT_SOCKET":
      return {
        connection: initialState.socket,
        status: false,
      };
    default:
      return {
        ...state,
      };
  }
};
