/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import { deviceDetect } from "react-device-detect";

import Colors from "../../constants/Colors";

import Google_OAuth from "./Google_OAuth";
import Facebook_OAuth from "./Facebook_OAuth";
import Email_OAuth from "./Email_OAuth";
import { callServer, networkTest } from "../../store/helpers/actionHelper";

import { useDispatch, useSelector } from "react-redux";

import { getCurrentLocation } from "../../store/actions/location_action";

const Login = (props) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.display.darkMode);

  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [err, setErr] = React.useState("");
  const [passShow, setPassShow] = React.useState(true);

  const [socialToken, setSocialToken] = React.useState("");
  const [socialType, setSocialType] = React.useState("");
  const [socialVariables, setSocialVariables] = React.useState({
    socialType: "",
    socialToken: "",
  });

  // React.useEffect(() => {
  //   if (socialVariables.socialType) {
  //     login(true);
  //     alert("Social");
  //   }
  // }, [socialVariables]);

  const getIpAddress = async () => {
    try {
      const data = await fetch("https://api64.ipify.org?format=json");
      return data;
    } catch (err) {
      return null;
    }
  };

  const getLocation = async () => {
    try {
      const data = await getCurrentLocation();
      return data;
    } catch (err) {
      console.log({ err });
      return false;
    }
  };

  const login = async (social) => {
    let ipInfo = null;
    let geolocation = null;
    let clientData = null;

    try {
      const _timeout = new Promise((resolve, reject) => {
        setTimeout(resolve, 3000, null);
      });
      // console.log("LOGIN ATTEMPT");
      await networkTest();
      // console.log("Login action - 1");
      const raw_ipInfo = await Promise.race([_timeout, getIpAddress()]);
      // console.log("Login action - 2");
      if (raw_ipInfo && raw_ipInfo.ok) {
        try {
          ipInfo = await raw_ipInfo.json();
          // console.log("Login action - 3");
        } catch (error) {
          console.log({ error });
        }
      }
      // console.log("Login action - 4");
      geolocation = await Promise.race([_timeout, getLocation()]);

      // console.log("Login action - 5");
      clientData = await Promise.race([_timeout, deviceDetect()]);
      // console.log("Login action - 6");

      const deviceData = { ipInfo, geolocation, clientData };

      // dispatch({
      //   type: "LOGGED_IN",
      //   token: true,
      //   userInfo,
      // });
      // props.setShowAuth(false);
      props.setLoading(true);

      const theBody = social
        ? {
            socialToken: social.socialToken,
            fcmToken: props.fcmToken,
            // socketId,
            deviceData,
            socialType: social.socialType,
            email: social.email,
          }
        : {
            email,
            password,
            fcmToken: props.fcmToken,
            // socketId,
            deviceData,
          };

      // console.log({ deviceData, theBody, social });

      const resData = social
        ? await callServer({
            apiMethod: "post",
            endPoint: `server/auth/social/${social.social_type}-sign-in`,
            theBody,
          })
        : await callServer({
            apiMethod: "post",
            endPoint: "server/auth/sign-in",
            theBody,
          });

      await dispatch({
        type: "AUTH",
        valid: resData.valid,
        userId: resData.userId,
        tokenInfo: resData.tokenInfo,
        userInfo: resData.userInfo,
        token: resData.token,
      });

      props.setLoading(false);
      props.close();
    } catch (err) {
      props.setLoading(false);
      console.log({ err });
      let errMsg = err ? err.msg || err.message : null;

      if (errMsg) {
        if (errMsg.includes("Unexpected")) {
          errMsg = "Something went wrong, please try again later.";
        }
      } else {
        errMsg = "Something went wrong, please try again later.";
      }

      setErr(errMsg);
      // setErr(err.msg || err.message);
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          className={props.loading ? "App-logo" : ""}
          src={"/logo.svg"}
          // className="App-logo"
          alt="logo"
          style={{ height: 100, margin: 10 }}
        />
        {err ? (
          <p
            style={{
              color: Colors.err,
              width: "80%",
              textAlign: "center",
              borderRadius: 20,
              padding: 5,
              margin: 0,
            }}
          >
            {err}
          </p>
        ) : (
          <p> </p>
        )}
        <input
          className={"textInput"}
          style={{
            opacity: props.loading ? 0.3 : 1,
          }}
          disabled={props.loading}
          type="email"
          name="Email"
          placeholder={"Email"}
          maxLength="60"
          value={email}
          onChange={(value) => {
            if (props.loading) return;
            setErr("");
            setEmail(value.target.value);
          }}
        />
        <input
          className={"textInput"}
          style={{
            opacity: props.loading ? 0.3 : 1,
            marginBottom: 0,
          }}
          disabled={props.loading}
          type={passShow ? "password" : "text"}
          name="password"
          placeholder={"Password"}
          maxLength="60"
          value={password}
          onChange={(value) => {
            if (props.loading) return;
            setErr("");
            setPassword(value.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              login();
              // console.log("ENTER PRESSED");
            }
          }}
        />
        <div
          style={{
            padding: 10,
            cursor: "pointer",
            opacity: props.loading ? 0.3 : 1,
          }}
          onClick={() => {
            if (props.loading) return;
            setPassShow((state) => !state);
          }}
        >
          {passShow ? (
            <AiFillEyeInvisible color={"white"} style={{ fontSize: 25 }} />
          ) : (
            <AiFillEye color={"white"} style={{ fontSize: 25 }} />
          )}
        </div>
        <button
          className={
            props.loading || password.length < 5 || email.length < 5
              ? "universalBtnStatic"
              : "universalBtn"
          }
          style={{
            border: "none",
            backgroundColor: Colors.good,
            color: Colors.primary,
            fontWeight: "bold",
            alignSelf: "center",
            opacity:
              props.loading || password.length < 5 || email.length < 5
                ? 0.3
                : 1,
          }}
          onClick={() => {
            if (props.loading || password.length < 5 || email.length < 5)
              return;
            props.setLoading(true);
            setErr("");
            setPassShow(true);
            login();
          }}
          disabled={props.loading || password.length < 5 || email.length < 5}
        >
          Login
        </button>
        <button
          style={{
            border: "none",
            backgroundColor: "rgba(0, 0, 0, 0)",
            margin: 5,
            color: "white",
            fontWeight: "lighter",
            alignSelf: "center",
            opacity: props.loading ? 0.3 : 1,
            fontSize: 12,
          }}
          onClick={() => {
            if (props.loading) {
              return;
            }
            props.setShowAuth(3);
          }}
          disabled={props.loading}
        >
          Forgot your password?
        </button>

        <button
          style={{
            border: "none",
            backgroundColor: "rgba(0, 0, 0, 0)",
            marginTop: 10,
            color: "white",
            fontWeight: "normal",
            alignSelf: "center",
            opacity: props.loading ? 0.3 : 1,
            fontSize: 15,
          }}
          disabled={props.loading}
          onClick={() => {
            if (props.loading) {
              return;
            }

            props.setShowAuth(2);
          }}
        >
          Sign Up
        </button>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 5,
            marginTop: 20,
            paddingTop: 20,
            borderTop: "1px solid rgba(225, 225, 225, 0.1)",
          }}
        >
          <Google_OAuth
            fcmToken={props.fcmToken}
            loading={props.loading}
            setLoading={props.setLoading}
            btnText={"Login with Google"}
            successFunc={(a) => {
              login(a);
            }}
            errorFunc={() => {
              console.log("ERROR_GOOGLE");
              setErr("Unable to process at this time.");
            }}
            setSocialToken={setSocialToken}
            setSocialType={setSocialType}
            setSocialVariables={setSocialVariables}
          />

          <Facebook_OAuth
            fcmToken={props.fcmToken}
            loading={props.loading}
            setLoading={props.setLoading}
            btnText={"Login with Facebook"}
            successFunc={(a) => {
              login(a);
            }}
            errorFunc={() => {
              console.log("ERROR_FACEBOOK");
              setErr("Unable to process at this time.");
            }}
            setSocialToken={setSocialToken}
            setSocialType={setSocialType}
            setSocialVariables={setSocialVariables}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
