/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable default-case */

const defaultValue = () => {
  const isDarkMode = localStorage.getItem(DARKMODE);
  // console.log({ isDarkMode });
  if (isDarkMode) {
    return true;
  } else {
    return false;
  }
};

const DARKMODE = "DARKMODE";
const initialState = {
  darkMode: defaultValue(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "DARK":
      localStorage.setItem(DARKMODE, true);
      return {
        ...state,
        darkMode: true,
      };
    case "LIGHT":
      localStorage.removeItem(DARKMODE);
      return {
        ...state,
        darkMode: false,
      };
  }
  return state;
};
