/* eslint-disable no-throw-literal */

import { callServer } from "../helpers/actionHelper";

import { getDistance } from "geolib";

import store from "../helpers/reducer_index";

export const UPDATE_MY_LOCATION = "UPDATE_MY_LOCATION";
export const LAST_UPDATED = "LAST_UPDATED";
export const UPDATE_MY_STATIC_MAP = "UPDATE_MY_STATIC_MAP";

export const getCurrentLocation = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      if (navigator.geolocation) {
        const result = await navigator.permissions.query({
          name: "geolocation",
        });

        // console.log({ result });
        if (result.state === "granted") {
          //   console.log(result.state);
          //If granted then you can directly call your function here
          navigator.geolocation.getCurrentPosition((success) => {
            // console.log({ success });
            resolve({
              coords: {
                accuracy: success.coords.accuracy,
                altitude: success.coords.altitude,
                altitudeAccuracy: success.coords.altitudeAccuracy,
                heading: success.coords.heading,
                latitude: success.coords.latitude,
                longitude: success.coords.longitude,
                speed: success.coords.speed,
              },
              timestamp: success.timestamp,
            });
          });
        } else if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(
            (success, errors, options) => {
              if (errors) {
                throw errors;
              }
              console.log({ success });

              resolve({
                coords: {
                  accuracy: success.coords.accuracy,
                  altitude: success.coords.altitude,
                  altitudeAccuracy: success.coords.altitudeAccuracy,
                  heading: success.coords.heading,
                  latitude: success.coords.latitude,
                  longitude: success.coords.longitude,
                  speed: success.coords.speed,
                },
                timestamp: success.timestamp,
              });
            }
          );
        } else if (result.state === "denied") {
          console.log({ result });
          throw null;
          //If denied then you have to show instructions to enable location
        }
        result.onchange = function () {
          console.log(result.state);
        };
      } else {
        console.log("No navigator");
        throw null;
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const updateLocation = (token) => {
  return async (dispatch) => {
    try {
      const location = await getCurrentLocation();
      // console.log({ location, store: store.getState() });
      const userInfo = store.getState().auth.userInfo;
      if (userInfo) {
        const distance = getDistance(
          {
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
          },
          {
            latitude: userInfo.currentLocation.location.coordinates[1],
            longitude: userInfo.currentLocation.location.coordinates[0],
          }
        );

        // console.log({ distance });
        if (distance <= 100) {
          throw { msg: "You are within 100m of last updated location" };
        }
      }

      const resData = await callServer({
        theBody: {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        },
        token,
        apiMethod: "post",
        endPoint: "jobs/queue/updatelocation",
      });

      // console.log("Location update", { resData });

      dispatch({
        type: UPDATE_MY_LOCATION,
        myLocation: {
          lng: resData.success.location.coordinates[0],
          lat: resData.success.location.coordinates[1],
          address: resData.success.location.address,
          parish: resData.success.location.parish,
          lastUpdated: new Date(Date.now()),
        },
      });
    } catch (err) {
      throw err;
    }
  };
};
