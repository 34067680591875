import React from "react";

import Colors from "../../constants/Colors";

import { useHistory, useLocation } from "react-router-dom";
import {
  useIsMountedRef,
  useWindowSize,
  useRefDimensions,
  useDebounce,
} from "../../utils/customHooks";

import { AiOutlineCheckCircle } from "react-icons/ai";

import CurrencyInput from "react-currency-input-field";

import HoverComp from "../HoverComp";
import ModalView from "../ModalView";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";

import { callServer } from "../../store/helpers/actionHelper";
import { MdVerifiedUser } from "react-icons/md";
import { FaCheck, FaUser } from "react-icons/fa";
import { Star } from "../Rating";
import InfinityScroll from "../InfinityScroll";

/**
 *
 * Types
 *  1 - Message seller
 *  2 - Item Ref to seller
 *  3 - Item Ref to following
 *  4 - Interested
 *  5 - null
 *  6 - Seller Ref to following
 * 
      {online && showMessage ? (
        <ChatModal
          show={showMessage}
          setShow={setShowMessage}
          sellerId={null}
          sellerRefInfo={null}
          itemInfo={null}
          type={1}
        />
      ) : null}
 *
 */

const textLimit = 1200;

const ChatModal = (props) => {
  const ScrollRef = React.useRef();
  const location = useLocation();
  const isMountRef = useIsMountedRef();
  const history = useHistory();
  const [width, height] = useWindowSize();

  const userInfo = useSelector((state) => state.auth.userInfo);
  const online = useSelector((state) => state.auth.token);

  const [loading, setLoading] = React.useState(false);
  const [followingInfo, setFollowingInfo] = React.useState("");

  const [price, setPrice] = React.useState(0);
  const [priceErr, setPriceErr] = React.useState("");

  const [msg, setMsg] = React.useState("");
  const [sent, setSent] = React.useState(false);

  const [startDate, setStartDate] = React.useState(null);
  const [lastDate, setLastDate] = React.useState(null);

  const [loadFollow, setLoadFollow] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(false);

  const [follows, setFollows] = React.useState([]);
  const [typedName, setTypedName] = React.useState("");
  const debounce_typed_name = useDebounce(typedName, 500);

  const [selected, setSelected] = React.useState(null);
  const [refItemId, setRefItemId] = React.useState(null);
  const [refSellerId, setRefSellerId] = React.useState(null);

  React.useEffect(() => {
    console.log({
      userInfo,
      itemInfo: props.itemInfo,
      sellerInfo: props.sellerInfo,
    });
    if (props.itemInfo) {
      setPrice(props.itemInfo.price);
    }
  }, []);

  React.useEffect(() => {
    fetch();
  }, [debounce_typed_name]);

  // React.useEffect(() => {
  //   if (typedName.length > 0) {
  //     setLoading(true);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [typedName]);

  const sendMsg = async () => {
    setLoading(true);
    try {
      const resData = await callServer({
        apiMethod: "post",
        endPoint: `server/chat/send-msg`,
        theBody: {
          sellerId: selected
            ? selected._id
            : props.itemInfo
            ? props.itemInfo.userId
            : props.sellerInfo
            ? props.sellerInfo._id
            : null,
          message: msg ? msg : "",
          offer: price,
          refItemId: props.itemInfo ? props.itemInfo._id : null,
          refSellerId: props.sellerRefInfo ? props.sellerRefInfo._id : null,
          interested: [4, "4"].includes(props.type),
        },
      });
      console.log({ resData });
      if (resData.valid) {
        setSent(true);
        setTimeout(() => {
          props.setShow(!props.show);
        }, 2000);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log({ err });
    }
  };

  const validateValue = (value) => {
    const rawValue = value === undefined ? "undefined" : value;

    if (
      Number(rawValue.target.value.replace(/\$|,/g, "")).toString() === "NaN"
    ) {
      return;
    } else {
      setPrice(rawValue.target.value.replace(/\$|,/g, "") || " ");
    }
  };

  const fetch = async () => {
    if (loading) return;
    try {
      setLoading(true);
      setFollows([]);

      console.log({ typedName });

      const resData = await callServer({
        apiMethod: "post",
        endPoint: `server/seller/following/${userInfo.userName}`,
        theBody: {
          typedName,
          startDate: new Date(),
        },
      });
      console.log({ resData });
      setHasMore(resData.hasMore > 0 ? true : false);
      setLastDate(resData.lastDate);
      setStartDate(resData.StartDate);
      setFollows(resData.followers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  };

  const loadMore = async () => {
    if (loading) return;
    if (userInfo) {
      setLoading(true);
      try {
        const resData = await callServer({
          apiMethod: "post",
          endPoint: `server/seller/following/${userInfo.userName}`,
          theBody: {
            startDate: lastDate,
            typedName,
          },
        });

        console.log({ resData });
        setHasMore(resData.hasMore > 0 ? true : false);
        setLastDate(resData.lastDate);
        setStartDate(resData.StartDate);
        setFollows((state) => [...state, ...resData.followers]);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log({ err });
      }
    }
  };

  return (
    <React.Fragment>
      <ModalView
        uniqueId={"chat-modal"}
        visible={props.show}
        loading={false}
        close={() => props.setShow(!props.show)}
        style={{ maxWidth: 400 }}
        hideIcons={sent ? true : false}
        leftIcon={selected}
        leftIconAction={() => setSelected(null)}
      >
        {sent ? (
          <div
            style={{
              // background: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 style={{ textAlign: "center" }}>Sent</h1>
            <AiOutlineCheckCircle
              style={{
                color: Colors.good,
                fontSize: "80px",
                textAlign: "center",
              }}
            />
          </div>
        ) : (
          <React.Fragment>
            {[3, 6].includes(props.type) && !followingInfo ? (
              <React.Fragment>
                {selected ? (
                  <React.Fragment>
                    <h5 style={{ color: "white", textAlign: "center" }}>
                      {`Send this ${props.itemInfo ? "item" : "Profile"} - "${
                        props.sellerRefInfo
                          ? props.sellerRefInfo.userName
                          : props.itemInfo
                          ? props.itemInfo.title
                          : "..."
                      }" to`}
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        style={{
                          // flex: "auto",
                          display: "flex",
                          flexDirection: "row",
                          // flexWrap: "no-wrap",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: 10,
                          marginBottom: 20,
                        }}
                      >
                        <TheUser
                          x={selected}
                          setShowModal={props.setShowModal}
                          setShow={props.setShow}
                        />
                      </div>
                    </div>
                    <textarea
                      autoFocus={true}
                      className={"textInput"}
                      placeholder={`Type message here`}
                      style={{
                        textAlign: !msg || msg.length < 1 ? "center" : "left",
                        fontSize: 12,
                        padding: 5,
                        height: 80,
                        width: "100%",
                        margin: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      value={msg}
                      maxLength={textLimit}
                      onChange={(value) => {
                        setMsg(value.target.value);
                      }}
                      onKeyPress={(e) => {
                        console.log({ e, press: e.key });
                        if (e.key === "Enter") sendMsg();
                      }}
                    />
                    <div>
                      <p
                        style={{
                          color:
                            msg.length < 2 || msg.length > textLimit - 20
                              ? Colors.err
                              : "white",
                          display: "inline",
                        }}
                      >
                        {msg.length}
                      </p>
                      <p style={{ color: "white", display: "inline" }}>/1200</p>
                    </div>
                    <button
                      className={
                        loading ? "universalBtnStatic" : "universalBtn"
                      }
                      style={{
                        opacity: loading ? 0.3 : 1,
                        marginLeft: "auto",
                        marginRight: "auto",
                        backgroundColor: Colors.good,
                        transitionDuration: "800ms",
                      }}
                      onClick={() => {
                        if (loading) return;
                        sendMsg();
                      }}
                    >
                      Send
                    </button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <h5 style={{ color: "white", textAlign: "center" }}>
                      {`Send this ${props.itemInfo ? "item" : "Profile"} - "${
                        props.sellerRefInfo
                          ? props.sellerRefInfo.userName
                          : props.itemInfo
                          ? props.itemInfo.title
                          : "..."
                      }" to...`}
                    </h5>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <input
                        className={"textInput"}
                        style={{ marginLeft: "auto", marginRight: "auto" }}
                        placeholder={"Search your following"}
                        value={typedName}
                        onChange={(value) => {
                          setTypedName(value.target.value);
                        }}
                      />
                    </div>
                    <InfinityScroll
                      ref={ScrollRef}
                      list={follows}
                      hasMore={hasMore}
                      loadMore={loadMore}
                      loading={loading}
                      reverse={false}
                      style={{
                        height: height * 0.7,
                        backgroundColor: Colors.primary,
                        borderRadius: 10,
                        padding: 10,
                        // marginTop: 20,
                        marginBottom: 10,
                      }}
                      loadComp={() => (
                        <Loading
                          list={follows}
                          style={{ top: null, bottom: 5 }}
                        />
                      )}
                    >
                      <React.Fragment>
                        <>
                          {follows.map((x, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    // marginTop: 5,
                                    // marginBottom: 5,
                                    width: "98%",
                                    // height: 50,
                                    padding: 5,
                                    // backgroundColor: Colors.harmony_4,
                                    borderRadius: 5,
                                  }}
                                >
                                  <div
                                    style={{
                                      // flex: "auto",
                                      display: "flex",
                                      flexDirection: "row",
                                      // flexWrap: "no-wrap",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {/* TheUser */}

                                    <TheUser
                                      x={x}
                                      setShowModal={props.setShowModal}
                                      setShow={props.setShow}
                                    />
                                    {online && userInfo._id !== x._id ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <button
                                          className={
                                            "universalBtn" +
                                            (loadFollow ? " App-logo" : "")
                                          }
                                          style={{
                                            backgroundColor:
                                              Colors.primary_darker,
                                            color: "white",
                                            width: 100,
                                          }}
                                          onClick={() => {
                                            setSelected(x);
                                          }}
                                        >
                                          Select
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    borderBottom: "1px solid " + Colors.primary,
                                    margin: 10,
                                  }}
                                />
                              </React.Fragment>
                            );
                          })}
                        </>
                      </React.Fragment>
                    </InfinityScroll>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {props.itemInfo ? (
                  <p
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      textAlign: "center",
                      //   margin: 0,
                    }}
                  >
                    {props.itemInfo.neg ? "Price Negotiable" : "Price Firm"}
                  </p>
                ) : null}
                <h5 style={{ color: "white", textAlign: "center" }}>
                  {props.type === 1
                    ? `Message ${
                        props.sellerInfo ? props.sellerInfo.userName : "..."
                      }`
                    : props.type === 2
                    ? `Message ${
                        props.sellerRefInfo
                          ? `${props.sellerRefInfo.userName} about "${
                              props.itemInfo ? props.itemInfo.title : "..."
                            }"`
                          : "..."
                      }`
                    : props.type === 3
                    ? `Message ${
                        followingInfo
                          ? `${followingInfo.userName} about "${
                              props.itemInfo ? props.itemInfo.title : "..."
                            }"`
                          : "..."
                      }`
                    : props.type === 4
                    ? `You are interested in ${
                        props.itemInfo ? props.itemInfo.title : "..."
                      }`
                    : props.type === 5
                    ? ""
                    : props.type === 6
                    ? `Message ${
                        followingInfo
                          ? `${followingInfo.userName} about "${
                              props.sellerRefInfo
                                ? props.sellerRefInfo.userName
                                : "..."
                            }"`
                          : "..."
                      }`
                    : "..."}
                </h5>
                {props.itemInfo ? (
                  <React.Fragment>
                    {props.type === 2 ? (
                      <p
                        style={{
                          color: "white",
                          fontSize: 30,
                          fontWeight: "bolder",
                          textAlign: "center",
                        }}
                      >
                        {props.itemInfo.currency.toUpperCase()}{" "}
                        <NumberFormat
                          value={props.itemInfo.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </p>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 30,
                            fontWeight: "bold",
                            color: "white",
                            textAlign: "center",
                            margin: 0,
                            marginRight: 10,
                          }}
                        >
                          {props.itemInfo.currency.toUpperCase()}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "white",
                            // marginRight: "auto",
                            // marginLeft: "auto",
                            width: 250,
                            borderRadius: 10,
                            marginBottom: 15,
                          }}
                        >
                          <CurrencyInput
                            className={"textInput"}
                            id="price"
                            name="price"
                            placeholder="Price"
                            disabled={!props.itemInfo.neg}
                            value={price}
                            style={{
                              width: 250,
                              margin: 0,
                              paddingRight: 0,
                              fontSize: 30,
                              fontWeight: "bold",
                              color: Colors.primary,
                              textAlign: "center",
                              height: 50,
                              border: `5px solid ${
                                props.itemInfo.neg ? Colors.good : Colors.err
                              }`,
                            }}
                            // defaultValue={1}
                            prefix={"$"}
                            decimalsLimit={2}
                            onChange={validateValue}
                          />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ) : null}
                <React.Fragment>
                  <textarea
                    autoFocus={true}
                    className={"textInput"}
                    placeholder={`Type message here`}
                    style={{
                      textAlign: !msg || msg.length < 1 ? "center" : "left",
                      fontSize: 12,
                      padding: 5,
                      height: 80,
                      width: "100%",
                      margin: 0,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    value={msg}
                    maxLength={textLimit}
                    onChange={(value) => {
                      setMsg(value.target.value);
                    }}
                    onKeyPress={(e) => {
                      console.log({ e, press: e.key });
                      if (e.key === "Enter" && msg.length > 0) sendMsg();
                    }}
                  />
                  <div>
                    <p
                      style={{
                        color:
                          msg.length < 2 || msg.length > textLimit - 20
                            ? Colors.err
                            : "white",
                        display: "inline",
                      }}
                    >
                      {msg.length}
                    </p>
                    <p style={{ color: "white", display: "inline" }}>/1200</p>
                  </div>
                  <button
                    className={
                      loading || msg.length > 1
                        ? "universalBtn"
                        : "universalBtnStatic"
                    }
                    style={{
                      opacity: loading ? 0.3 : 1,
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor:
                        [4, "4"].includes(props.type) || msg.length > 1
                          ? Colors.good
                          : "lightgray",
                      transitionDuration: "800ms",
                      cursor:
                        [4, "4"].includes(props.type) || msg.length > 1
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() => {
                      if (loading) return;
                      if ([4, "4"].includes(props.type) || msg.length > 0)
                        sendMsg();
                    }}
                  >
                    Send
                  </button>
                </React.Fragment>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </ModalView>
    </React.Fragment>
  );
};

export const TheUser = (props) => {
  const history = useHistory();

  React.useEffect(() => {
    console.log({ x: props.x });
  }, []);
  return (
    <React.Fragment>
      <div
        style={{
          height: 60,
          minHeight: 60,
          width: 60,
          minWidth: 60,
          backgroundImage: props.x.picture
            ? `${
                props.x.picture.local
                  ? `url(https://files.bidsquawk.com/file/bsq-public/profile/thumbnail/${props.x.picture.local})`
                  : props.x.picture.facebook
                  ? `url(${props.x.picture.facebook})`
                  : props.x.picture.google
                  ? `url(${props.x.picture.google})`
                  : ""
              }`
            : "",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          borderRadius: 200,
          marginRight: 10,
          backgroundColor: "white",
          cursor: "pointer",
          position: "relative",
          border: "1px solid white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          history.push(`/user/${props.x.userName ? props.x.userName : "---"}`);
          if (props.setShowModal) {
            props.setShowModal(false);
          } else {
            props.setShow(!props.show);
          }
        }}
      >
        {props.x.picture ? null : (
          <FaUser
            onClick={() => {}}
            style={{
              color: Colors.primary_darker,
              margin: "auto",
              fontSize: "300%",
            }}
          />
        )}
      </div>
      <div
        style={{
          flex: "auto",
          cursor: props.displayOnly ? "default" : "pointer",
        }}
        onClick={() => {}}
      >
        <p
          style={{
            margin: 0,
            color: props.invert ? Colors.primary : "white",
            fontWeight: "bold",
          }}
        >
          {props.x.pro || props.x.proUser ? (
            <MdVerifiedUser
              style={{
                color: "#3FBF3F",
                fontSize: 18,
                // position: "absolute",
                left: 0,
                bottom: 0,
                marginRight: 5,
                display: "inline-block",
              }}
            />
          ) : props.x.verified ? (
            <FaCheck
              style={{
                color: "#3FBF3F",
                fontSize: 16,
                // position: "absolute",
                left: 0,
                bottom: 0,
                marginRight: 5,
                display: "inline-block",
              }}
            />
          ) : null}
          {`${
            props.x.userName
              ? props.x.userName.length > 25
                ? props.x.userName.slice(0, 25) + "..."
                : props.x.userName
              : "..."
          } - ${
            props.x.location ? props.x.location.value_0.toUpperCase() : "..."
          }`}
        </p>
        {props.x.proUser ? (
          props.x.proUser.company_name ? (
            <p
              style={{
                marginBottom: 3,
                fontSize: 12,
                color: props.invert ? Colors.primary : "white",
              }}
            >
              {props.x.proUser.company_name.length > 25
                ? props.x.proUser.company_name.slice(0, 25) + "..."
                : props.x.proUser.company_name}
            </p>
          ) : null
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Star width={70} value={props.x.rating ? props.x.rating : 0} />
          <p
            style={{
              margin: 0,
              fontSize: 12,
              marginLeft: 5,
              color: Colors.primary,
            }}
          >
            ({props.x.sold > -1 ? props.x.sold : "..."})
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export const Loading = (props) => (
  <div
    style={{
      position: props.list.length < 1 ? "relative" : "absolute",
      top: 5,
      justifySelf: "center",
      marginLeft: "auto",
      marginRight: "auto",
      zIndex: 30,
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "center",

      width: "100%",
      ...props.style,
    }}
  >
    <div
      style={{
        padding: 10,

        borderRadius: 20,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <img
        alt="logo"
        className={"App-logo"}
        src={"/logo.svg"}
        style={{ width: 50 }}
      />
    </div>
  </div>
);

export default ChatModal;
