/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable default-case */
const initialState = {
  conditions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_COND":
      return {
        conditions: action.conditions,
      };
  }
  return state;
};
