import React from "react";
import Colors from "../constants/Colors";
import {
  makeRandom,
  useWindowSize,
  useIsMountedRef,
} from "../utils/customHooks";

import { IoMdClose } from "react-icons/io";
import { BsChevronDown } from "react-icons/bs";

import "./List.css";
import { callServer } from "../store/helpers/actionHelper";
import { useSelector } from "react-redux";
import moment from "moment";

import Marquee from "react-fast-marquee";

/**
 *
 * width = int
 * value = {value: x, title: y}
 * setValue = func({value: x, title: y})
 * list = [{value: x, title: y},{value: x, title: y}...]
 *
 *
 */

const valueArray = ["all", null, undefined, "-", ""];

const CategoryList = (props) => {
  const random = makeRandom();
  const isMountRef = useIsMountedRef();

  const list = useSelector((state) => state.cats.categories);
  const online = useSelector((state) => state.auth.token);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [value, setValue] = React.useState(
    props.post
      ? {
        title: "Select a Category",
        value: { mainCat: "-" },
      }
      : {
        title: "All Categories",
        value: { mainCat: "all" },
      }
  );

  const finish = () => {
    if (!isMountRef.current) return;
    if (props.setValue && props.setCategoryReady && list.length > 0) {
      if (!props.value) {
        props.setValue(value);
      }
      props.setCategoryReady(true);
    }
  };

  React.useEffect(() => {
    finish();
  }, [list.length]);

  return (
    <React.Fragment>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          // id={"searchList"}
          style={{
            zIndex: 56,
            width: props.width || 100,
            height: 20,
            minHeight: 30,
            maxHeight: 20,
            padding: 5,
            backgroundColor: "white",
            borderRadius: 20,
            // margin: 5,
            cursor: "pointer",
            opacity: props.loading ? 0.8 : 1,
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: props.showList ? "flex-start" : "center",
            alignItems: "center",
            ...props.style,
          }}
          onClick={() => {
            if (props.loading || list.length === 0) {
              return;
            }
            props.setShowList();
          }}
        >
          {/* {props.showList ? (
            <IoMdClose
              color={"#ccc"}
              style={{
                // fontSize: 20,
                color: Colors.primary,
                // position: "absolute",
                marginLeft: 5,
              }}
            />
          ) : null} */}
          <div
            style={{
              textAlign: "center",
              margin: 0,
              padding: 0,
              overflow: "hidden",
              // width: props.showList ? "70%" : "100%",
              color: (
                props.value
                  ? props.value.value.mainCat === "-"
                  : value.value.mainCat === "-"
              )
                ? "rgba(0, 0, 0, 0.4)"
                : Colors.primary,
              width: "100%",
              // fontSize: 15,
              // marginLeft: props.showList ? 15 : 0,
              // fontWeight: "600",
            }}
          >
            {props.showList ? (
              <div
                style={{
                  width: 100,
                  backgroundColor: Colors.err,
                  borderRadius: 200,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <p
                  style={{
                    whiteSpace: "nowrap",
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    color: Colors.primary,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {"Close"}
                </p>
              </div>
            ) : (
              <React.Fragment>
                {valueArray.includes(
                  props.value ? props.value.value.mainCat : value.value.mainCat
                ) || !props.value ? (
                  <p
                    style={{
                      whiteSpace: "nowrap",
                      marginTop: 0,
                      marginBottom: 0,
                      marginLeft: 0,
                      color: Colors.primary,
                      // fontSize: 15,
                      marginRight: valueArray.includes(
                        props.value
                          ? props.value.value.mainCat
                          : value.value.mainCat
                      )
                        ? 0
                        : 10,
                    }}
                  >
                    {props.value
                      ? `${props.value.title}${props.value.value.subCat
                        ? props.value.value.subCat.title
                          ? ` - ${props.post &&
                            valueArray.includes(
                              props.value.value.subCat.value
                            )
                            ? "Please select a sub category"
                            : props.value.value.subCat.title
                          }`
                          : ""
                        : ""
                      }`
                      : value
                        ? `${value.title}${value.value.subCat
                          ? value.value.subCat.title
                            ? ` - ${props.post &&
                              valueArray.includes(value.value.subCat.value)
                              ? "Please select a sub category"
                              : value.value.subCat.title
                            }`
                            : ""
                          : ""
                        }`
                        : " - "}
                  </p>
                ) : (
                  <Marquee
                    pauseOnHover={true}
                    gradientWidth={20}
                    play={true}
                    // gradientColor={[100, 100, 100]}
                    speed={30}
                    style={{
                      backgroundColor: "transparent",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        margin: 0,
                        color: Colors.primary,
                        // fontSize: 15,
                        marginRight: 20,
                      }}
                    >
                      {props.value
                        ? `${props.value.title}${props.value.value.subCat
                          ? props.value.value.subCat.title
                            ? ` - ${props.post &&
                              valueArray.includes(
                                props.value.value.subCat.value
                              )
                              ? "Please select a sub category"
                              : props.value.value.subCat.title
                            }`
                            : ""
                          : ""
                        }`
                        : value
                          ? `${value.title}${value.value.subCat
                            ? value.value.subCat.title
                              ? ` - ${props.post &&
                                valueArray.includes(
                                  value.value.subCat.value
                                )
                                ? "Please select a sub category"
                                : value.value.subCat.title
                              }`
                              : ""
                            : ""
                          }`
                          : " - "}
                      {". "}
                    </p>
                  </Marquee>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        {list.length > 0 ? (
          <React.Fragment>
            {props.showList ? (
              <React.Fragment>
                <div
                  className={"shadow"}
                  style={{
                    position: "absolute",
                    display: "block",
                    zIndex: 60,
                    padding: 5,
                    backgroundColor: "white",
                    borderRadius: 10,
                    width: props.width || 100,
                    top: "100%",
                    marginTop: 1,
                    left: 0,
                    borderTop: "1px solid #ccc",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      margin: 0,
                      padding: 0,
                      overflow: "hidden",
                      color: Colors.primary,
                      borderBottom: "1px solid #ccc",
                      marginBottom: 5,
                    }}
                  >
                    {props.value
                      ? `${props.value.title}${props.value.value.subCat
                        ? props.value.value.subCat.title
                          ? ` - ${props.value.value.subCat.title}`
                          : ""
                        : ""
                      }`
                      : value
                        ? `${value.title}${value.value.subCat
                          ? value.value.subCat.title
                            ? ` - ${value.value.subCat.title}`
                            : ""
                          : ""
                        }`
                        : " - "}
                  </p>
                  <div
                    id={"sel"}
                    style={{
                      overflow: "scroll",
                      height: 210,
                      scrollbarWidth: "thin !important ",
                      overflowX: "hidden",
                    }}
                  >
                    {list.map((x, index) => {
                      if (
                        props.post &&
                        ["users", "all"].includes(x.value.mainCat)
                      ) {
                        return null;
                      } else if (
                        ["Adult 18+"].includes(x.value.mainCat) &&
                        (!online ||
                          (userInfo
                            ? !userInfo.mature
                              ? true
                              : moment().diff(userInfo.dob, "years") < 18
                            : true))
                      ) {
                        return null;
                      } else {
                        const key = `${x.value}-${x.title
                          }-${index}-${Date.now()}-${random}`;
                        //

                        return (
                          <React.Fragment key={index}>
                            <Category
                              post={props.post}
                              x={x}
                              setValue={props.setValue}
                              value={props.value || value}
                              setShowList={props.setShowList}
                            />
                          </React.Fragment>
                        );
                      }
                    })}
                  </div>
                  {props.setShowList ? (
                    <button
                      className={"universalBtn"}
                      style={{
                        width: 100,
                        backgroundColor: Colors.good,
                        borderWidth: 0,
                        borderRadius: 200,
                        marginLeft: "auto",
                        marginRight: "auto",
                        color: Colors.primary,
                        textAlign: "center",
                        fontWeight: "bold",
                        padding: 0,
                      }}
                      onClick={() => {
                        props.setShowList(false);
                      }}
                    >
                      Ok
                    </button>
                  ) : null}
                </div>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  );
};

const Category = (props) => {
  const isMountRef = useIsMountedRef();
  const [showSubList, setShowSubList] = React.useState(false);

  return (
    <React.Fragment>
      <div
        key={props.key}
        value={props.x.value}
        style={{
          cursor: "pointer",
          color:
            props.value.value.mainCat === props.x.value.mainCat
              ? "white"
              : Colors.primary,
          backgroundColor:
            props.value.value.mainCat === props.x.value.mainCat
              ? Colors.primary
              : "white",
          padding: 5,
          // fontSize: 15,
          borderBottom: ["pro", "all"].includes(props.x.value.mainCat)
            ? "1px solid #ccc"
            : "0px",
        }}
        onClick={() => {
          if (props.setValue) {
            if (!isMountRef.current) return;
            props.setValue({
              title: props.x.title,
              value: {
                mainCat: props.x.value.mainCat,
                subCat: { title: "All", value: "all" },
              },
            });
          }
        }}
      >
        <p
          style={{
            margin: 0,
            textAlign: "left",
          }}
        >
          {props.x.title}
        </p>
      </div>

      {props.x.value.subCat ? (
        <div style={{}}>
          {props.value.mainCat !== "all" &&
            props.value.value.mainCat === props.x.value.mainCat &&
            props.x.value.subCat.length > 0 ? (
            <div style={{ border: "1px solid " + Colors.primary }}>
              {props.value.value.mainCat === props.x.value.mainCat ? (
                <React.Fragment>
                  <div
                    style={{
                      // marginLeft: 10,
                      padding: 5,
                      backgroundColor: "white",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        color: Colors.primary,
                        textAlign: "center",
                      }}
                    >
                      {props.post && props.value.value.subCat.value === "all"
                        ? "Select a sub category"
                        : props.value.value.subCat.title}
                    </p>
                  </div>
                </React.Fragment>
              ) : null}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!isMountRef.current) return;
                  setShowSubList((state) => !state);
                }}
              >
                <p
                  style={{
                    margin: 0,
                    marginLeft: 5,
                  }}
                >
                  Sub Categories
                </p>
                <BsChevronDown
                  color={"#ccc"}
                  style={{
                    transform: showSubList
                      ? "rotateX(0deg)"
                      : "rotateX(180deg)",
                    transition: "200ms",
                    // fontSize: 15,
                    marginRight: 10,
                    color: Colors.primary,
                  }}
                />
              </div>
              {showSubList ? (
                <React.Fragment>
                  {props.x.value.subCat.map((subCat, index) => {
                    if (props.post && ["all"].includes(subCat.value)) {
                      return null;
                    }
                    return (
                      <div
                        key={index + subCat.value}
                        onClick={() => {
                          if (!isMountRef.current) return;
                          props.setValue({
                            title: props.x.title,
                            value: {
                              mainCat: props.x.value.mainCat,
                              subCat,
                            },
                          });
                          props.setShowList();
                        }}
                        style={{
                          // marginLeft: 10,
                          padding: 5,
                          backgroundColor:
                            props.value.value.subCat.value === subCat.value
                              ? Colors.primary
                              : "white",
                          cursor: "pointer",
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            color:
                              props.value.value.subCat.value === subCat.value
                                ? "white"
                                : Colors.primary,
                          }}
                        >
                          • {subCat.title}
                        </p>
                      </div>
                    );
                  })}
                </React.Fragment>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default CategoryList;
