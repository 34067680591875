/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

// import FacebookLogin from "react-facebook-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { FaFacebook } from "react-icons/fa";
import { callServer } from "../../store/helpers/actionHelper";
import Colors from "../../constants/Colors";
import { useIsMountedRef } from "../../utils/customHooks";

/**
 * 
 * 
 * PROPS
        style={{ height: 40 }}
        btnText={"Sign Up with Google"}
        successFunc={() => console.log("SUCCESS_GOOGLE")}
        errorFunc={() => console.log("ERROR_GOOGLE")}
 */

const Facebook_OAuth = (props) => {
  const isMountRef = useIsMountedRef();
  const [appId, setAppId] = React.useState("");

  React.useEffect(() => {
    fetchAppId();
    const ele = document.getElementById("facebook_btn");
    if (ele) {
      ele.addEventListener("click", () => {
        ele.style.transform = "scale(0.8)";
        setTimeout(() => {
          ele.style.transform = "scale(1)";
        }, 100);
      });
    }

    return () => {
      if (ele) {
        ele.removeEventListener("click", () => {});
      }
    };
  }, []);

  const fetchAppId = async () => {
    try {
      const resData = await callServer({
        apiMethod: "get",
        endPoint: "server/auth/social/fetch-id/facebook",
      });
      // console.log({ resData });
      if (resData.appId) {
        setAppId(resData.appId);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const responseFacebook = async (response) => {
    // console.log({ response });
    try {
      if (!isMountRef.current) return;
      if (props.setMainErr) {
        props.setMainErr("");
      }
      props.setLoading(true);
      if (response.status) {
        if (["not_authorized", "unknown"].includes(response.status)) {
          throw response.status;
        }
      }

      if (props.signUp) {
        await callServer({
          apiMethod: "post",
          endPoint: "server/auth/social/validate-email",
          theBody: {
            email: response.email,
          },
        });
      }

      if (props.setSocialType) {
        await props.setSocialType("facebook");
      }

      if (props.setSocialToken) {
        await props.setSocialToken(response.accessToken);
      }

      if (props.setSocialVariables) {
        props.setSocialVariables({
          socialType: "facebook",
          socialToken: response.accessToken,
          email: response.email,
        });
      }

      if (props.setSocialName) {
        await props.setSocialName(response.name.split(" ")[0]);
      }

      if (props.set_last_name) {
        await props.set_last_name(
          response.name.split(" ")[response.name.split(" ").length - 1]
        );
      }

      if (props.set_email) {
        props.set_email(response.email);
      }

      if (props.set_picture) {
        if (response.picture) {
          if (response.picture.data) {
            if (response.picture.data.url) {
              props.set_picture(response.picture.data.url);
            }
          }
        }
      }

      if (!isMountRef.current) return;

      const return_body = {
        socialType: "facebook",
        socialToken: response.accessToken,
        email: response.email,
        social_type: "facebook",
      };

      return props.successFunc(return_body);
    } catch (err) {
      if (!isMountRef.current) return;
      props.setLoading(false);
      if (err.msg) {
        if (props.setMainErr) {
          props.setMainErr(err.msg);
        }
      }
      if (props.errorFunc) {
        props.errorFunc();
      }
      console.log({ err });
    }
  };

  if (!appId) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <FacebookLogin
        appId={appId}
        scope={"public_profile, email, user_birthday"}
        fields={"name,email,picture"}
        // autoLoad={true}
        // fields="name,email,picture"
        isDisabled={props.loading || !appId}
        render={(renderProps) => (
          <button
            className="universalBtn"
            id={"facebook_btn"}
            disabled={props.loading || !appId}
            style={{
              opacity: props.loading || !appId ? 0.3 : 1,
              ...props.style,
            }}
            onClick={() => {
              props.setLoading(true);
              try {
                if (renderProps.onClick) renderProps.onClick();
              } catch (error) {
                console.log({ error });
              }
            }}
            // disabled={renderProps.disabled}
          >
            <FaFacebook
              style={{ marginRight: 10, fontSize: 20, color: "#4267B2" }}
            />
            {props.btnText ? props.btnText : "Use Facebook"}
          </button>
        )}
        onFailure={(failure) => {
          console.log({ failure });
        }}
        callback={responseFacebook}
      />
    </React.Fragment>
  );
};

export default Facebook_OAuth;
