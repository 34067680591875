import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../constants/Colors";
import { getCurrentLocation } from "../../store/actions/location_action";
import { useDebounce, useIsMountedRef } from "../../utils/customHooks";
import { isEmpty } from "../../utils/helper";

import List from "../List";
import { deviceDetect } from "react-device-detect";

import LocationSearch from "../LocationSearch";
import { useHistory } from "react-router-dom";
import { callServer } from "../../store/helpers/actionHelper";
import { FiCheck } from "react-icons/fi";

const month = [
  { value: 1, title: "January" },
  { value: 2, title: "February" },
  { value: 3, title: "March" },
  { value: 4, title: "April" },
  { value: 5, title: "May" },
  { value: 6, title: "June" },
  { value: 7, title: "July" },
  { value: 8, title: "August" },
  { value: 9, title: "September" },
  { value: 10, title: "October" },
  { value: 11, title: "November" },
  { value: 12, title: "December" },
];

const validator = /^([a-z0-9]|[-._]){3,60}$/;

const SocialAuthFinish = (props) => {
  const online = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const history = useHistory();

  const [referralCode, set_referralCode] = React.useState("");

  const [checked, setChecked] = React.useState(false);

  const [days, setDays] = React.useState([]);
  const [years, setYears] = React.useState([]);
  const [gender, set_gender] = React.useState("");

  const [dob_month, set_dob_month] = React.useState("");
  const [dob_day, set_dob_day] = React.useState("");
  const [dob_year, set_dob_year] = React.useState("");

  const [main_err, setMain_err] = useState("");
  const [gender_err, setGender_err] = useState(false);
  const [dobErr, setDobErr] = useState(false);
  const [location_err, setLocation_err] = useState(null);

  const [location, setLocation] = React.useState("");
  const [showLoc, setShowLoc] = React.useState(false);
  const [locationReady, setLocationReady] = React.useState(false);

  const [userName, setUserName] = React.useState("");
  const debounce_userName = useDebounce(userName, 1000);

  const [userNameGood, setUserNameGood] = React.useState(false);
  const [userName_err, setUserName_err] = useState("");

  const [expired, setExpired] = React.useState(false);

  React.useEffect(() => {
    props.setLoading(false);
    createDate();
    createYear();
    const timer = setTimeout(() => {
      setExpired(true);
    }, 180000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  React.useEffect(() => {
    if (online) {
      props.close();
    }
  }, [online]);

  React.useEffect(() => {
    if (userName.length > 0 && userName.length < 3) {
      validateUserName();
    } else if (userName.length > 0) {
      setUserName_err(`Checking if ${userName} is available...`);
      setUserNameGood(false);
    } else {
      setUserName_err(``);
      setUserNameGood(false);
    }
  }, [userName]);

  React.useEffect(() => {
    validateUserName();
  }, [debounce_userName]);

  const updateUserName = (value) => {
    setUserName_err("");

    setUserName(value.target.value.toLowerCase());
  };

  const validateUserName = async () => {
    if (userName.length < 3) return;

    if (!validator.test(userName)) {
      setUserName_err("Invalid character used.");
      setUserNameGood(false);
      return;
    }

    try {
      const resData = await callServer({
        apiMethod: "post",
        endPoint: "server/auth/username-check",
        theBody: {
          userName,
        },
      });

      if (resData.validUserName) {
        setUserName_err("");
        setUserNameGood(true);
      } else {
        setUserName_err(`${userName} is not available.`);
        setUserNameGood(false);
      }
    } catch (err) {
      setUserNameGood(false);
      console.log({ err });
    }
  };

  const changeLocation = (value) => {
    setLocation(value);
    setLocation_err("");
  };

  const createDate = () => {
    // setDays([]);
    let tempt = [];
    for (let i = 0; i < 31; ) {
      i++;
      tempt.push({ value: i, title: i });
      if (i >= 31) {
        setDays(tempt);
        // console.log({ days: tempt });
      }
    }
  };

  const createYear = () => {
    // setYears([]);
    let tempt = [];
    const currentYear = new Date().getFullYear();
    // console.log({ currentYear });
    let startYear = currentYear - 90;
    for (let i = startYear; i < currentYear; ) {
      i++;
      tempt.push({ value: i, title: i });
      if (i >= currentYear) {
        setYears(tempt.reverse());
        // console.log({ years: tempt });
      }
    }
  };

  const getLocation = async () => {
    try {
      const data = await getCurrentLocation();
      return data;
    } catch (err) {
      console.log({ err });
      return false;
    }
  };

  const getIpAddress = async () => {
    try {
      const data = await fetch("https://api64.ipify.org?format=json");
      return data;
    } catch (err) {
      return null;
    }
  };

  const validateInfo = async () => {
    setMain_err("");
    props.setLoading(true);
    // console.log("HIT!");

    let ok = true;
    try {
      if (
        !userName ||
        userName.length < 3 ||
        !validator.test(userName) ||
        !userNameGood
      ) {
        if (!userName_err) {
          setUserName_err("You need to enter a valid username.");
        }
        ok = false;
      }
      if (!dob_year || !dob_day || !dob_month) {
        setDobErr("Your Date Of Birth is required.");
        ok = false;
      }
      const genderVal = await isEmpty(gender);
      if (!genderVal) {
        setGender_err("Please select a gender.");
        ok = false;
      }

      let locVal = true;
      if (!location) {
        locVal = false;
        setLocation_err("Please select your general location.");
        ok = false;
      }

      if (
        gender &&
        locVal &&
        userName &&
        ok &&
        dob_year &&
        dob_day &&
        dob_month
      ) {
        return sendData();
      }
      props.setLoading(false);
    } catch (err) {
      console.log({ err });
      setMain_err(err);
      props.setLoading(false);
    }
  };

  const sendData = async () => {
    let ipInfo = null;
    let geolocation = null;
    let clientData = null;

    const raw_ipInfo = await getIpAddress();
    if (raw_ipInfo) {
      if (raw_ipInfo.ok) {
        ipInfo = await raw_ipInfo.json();
      }
    }
    geolocation = await getLocation();

    clientData = await deviceDetect();

    const deviceData = { ipInfo, geolocation, clientData };

    const newLocation = {
      title: location.title,
      value_0: location.value_0,
      value_1: location.value_1,
    };

    if (location.value_2) {
      newLocation.value_2 = location.value_2.value_2;
      if (location.value_2.value_3) {
        newLocation.value_3 = location.value_2.value_3.value_3;
      }
    }

    try {
      const data = {
        referralCode,
        userName,
        day: dob_day.value,
        month: dob_month.value,
        year: dob_year.value,
        gender,
        location: newLocation,
        socialToken: props.socialToken,
        socialType: props.socialType,
        fcmToken: props.fcmToken,
        deviceData,
        email: props.email,
        last_name: props.last_name,
        first_name: props.socialName,
        picture: props.picture,
      };
      // console.log({ data });

      const resData = await callServer({
        endPoint: `server/auth/social/${props.socialType}-sign-up`,
        apiMethod: "POST",
        theBody: data,
      });

      // console.log({ resData });
      props.setLoading(false);
      theDispatch(resData);
    } catch (err) {
      props.setLoading(false);
      console.log({ err });
    }
  };

  const theDispatch = async (data) => {
    try {
      // const newData = await getMyInfo();

      // console.log({ dispatchData: data });

      props.setLoading(false);
      props.setDisable(false);

      await dispatch({
        type: "AUTH",
        valid: data.valid,
        userId: data.userId,
        tokenInfo: data.tokenInfo,
        userInfo: data.userInfo,
        token: data.token,
      });
      // props.close();
    } catch (err) {
      setMain_err(err.msg);
      console.log({ signup: err });
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: "100%",
        }}
      >
        {expired && !props.loading ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: Colors.transparent_high,
              zIndex: 60,
              borderRadius: 5,
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3>Session Expired</h3>
            <button
              className="universalBtn"
              style={{ margin: 10, color: Colors.primary, fontWeight: "bold" }}
              onClick={() => {
                if (props.setShowAuth) {
                  props.setShowAuth(2);
                }
              }}
            >
              Go Back
            </button>
          </div>
        ) : null}
        <img src={"/logo.svg"} alt="logo" style={{ height: 100, margin: 10 }} />

        <h2 style={{ marginBottom: 15 }}>
          Welcome {props.socialName ? props.socialName : "USERNAME"}{" "}
        </h2>

        {main_err ? (
          <p style={{ marginBottom: 15, color: Colors.err }}>{main_err}</p>
        ) : null}

        <input
          className={"textInput"}
          style={{
            marginBottom: 0,
            opacity: props.loading ? 0.3 : 1,
            backgroundColor: Colors.primary,
            color: "white",
          }}
          disabled={props.loading}
          type="text"
          name="firstName"
          placeholder={"Referral Code"}
          maxLength="60"
          max="60"
          value={referralCode.toUpperCase()}
          onChange={(value) => {
            set_referralCode(value.target.value);
          }}
        />
        <p style={{ color: "#ccc", fontSize: 12, marginBottom: 10 }}>
          Optional
        </p>
        {userName_err ? (
          <p style={{ ...styles.errMsg }}>{userName_err}</p>
        ) : userName.length >= 3 ? (
          <p
            style={{ ...styles.errMsg, color: Colors.good }}
          >{`${userName} is available.`}</p>
        ) : null}
        <input
          className={"textInput"}
          style={{
            opacity: props.loading ? 0.3 : 1,
          }}
          disabled={props.loading}
          type="text"
          name="userName"
          placeholder={"Username"}
          maxLength="60"
          max="60"
          value={userName}
          onChange={updateUserName}
          // onChange={(value) => {
          //   setUserName_err("");
          //   if (validator.test(value.target.value)) {
          //     setUserName(value.target.value);
          //   }
          // }}
        />
        {location_err ? (
          <p style={{ ...styles.errMsg }}>{location_err}</p>
        ) : null}

        <div
          style={{
            position: "relative",
            marginBottom: 15,
            cursor: "pointer",
          }}
        >
          <input
            className={"textInput"}
            placeholder={"Location"}
            value={""}
            style={{ margin: 0 }}
            onChange={(value) => {
              return;
            }}
          ></input>
          <div
            onClick={() => setShowLoc((state) => !state)}
            style={{
              top: 0,
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: location || showLoc ? "white" : "transparent",
              borderRadius: 5,
              opacity: location || showLoc ? 1 : 0,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {location && !showLoc ? (
              <p style={{ fontSize: 12, margin: 0, textAlign: "center" }}>
                {location.value_0.toUpperCase()} - {location.title}
                {location.value_2 ? (
                  <React.Fragment>{"..."}</React.Fragment>
                ) : null}
              </p>
            ) : (
              <React.Fragment>
                <div
                  style={{
                    width: 100,
                    backgroundColor: Colors.err,
                    borderRadius: 200,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <p
                    style={{
                      whiteSpace: "nowrap",
                      marginTop: 0,
                      marginBottom: 0,
                      marginLeft: 0,
                      color: Colors.primary,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {"Close"}
                  </p>
                </div>
              </React.Fragment>
            )}
          </div>
          <LocationSearch
            visible={showLoc}
            setVisible={setShowLoc}
            width={200}
            location={location}
            setLocationReady={(value) => setLocationReady(value)}
            changeLocation={(value) => changeLocation(value)}
            fixed={true}
            notSearch={true}
            styles={{ left: 0 }}
          />
        </div>
        <p style={{ color: "white", marginBottom: 0, marginTop: 0 }}>Gender</p>
        {gender_err ? <p style={{ ...styles.errMsg }}>{gender_err}</p> : null}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: 150,
            marginTop: 0,
            opacity: props.loading ? 0.5 : 1,
          }}
        >
          <div className={"radioContainer"}>
            <label style={{ color: "white" }} htmlFor="male">
              Male
            </label>
            <span
              className="checkmark"
              style={{
                backgroundColor: gender === "m" ? Colors.primary : "white",
              }}
              onClick={() => {
                if (props.loading) {
                  return;
                }
                set_gender("m");
              }}
            >
              <input
                type="radio"
                id="male"
                name="gender"
                value="m"
                checked={gender === "m" ? true : false}
                onChange={(value) => {
                  setGender_err("");
                  set_gender(value.target.value);
                }}
              />
            </span>
          </div>
          <div className={"radioContainer"}>
            <label style={{ color: "white" }} htmlFor="female">
              Female
            </label>
            <span
              className="checkmark"
              style={{
                backgroundColor: gender === "f" ? Colors.primary : "white",
              }}
              onClick={() => {
                if (props.loading) {
                  return;
                }
                set_gender("f");
              }}
            >
              <input
                type="radio"
                id="female"
                name="gender"
                value="f"
                checked={gender === "f" ? true : false}
                onChange={(value) => {
                  setGender_err("");
                  set_gender(value.target.value);
                }}
              />
            </span>
          </div>
        </div>
        <p style={{ marginTop: 10, color: "white", marginBottom: 0 }}>
          Date of Birth
        </p>
        {dobErr ? <p style={{ ...styles.errMsg }}>{dobErr}</p> : null}

        <div
          style={{
            opacity: props.loading ? 0.5 : 1,
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginBottom: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ color: "white", marginBottom: 0 }}>Day</p>
            <List
              style={{ marginRight: 5, marginLeft: 5 }}
              width={60}
              list={days}
              setValue={(value) => {
                set_dob_day(value);
                setDobErr("");
              }}
              value={dob_day}
              loading={props.loading}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ color: "white", marginBottom: 0 }}>Month</p>
            <List
              style={{ marginRight: 5, marginLeft: 5 }}
              list={month}
              setValue={(value) => {
                set_dob_month(value);
                setDobErr("");
              }}
              value={dob_month}
              loading={props.loading}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ color: "white", marginBottom: 0 }}>Year</p>
            <List
              style={{ marginRight: 5, marginLeft: 5 }}
              width={60}
              list={years}
              setValue={(value) => {
                setDobErr("");
                set_dob_year(value);
              }}
              value={dob_year}
              loading={props.loading}
            />
          </div>
        </div>

        <div
          className={"checkContainer"}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            margin: 10,
            cursor: props.loading ? "inherit" : "pointer",
            opacity: props.loading ? 0.3 : 1,
          }}
          onClick={() => {
            if (props.loading) {
              return;
            }
            setChecked((state) => !state);
          }}
        >
          <input
            type="checkbox"
            style={{ width: 20 }}
            checked={checked}
            onChange={(value) => {
              // console.log({ value: value.target.value });
            }}
          />
          <span className="checkMarkBox">
            {checked ? (
              <FiCheck color={"white"} style={{ fontSize: 18 }} />
            ) : null}
          </span>
          <p
            style={{
              marginBottom: 0,
              textAlign: "center",
              color: "white",
            }}
          >
            I agree to the{" "}
            <a
              href={"/legal.html"}
              style={{ color: "white", fontWeight: "bold" }}
              target={"_blank"}
            >
              terms and conditions
            </a>
          </p>
        </div>

        <button
          className={checked ? "universalBtn" : "universalBtnStatic"}
          style={{
            backgroundColor: Colors.good,
            cursor: props.loading ? "inherit" : "pointer",
            opacity: props.loading || !checked ? 0.3 : 1,
          }}
          onClick={() => {
            if (props.loading || !checked) {
              return;
            }
            props.setLoading(true);
            validateInfo();
          }}
        >
          {props.userType === "pro" ? " Continue" : "Finish"}
        </button>
      </div>
    </React.Fragment>
  );
};

const styles = {
  errMsg: {
    color: Colors.err,
    margin: 0,
    textAlign: "center",
  },
};

export default SocialAuthFinish;
