/**
 *
      uniqueId - String
      visible - Boolean
      loading - Boolean
      disable - Boolean
      leftIcon - Boolean
      hideIcons - Boolean
      leftIconAction - Function
      close - Function
      children - html/jsx
 *
 */

import React from "react";

import { IoMdClose } from "react-icons/io";
import { BsChevronLeft } from "react-icons/bs";

import Colors from "../constants/Colors";
import { useSelector } from "react-redux";

const ModalView = (props) => {
  const darkMode = useSelector((state) => state.display.darkMode);
  const idTag = `${Date.now()}-${props.uniqueId}`;
  const fadeIn = () => {
    const modalTemp = document.getElementById(idTag);

    setTimeout(() => {
      modalTemp.style.opacity = 1;
    }, 0);
  };

  React.useEffect(() => {
    if (props.visible) {
      fadeIn();
    }
  }, [props.visible]);

  return (
    <React.Fragment>
      {props.visible && props.uniqueId ? (
        <div
          className="pu_modal"
          id={idTag}
          style={{ backgroundColor: Colors.transparent_high, zIndex: 550 }}
        >
          <div
            className={"pu_modal_content"}
            style={{
              maxWidth: 1200,
              paddingBottom: 20,
              backgroundColor: darkMode
                ? Colors.primary_darker
                : Colors.primary,
              marginTop: 80,
              width: "100%",
              position: "relative",
              // overflow: "hidden",
              ...props.style,
            }}
          >
            {props.hideIcons ? null : (
              <div
                className="pu_modal_close"
                style={{
                  justifyContent: props.leftIcon ? "space-between" : "flex-end",
                  marginBottom: 10,
                }}
              >
                {props.leftIcon ? (
                  <div
                    style={{
                      cursor: props.disable ? "default" : "pointer",
                      opacity: props.loading || props.disable ? 0.1 : 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (props.loading || props.disable) {
                        return;
                      }
                      if (props.leftIconAction) {
                        props.leftIconAction();
                      }
                    }}
                  >
                    <BsChevronLeft
                      color={"white"}
                      style={{ fontSize: 30, fontWeight: "600" }}
                    />
                    {/* <p
                    style={{
                      color: "white",
                      margin: 0,
                      fontSize: 15,
                    }}
                  >
                    Back
                  </p> */}
                  </div>
                ) : null}
                <div
                  style={{
                    cursor: props.disable ? "default" : "pointer",
                    opacity: props.loading || props.disable ? 0.1 : 1,
                  }}
                  onClick={() => {
                    if (props.loading || props.disable) {
                      return;
                    }

                    if (props.close) {
                      const modalTemp = document.getElementById(idTag);
                      modalTemp.style.opacity = 0;
                      setTimeout(() => {
                        props.close(false);
                      }, 500);
                    }
                  }}
                >
                  <IoMdClose color={"white"} style={{ fontSize: 30 }} />
                </div>
              </div>
            )}
            {props.visible ? props.children : null}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default ModalView;
