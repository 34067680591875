/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import Colors from "../../constants/Colors";

import Google_OAuth from "./Google_OAuth";
import Facebook_OAuth from "./Facebook_OAuth";
import Email_OAuth from "./Email_OAuth";
import SignUpEmail from "./SignUpEmail";

import { Pro, Reg } from "./RegisterTypes";
import SocialAuthFinish from "./SocialAuthFinish";
import { useStore } from "react-redux";

const SignUp = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [userType, setUserType] = React.useState("reg");
  const [social, setSocial] = React.useState(false);

  const [userData, setUserData] = React.useState("");

  const [socialToken, setSocialToken] = React.useState("");
  const [socialName, setSocialName] = React.useState("");
  const [socialType, setSocialType] = React.useState("");

  const [first_name, set_first_name] = React.useState("");
  const [last_name, set_last_name] = React.useState("");
  const [email, set_email] = React.useState("");
  const [picture, set_picture] = React.useState("");

  const [mainErr, setMainErr] = React.useState(" ");

  React.useEffect(() => {
    props.setShowBack(true);
    return () => props.setShowBack(false);
  });

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.showAuth === 2 ? (
          <React.Fragment>
            <img
              src={"/logo.svg"}
              alt="logo"
              style={{ height: 100, margin: 10 }}
            />

            <p style={{ textAlign: "center", color: Colors.err, margin: 0 }}>
              {mainErr}
            </p>

            {userType ? (
              <React.Fragment>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 5,
                    marginTop: 20,
                    paddingBottom: 20,
                    borderBottom: "1px solid white",
                  }}
                >
                  <Email_OAuth
                    setMainErr={setMainErr}
                    fcmToken={props.fcmToken}
                    showAuth={props.showAuth}
                    loading={props.loading}
                    setLoading={props.setLoading}
                    setShowAuth={props.setShowAuth}
                    action={() => props.setShowAuth(2.1)}
                  />
                  <Google_OAuth
                    signUp={true}
                    setMainErr={setMainErr}
                    fcmToken={props.fcmToken}
                    loading={props.loading}
                    setLoading={props.setLoading}
                    btnText={"Sign up with Google"}
                    setSocialToken={setSocialToken}
                    setSocialName={setSocialName}
                    setSocialType={setSocialType}
                    userData={userData}
                    setUserData={setUserData}
                    successFunc={() => {
                      setSocial(true);
                      // props.setDisable(true);
                      props.setShowAuth(2.2);
                      // console.log("SUCCESS_GOOGLE");
                    }}
                    errorFunc={() => console.log("ERROR_GOOGLE")}
                  />

                  <Facebook_OAuth
                    signUp={true}
                    setMainErr={setMainErr}
                    fcmToken={props.fcmToken}
                    loading={props.loading}
                    setLoading={props.setLoading}
                    btnText={"Sign up with Facebook"}
                    setSocialToken={setSocialToken}
                    setSocialName={setSocialName}
                    setSocialType={setSocialType}
                    userData={userData}
                    setUserData={setUserData}
                    successFunc={() => {
                      setSocial(true);
                      // props.setDisable(true);
                      props.setShowAuth(2.2);
                      // console.log("SUCCESS_FACEBOOK");
                    }}
                    set_first_name={set_first_name}
                    set_last_name={set_last_name}
                    set_email={set_email}
                    set_picture={set_picture}
                    errorFunc={() => console.log("ERROR_FACEBOOK")}
                  />
                </div>
                <button
                  style={{
                    border: "none",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    marginTop: 10,
                    color: "white",
                    fontWeight: "normal",
                    alignSelf: "center",
                    opacity: props.loading ? 0.3 : 1,
                    fontSize: 15,
                  }}
                  disabled={props.loading}
                  onClick={() => {
                    if (props.loading) {
                      return;
                    }

                    props.setShowAuth(1);
                  }}
                >
                  Login
                </button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "sans-serif",
                  }}
                >
                  <Reg setUserType={setUserType} />
                  <Pro setUserType={setUserType} />
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : props.showAuth === 2.1 ? (
          <SignUpEmail
            fcmToken={props.fcmToken}
            userType={userType}
            setShowAuth={props.setShowAuth}
            setUserType={setUserType}
            setShowBack={props.setShowBack}
            setDisable={props.setDisable}
            loading={props.loading}
            setLoading={props.setLoading}
            close={props.close}
          />
        ) : props.showAuth === 2.2 ? (
          <SocialAuthFinish
            userData={userData}
            socialToken={socialToken}
            socialType={socialType}
            socialName={socialName}
            setUserData={setUserData}
            fcmToken={props.fcmToken}
            userType={userType}
            setShowAuth={props.setShowAuth}
            setUserType={setUserType}
            setShowBack={props.setShowBack}
            setDisable={props.setDisable}
            loading={props.loading}
            goBack={() => props.setShowAuth(2)}
            setLoading={props.setLoading}
            close={props.close}
            first_name={first_name}
            last_name={last_name}
            email={email}
            picture={picture}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default SignUp;
