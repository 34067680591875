// import store from "../reducers/reducer_index";
// import regeneratorRuntime from "regenerator-runtime";

import { SERVER_URL, TIMEOUT } from "../../config/config";
import store from "../../store/helpers/reducer_index";

const msg =
  "Unable to contact server, please check your internet connection or try agin later.";

export const FORCE_LOGOUT = "FORCE_LOGOUT";

export const networkTest = async () => {
  // console.log("SERVER PING");
  const timeout = new Promise((resolve, reject) => {
    setTimeout(reject, TIMEOUT, "Request timed out");
  });
  const appData = {
    AppVersion: null,
    NativeVersion: null,
    SDK: null,
    mobile: false,
  };

  const request = fetch(`${SERVER_URL}/server/ping`, {
    method: "GET",
    headers: {
      "X-Requested-With": " XMLHttpRequest",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      // ...appData,
    },
    credentials: "include",
  });

  return Promise.race([timeout, request])
    .then((response) => {
      store.dispatch({ type: "UPDATE_CONNECTION", live: true });
      // console.log("PING RESPONSE", { response });

      return true;
    })
    .catch((error) => {
      store.dispatch({ type: "UPDATE_CONNECTION", live: false });
      console.log("networkTest", { error });
      return false;
    });
};

const force = async () => {
  console.log("FOCRED");
  store.dispatch({ type: "UPDATE_CONNECTION", live: false });
  // store.dispatch({ type: FORCE_LOGOUT, valid, msg });
};

export const callServer = async ({
  theBody,
  apiMethod,
  endPoint,
  form,
  token,
  noToken,
  signal,
}) => {
  try {
    // console.log("start");
    const ping = await networkTest();
    // console.log("ping");

    if (!ping) {
      force();
      // console.log("fail");

      throw { msg };
    }
    // console.log("good ping");

    store.dispatch({ type: "UPDATE_CONNECTION", live: true });

    let the_token;
    if (!noToken) {
      if (token) {
        the_token = token;
      } else {
        const tokenAsync = await localStorage.getItem("jwt");
        if (tokenAsync) {
          the_token = await JSON.parse(tokenAsync);
        } else {
          the_token = null;
        }
      }
    } else {
      the_token = "";
    }

    // console.log({ the_token });

    let contentType = "application/json";
    // if (form) {
    //   contentType = "multipart/form-data";
    // }

    // App version
    const appData = {
      AppVersion: null,
      NativeVersion: null,
      SDK: null,
      mobile: false,
    };

    // fetch/post data
    let options = { timeout: 1500 };
    if (apiMethod.toUpperCase() === "POST" && !form) {
      options = {
        method: apiMethod.toUpperCase(),
        headers: {
          Authorization: `Bearer ${the_token}`,
          ...appData,
        },

        body: JSON.stringify(theBody),
      };
    } else if (apiMethod.toUpperCase() === "POST" && form) {
      options = {
        method: apiMethod.toUpperCase(),
        headers: {
          Authorization: `Bearer ${the_token}`,
          ...appData,
        },
        body: theBody,
      };
    } else if (apiMethod.toUpperCase() === "GET") {
      options = {
        method: apiMethod.toUpperCase(),
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${the_token}`,
          ...appData,
        },
      };
    }

    options.credentials = "include";
    // options.headers["Access-Control-Allow-Origin"] = SERVER_URL;
    options["X-Requested-With"] = " XMLHttpRequest";
    options.headers.accept = "application/json";

    if (!form) {
      options.headers["Content-Type"] = contentType;
    }

    if (signal) {
      options.signal = signal;
    }

    // console.log({ options });
    const res = await fetch(`${SERVER_URL}/${endPoint}`, options);
    // Processing Response

    if (!res.ok) {
      let x = await res.json();
      throw new Error(x);
    }

    const resData = await res.json();
    // console.log({ resData });

    if (resData.err) {
      console.log({ resData });
      if (resData.err.action === "LOGOUT") {
        console.log({ callServer_err: resData.err });
        store.dispatch({ type: "FORCE_LOGOUT" });
        throw { msg: resData.err.message };
      } else {
        throw resData.err;
      }
    }
    if (resData.error) {
      throw resData.error;
    }

    return resData;
  } catch (err) {
    console.log({ insideCall: err });
    throw err;
  }
};
