import React from "react";
import Colors from "../constants/Colors";
import { makeRandom, useWindowSize } from "../utils/customHooks";

import "./List.css";

/**
 *
 * width = int
 * value = {value: x, title: y}
 * setValue = func({value: x, title: y})
 * list = [{value: x, title: y},{value: x, title: y}...]
 *
 *
 */

const List = (props) => {
  const random = makeRandom();
  const [width, height] = useWindowSize();

  const [list, setList] = React.useState([]);
  const [showList, setShowList] = React.useState(false);
  const [value, setValue] = React.useState(
    props.value || { value: 0, title: " - " }
  );

  React.useLayoutEffect(() => {
    createList();
  }, [props.list]);

  //   React.useEffect(() => {
  //     if (props.setValue) {
  //       props.setValue(value);
  //     }
  //   }, [value]);

  const createList = () => {
    const tempt = [];
    if (!props.list) {
      for (let i = 0; i < 20; ) {
        i++;
        tempt.push({ value: i, title: i });
      }
      setList(tempt);
    } else {
      setList(props.list);
    }
    // console.log({ list });
  };

  return (
    <React.Fragment>
      <div
        // id={"searchList"}
        style={{
          zIndex: 56,
          width: props.width || 100,
          height: 20,
          minHeight: 30,
          maxHeight: 20,
          padding: 5,
          backgroundColor: "white",
          borderRadius: 20,
          position: "relative",
          // margin: 5,
          cursor: "pointer",
          opacity: props.loading ? 0.8 : 1,
          ...props.style,
        }}
        onClick={() => {
          if (props.loading) {
            return;
          }
          setShowList((state) => !state);
        }}
      >
        <p
          style={{
            textAlign: "center",
            margin: 0,
            padding: 0,
            overflow: "hidden",
            color: Colors.primary,
            fontSize: 15,
            // fontWeight: "600",
            ...props.textStyle,
          }}
        >
          {props.value ? props.value.title : value.title}
        </p>
        {showList ? (
          <div
            className={"shadow"}
            style={{
              position: "absolute",
              display: "block",
              zIndex: 60,
              padding: 5,
              backgroundColor: "white",
              borderRadius: 10,
              width: props.width || 100,
              top: "100%",
              marginTop: 1,
              left: 0,
              borderTop: "1px solid #ccc",
              ...props.style,
              // WebkitBoxShadow: "0px 10px 25px -2px rgba(0, 0, 0, 0.75)",
              // MozBoxShadow: "0px 10px 25px -2px rgba(0, 0, 0, 0.75)",
              // BoxShadow: "0px 10px 25px -2px rgba(0, 0, 0, 0.75)",
            }}
          >
            <div
              id={"sel"}
              style={{
                overflow: "scroll",
                // maxHeight: 150,
                height: props.height || 210,
                scrollbarWidth: "thin !important ",
                overflowX: "hidden",
              }}
            >
              {/* <div
                value="0"
                style={{
                  cursor: "pointer",
                  color: Colors.primary,
                  fontSize: 15,
                }}
              >
                {" "}
                -{" "}
              </div> */}
              {list.map((x, index) => {
                const key = `${x.value}-${
                  x.title
                }-${index}-${Date.now()}-${random}`;
                return (
                  <div
                    key={key}
                    value={x.value}
                    style={{
                      cursor: "pointer",
                      color: Colors.primary,
                      padding: 5,
                      fontSize: 15,
                    }}
                    onClick={() => {
                      if (props.setValue) {
                        props.setValue(x);
                      } else {
                        setValue(x);
                      }
                    }}
                  >
                    {x.title}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
      {showList ? (
        <div
          onClick={() => setShowList(false)}
          style={{
            position: "absolute",
            backgroundColor: "transparent",
            width: "100%",
            height: height,
            left: 0,
            top: 0,
          }}
        />
      ) : null}
    </React.Fragment>
  );
};

export default List;
