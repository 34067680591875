export const imageUrl =
  "dpp4b1kz3kisqceybwih-f3c84057-83bd-4ab2-8830-31790a7833e3-1624270981174.jpeg";

export const newItems = [
  {
    _id: "sbcugw87eqhgctb43tymyi,iykjtrhy53ny78whge798h",
    currency: "jmd",
    title: "WWWWWWW WWWWDWW WWWW",
    image: imageUrl,
    price: 386345,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "sbcugw87eqhgc78g87rnmrm,ttyjec6474n45bdmltk8h",
    verified: true,
    currency: "jmd",
    title: "New Item for sale",
    image: imageUrl,
    price: 1523,
    location: "JM - Kingston & St Andrew",
  },
  // {
  //   _id: "cyn9y8stxd76v3r2c8y2nvy98cun2982",
  //   title: "New Item for sale",
  //   featured: true,
  //   image: imageUrl,
  //   price: 300,
  //   location: "JM - Kingston & St Andrew",
  // },
  {
    _id: "98cyehc97whc9hw98yukkfyuf,berech98hw98ch",
    ad: true,
    image:
      "31l5toh8w3hb3fsfa85h-c6dc8fd4-c0bf-4773-a098-1a9009962557-1624271195879.jpeg",
  },
  {
    _id: "ocey29y97cy29yv92y3vfukfymuyf9y9r2vy91d32905ymn",
    proUser: true,
    currency: "jmd",
    proPromoted: true,
    title: "WWWWWWW WWWWDWW WWWW",
    image: imageUrl,
    price: 28685.45,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "sbcugw87eqhgc78g87echgasdasd78whge798h",
    proUser: true,
    currency: "usd",
    title: "New Item for sale",
    image: imageUrl,
    price: 6250000,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "sbcugw87eqhgctb4dfbngfdndggn3y53ny78whge798h",
    currency: "jmd",
    title: "WWWWWWW WWWWDWW WWWW",
    image: imageUrl,
    price: 386345,
    sold: true,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "wiuwyc87287cry782cjhlfkhj87by28vh822ycv2",
    promoted: true,
    currency: "jmd",
    title: "New Item for sale check it out here!!!!",
    image: imageUrl,
    price: 300,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "cyn9y8stxd76v3r2c8y2nvy98cun2982",
    title: "New Item for sale",
    // featured: true,
    currency: "jmd",
    image: imageUrl,
    price: 300,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "98cyehc97whc9hw98ch9dgbna hfmh 8hw98ch",
    ad: true,
    currency: "jmd",
    image:
      "uj5voxtu2iunduiq2p4f-9e3feb84-f493-4a69-9983-2bc43b82a6b9-1624270039828.jpeg",
  },
  {
    _id: "ocey29y97cy29yv92yh h wg hthrwh rt 3v9y9r2vy91d32905ymn",
    proUser: true,
    proPromoted: true,
    title: "WWWWWWW WWWWDWW WWWW",
    image: imageUrl,
    price: 300,
    currency: "jmd",
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "sbcugw87eqhgc78g87ech d fytuyt hdfg78whge798h",
    proUser: true,
    title: "New Item for sale",
    image: imageUrl,
    currency: "jmd",
    price: 300,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "sbcugw87eqhgctb43  rt  rr nhtr et by53ny78whge798h",
    title: "New Item for sale",
    image: imageUrl,
    currency: "jmd",
    price: 300,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "sbcugw87eqhgc78g87ec6474n hrhtyj kt45bdmltk8h",
    verified: true,
    currency: "usd",
    title: "New Item for sale",
    image: imageUrl,
    price: 300,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "wiuwyc87287cry782c86u4uu5j75n7by28vh822ycv2",
    promoted: true,
    currency: "ttd",
    title: "New Item for sale check it out here!!!!",
    image: imageUrl,
    price: 300,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "cyn9y8stxd76v3r2c8y2nvy98cun2982",
    title: "New Item for sale",
    // featured: true,
    image: imageUrl,
    currency: "jmd",
    price: 300,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "cyn9y8stxd76v3r2c8y2nvy98cun2982",
    title: "New Item for sale",
    // featured: true,
    image: imageUrl,
    currency: "jmd",
    price: 300,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "cyn9y8stxd76v3r2c8y2nvy98cun2982",
    title: "New Item for sale",
    // featured: true,
    image: imageUrl,
    currency: "jmd",
    price: 300,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "cyn9y8stxd76v3r2c8y2nvy98cun2982",
    title: "New Item for sale",
    // featured: true,
    image: imageUrl,
    currency: "jmd",
    price: 300,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "cyn9y8stxd76v3r2c8y2nvy98cun2982",
    title: "New Item for sale",
    // featured: true,
    image: imageUrl,
    currency: "jmd",
    price: 300,
    location: "JM - Kingston & St Andrew",
  },
  {
    _id: "cyn9y8stxd76v3r2c8y2nvy98cun2982",
    title: "New Item for sale",
    // featured: true,
    image: imageUrl,
    currency: "jmd",
    price: 300,
    location: "JM - Kingston & St Andrew",
  },
];

export const demo = [
  {
    _id: "1234567890_qwaszxerdfcvdsds",
    msg: "myMessage",
    userId: true,
    sellerId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    message:
      " 1 this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
  {
    _id: "1234567890_qwaszvfervexerdfcv",
    msg: "myMessage",
    userId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    sellerId: true,
    message:
      "2 this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
  {
    _id: "1234567890_qwaszxv4t43erdfcv",
    msg: "myMessage",
    userId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    sellerId: true,
    message:
      "3 this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
  {
    _id: "1234567890_qwasz 3 t45y6xerdfcv",
    msg: "myMessage",
    userId: true,
    sellerId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    message:
      "4 this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
  {
    _id: "1234567890_qwas 54y4554 zxerdfcv",
    msg: "myMessage",
    userId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    sellerId: true,
    message:
      "5 this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
  {
    _id: "1234567890_qwas54 y 4 y54zxerdfcv",
    msg: "myMessage",
    userId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    sellerId: true,
    message: "this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
  {
    _id: "1234567890_qwaszxe4thyngfbrdfcv",
    msg: "myMessage",
    userId: true,
    sellerId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    message: "this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
  {
    _id: "1234567890_qwaszvnmkutjyrxerdfcv",
    msg: "myMessage",
    userId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    sellerId: true,
    message: "this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
  {
    _id: "1234567890_qwaszxbnhtyrnmuykiyuerdfcv",
    msg: "myMessage",
    userId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    sellerId: true,
    message: "this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
  {
    _id: "1234567890_qwaszxerfvbtynhb423tyndfcv",
    msg: "myMessage",
    userId: true,
    sellerId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    message:
      "i this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
  {
    _id: "1234567890_qwaszxe4g3ght44665j7rdfcv",
    msg: "myMessage",
    userId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    sellerId: true,
    message:
      "h this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
  {
    _id: "1234567890_qwasbnymuyyulk66y452ewzxerdfcv",
    msg: "myMessage",
    userId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    sellerId: true,
    message:
      "g this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: true,
    read: false,
  },
  {
    _id: "1234567890_qwaszxe4gerdfcv",
    msg: "myMessage",
    userId: true,
    sellerId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    message:
      "f this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },

  {
    _id: "1234567890_qwaszxebgfhnmj5t5mutmtrdfcv",
    msg: "myMessage",
    userId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    sellerId: true,
    message:
      "e this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
  {
    _id: "1234567890_qwaszx34ggg335g546hh666ryhg",
    userId: true,
    sellerId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    message:
      "d this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: true,
    read: false,
  },
  {
    _id: "1234567890_qwaqwffszxerdfcv",
    msg: "c myMessage",
    userId: true,
    sellerId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    message:
      "c this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: true,
    read: false,
  },
  {
    _id: "1234567890_qwaszxfffffferdfcv",
    msg: "b myMessage",
    userId: true,
    sellerId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    message:
      "b this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
  {
    _id: "1234567890_qwaszxgggggggerdfcv",
    msg: "a myMessage",
    userId: "7375bf0c-0ec1-4a3b-9d2f-66781a8878cc-1630762645312",
    sellerId: true,
    message:
      "a this is a test message fot the chat to hold dummy text in place",
    bid: null,
    refItemId: null,
    read: false,
  },
];
