import React, { useEffect, useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import Colors from "../constants/Colors";
import { useIsMountedRef } from "../utils/customHooks";
import { InView } from "react-intersection-observer";
import { isMobile } from "react-device-detect";

const HoverComp = (props) => {
  const isMounted = useIsMountedRef();
  const [id_1] = useState(uuidv4());
  const [show, setShow] = useState(false);
  const [inView, setInView] = useState(false);
  const [mobile] = useState(isMobile);

  const handleMouseEnter = useCallback(() => {
    // console.log(`${props.eleId} - Entered`);
    const timer = setTimeout(() => {
      if (isMounted.current) {
        setShow(true);
      }
    }, 1000);
    return timer;
  }, [props.eleId, isMounted, inView]);

  const handleMouseLeave = useCallback(() => {
    if (isMounted.current && !props.disableClose) {
      setShow(false);
    }
  }, [isMounted, inView]);

  useEffect(() => {
    let timer;
    const ele = document.getElementById(props.eleId);

    if (ele) {
      // console.log("LISTENER ADDED FOR " + props.eleId);
      ele.addEventListener("mouseenter", () => {
        timer = handleMouseEnter();
      });
      ele.addEventListener("mouseleave", () => {
        handleMouseLeave();
        if (timer) {
          clearTimeout(timer);
        }
      });
    }

    return () => {
      if (ele) {
        ele.removeEventListener("mouseenter", handleMouseEnter);
        ele.removeEventListener("mouseleave", handleMouseLeave);
      }
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [props.eleId, handleMouseEnter, handleMouseLeave, inView]);

  const content = props.renderProp ? (
    props.renderProp()
  ) : props.text ? (
    <p
      id={id_1}
      className="shadow"
      style={{
        display: mobile ? "none" : show ? "block" : "none",
        position: "absolute",
        backgroundColor: Colors.secondary,
        padding: 5,
        margin: 0,
        border: `1px solid white`,
        borderRadius: 5,
        color: Colors.primary,
        fontWeight: "bold",
        zIndex: 60,
        textAlign: "center",
        ...props.style,
      }}
    >
      {props.text}
    </p>
  ) : (
    props.children
  );

  return (
    <>
      <InView
        style={{ width: 1 }}
        onChange={(theView) => {
          // console.log({ theView });
          setInView(theView);
        }}
      />
      {content}
    </>
  );
};

export default HoverComp;
