import React from "react";

import ModalView from "../ModalView";
import Create from "./Create";

const NewItemModal = (props) => {
  const [disable, setDisable] = React.useState(false);
  const [leftIcon, setLeftIcon] = React.useState(true);

  return (
    <React.Fragment>
      <ModalView
        uniqueId={"newPost"}
        // style={{ maxWidth: 1200 }}
        visible={props.visible}
        loading={props.loading}
        disable={props.loading}
        // leftIcon={leftIcon}
        leftIconAction={() => {
          console.log("BACK BTN CLICKED");
        }}
        close={props.close}
      >
        <Create
          close={props.close}
          edit={props.edit}
          itemInfo={props.itemInfo}
          loading={props.loading}
          setLoading={props.setLoading}
          fetchItemDetails={props.fetchItemDetails}
        />
      </ModalView>
    </React.Fragment>
  );
};

export default NewItemModal;
