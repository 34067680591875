import React from "react";

import Colors from "../../constants/Colors";

import { useSelector } from "react-redux";
import { networkTest } from "../../store/helpers/actionHelper";
import { useWindowSize } from "../../utils/customHooks";

const Connection = (props) => {
  const live = useSelector((state) => state.connection.live);
  const darkMode = useSelector((state) => state.display.darkMode);
  const [width, height] = useWindowSize();

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    // console.log({ live });
  }, [live]);

  const testConnection = async () => {
    try {
      // console.log({ live });
      props.setLoading(true);
      await networkTest();
      props.setLoading(false);
    } catch (err) {
      props.setLoading(false);
      console.log({ err });
    }
  };

  return (
    <React.Fragment>
      {live ? null : (
        <div
          style={{
            position: "sticky",
            top: 0,
            width: "100%",
            height: height,
            backgroundColor: darkMode
              ? Colors.darkBackground
              : Colors.lightBackground,
            // backgroundColor: "rgba(255, 255, 255, 0.88)",
            zIndex: 201,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            alt="logo"
            // src={"%PUBLIC_URL%//logo.svg"}
            src={"/logo.svg"}
            style={{ margin: 0, height: 150 }}
          />
          <div style={{}}>
            <h3
              style={{
                color: darkMode ? Colors.darModeText : Colors.lightModeText,
                margin: 20,
                marginBottom: 5,
              }}
            >
              Connection Issue
            </h3>
            <p
              style={{
                textAlign: "center",
                padding: 20,
                paddingTop: 0,
                marginRight: "auto",
                marginLeft: "auto",
                color: darkMode ? Colors.darModeText : Colors.lightModeText,
              }}
            >
              Unable to connect with our servers, please check your internet
              connection and try again.
            </p>
            <button
              className={"universalBtn" + `${props.loading ? " App-logo" : ""}`}
              style={{
                marginRight: "auto",
                marginLeft: "auto",
                backgroundColor: props.loading ? Colors.err : Colors.primary,
                color: props.loading ? Colors.primary : "white",
                // opacity: props.loading ? 0.3 : 1,
              }}
              onClick={() => {
                testConnection();
              }}
            >
              {props.loading ? "Connecting..." : "Try again"}
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Connection;
