import React, { useState, useEffect, useRef } from "react";

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  React.useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [value]);

  return debouncedValue;
};

/**
 * useWindowSize Hook
 *
    const Comp = props => {
        const [width, height] = useWindowSize();

        // Get the height of an elements and set a new height based on width the browser window
        const resizeContainer = () => {
            const element = document.getElementById(ElementId);

            const newHeight =
            (parseInt(getComputedStyle(element).width.split("p")[0]) / 16) * 9;

            setMaxHeight(newHeight);
        };

        React.useEffect(() => {
            resizeContainer();
        }, [width, height]);

        {...JS/JSX...}
    }
 
 */

export const useIsMountedRef = () => {
  const isMountedRef = useRef(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  });
  return isMountedRef;
};

/**
 * useIsMountedRef Hook
 * Checks is comp is mounted before doing and render refresh
 *
    const Comp = props => {
        const isMountedRef = useIsMountedRef();
        const [result, setResult] = React.useState({})

        
        const fetchSomethingAsync = async() => {
            try {
            const resData = await callServer();

            if(isMountedRef.current){
              setResult(resData)
            }
            } catch (err) {
            
            }
        };

        React.useEffect(() => {
            fetchSomethingAsync();
        }, []);

        {...JS/JSX...}
    }
 
 */

export const makeRandom = (min_val, max_val) => {
  const min =
    ([0].includes(min_val) ? "0" : min_val > 0 ? 1 : null) ||
    100000000000000000000;
  const max = max_val || 999999999999999999999;
  const value = Math.random() * (max - parseInt(min)) + parseInt(min);
  return parseInt(value.toFixed(0));
};

const memoryState = {};
export const useMemoryState = (key, initialState) => {
  const [state, setState] = useState(() => {
    const hasMemoryValue = Object.prototype.hasOwnProperty.call(
      memoryState,
      key
    );
    if (hasMemoryValue) {
      return memoryState[key];
    } else {
      return typeof initialState === "function" ? initialState() : initialState;
    }
  });

  function onChange(nextState) {
    memoryState[key] = nextState;
    setState(nextState);
  }

  return [state, onChange];
};

export const nFormatter = (isMountRef, num, digits) => {
  var unitlist = ["", "K", "M", "G"];
  if (!isMountRef || !isMountRef.current) return;
  if (typeof num === "number") {
    let sign = Math.sign(num);
    let unit = 0;

    while (Math.abs(num) > 1000) {
      unit = unit + 1;
      num = Math.floor(Math.abs(num) / 100) / 10;
    }
    return sign * Math.abs(num) + unitlist[unit];
  } else {
    return "0";
  }
};

/**
 * 
 * 
 *  import React, { useState, createRef } from 'react'
 * 
 *  export default function Comp() {
    const divRef = createRef()
    const dimensions = useRefDimensions(divRef)

    return (
      <div style={{ height: '100vh', width: '100vw' }}>
        <div
          ref={divRef}
          style={{
            margin: '50px',
            width: '70%',
            height: '70%',
            border: '1px solid black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Dimensions: {dimensions.width}w {dimensions.height}h
        </div>
      </div>
    )
}
 */

export const useRefDimensions = (ref) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    console.log([ref]);
    if (ref) {
      if (ref.current) {
        const { current } = ref;
        const boundingRect = current.getBoundingClientRect();
        const { width, height } = boundingRect;
        setDimensions({ width: Math.round(width), height: Math.round(height) });
      }
    }
  }, [ref]);
  return dimensions;
};

export const useMousePosition = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
    window.addEventListener("mousemove", setFromEvent);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
    };
  }, []);

  return position;
};
