/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable default-case */
const initialState = {
  proUserImageLimit: 0,
  regUserImageLimit: 0,
  proUserTagLimit: 0,
  regUserTagLimit: 0,
  descriptionLimit: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_LIMITS":
      return action.limits;
  }
  return state;
};
