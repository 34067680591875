/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable default-case */
const initialState = {
  currencies: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_CUR":
      return {
        currencies: action.currencies,
      };
  }
  return state;
};
