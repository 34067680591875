/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import GoogleLogin from "react-google-login";

import { FcGoogle } from "react-icons/fc";

import { callServer } from "../../store/helpers/actionHelper";
import { useIsMountedRef } from "../../utils/customHooks";

/**
 * 
 * 
 * PROPS
        style={{ height: 40 }}
        btnText={"Sign Up with Google"}
        successFunc={() => console.log("SUCCESS_GOOGLE")}
        errorFunc={() => console.log("ERROR_GOOGLE")}
 */

const Google_OAuth = (props) => {
  const isMountRef = useIsMountedRef();
  const [appId, setAppId] = React.useState("");

  React.useEffect(() => {
    fetchAppId();
    const ele = document.getElementById("google_btn");
    if (ele) {
      ele.addEventListener("click", () => {
        ele.style.transform = "scale(0.8)";
        setTimeout(() => {
          ele.style.transform = "scale(1)";
        }, 100);
      });
    }

    return () => {
      if (ele) {
        ele.removeEventListener("click", () => {});
      }
    };
  }, []);

  const responseGoogle = async (response) => {
    // console.log({ response });
    try {
      if (!isMountRef.current) return;
      if (props.setMainErr) {
        props.setMainErr("");
      }
      props.setLoading(true);

      if (response.error) {
        if (
          [
            "popup_closed_by_user",
            "idpiframe_initialization_failed",
            "access_denied",
            "immediate_failed",
          ].includes(response.error)
        ) {
          throw response.error;
        }
      }

      if (props.signUp) {
        await callServer({
          apiMethod: "post",
          endPoint: "server/auth/social/validate-email",
          theBody: {
            email: response.profileObj.email,
          },
        });
      }

      if (props.setSocialType) {
        await props.setSocialType("google");
      }

      if (props.setSocialToken) {
        await props.setSocialToken(response.tokenId);
      }

      if (props.setSocialName) {
        await props.setSocialName(response.profileObj.givenName);
      }

      if (props.set_last_name) {
        await props.set_last_name(response.profileObj.familyName);
      }

      const return_body = {
        socialType: "google",
        socialToken: response.tokenId,
        email: response.profileObj.email,
        social_type: "google",
      };

      if (props.setSocialVariables) {
        props.setSocialVariables(return_body);
      }

      return props.successFunc(return_body);
    } catch (err) {
      if (!isMountRef.current) return;
      props.setLoading(false);
      if (err.msg) {
        if (props.setMainErr) {
          props.setMainErr(err.msg);
        }
      }
      if (props.errorFunc) {
        props.errorFunc();
      }
      console.log({ err });
    }
  };

  const fetchAppId = async () => {
    try {
      const resData = await callServer({
        apiMethod: "get",
        endPoint: "server/auth/social/fetch-id/google",
      });

      // console.log({ resData });
      if (resData.appId) {
        setAppId(resData.appId);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  if (!appId) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <GoogleLogin
        clientId={appId}
        // buttonText="Sign Up"
        // scope={"userinfo, email, openid"}
        render={(renderProps) => (
          <button
            id={"google_btn"}
            className={"universalBtn"}
            style={{
              opacity: props.loading || !appId ? 0.3 : 1,
              ...props.style,
            }}
            onClick={() => {
              props.setLoading(true);
              renderProps.onClick();
            }}
            disabled={renderProps.disabled}
          >
            <FcGoogle style={{ marginRight: 10, fontSize: 20 }} />
            {props.btnText ? props.btnText : "Use Google"}
          </button>
        )}
        disabled={props.loading || !appId}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
    </React.Fragment>
  );
};

export default Google_OAuth;
