/* eslint-disable default-case */
/* eslint-disable import/no-anonymous-default-export */
// import { AUTH, LOGOUT, UPDATE_INFO, LOGGED_IN } from "../actions/auth_action";
// import { FORCE_LOGOUT } from "../helpers/actionHelper";

// const initialState = {
//   first_name: "Dwight",
//   last_name: "Thorpe",
//   age: 26,
//   gender: "male",
//   token: false,
// };

const initialState = {
  token: null,
  userInfo: null,
};

function clearCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "AUTH":
      if (action.token) {
        // localStorage.removeItem("userInfo");
        localStorage.setItem("userInfo", JSON.stringify(action));
        localStorage.setItem("jwt", JSON.stringify(action.token));
      }
      return {
        ...state,
        valid: action.valid,
        userId: action.userId,
        tokenInfo: action.tokenInfo,
        userInfo: action.userInfo,
        token: action.token,
      };
    case "UPDATE_INFO":
      // localStorage.removeItem("userInfo");
      localStorage.setItem(
        "userData",
        JSON.stringify({
          ...state,
          userInfo: action.userInfo,
        })
      );
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case "LOGGED_IN":
      return {
        ...state,
        token: action.token,
        userInfo: action.userInfo,
      };
    case "LOGOUT":
      document.cookie = "token; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      localStorage.removeItem("userInfo");
      localStorage.removeItem("jwt");
      localStorage.removeItem("token");
      clearCookie("token");
      return {
        ...initialState,
      };
    case "FORCE_LOGOUT":
      document.cookie = "token; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      localStorage.removeItem("userInfo");
      localStorage.removeItem("jwt");
      localStorage.removeItem("token");
      clearCookie("token");
      return {
        ...initialState,
      };
  }
  return state;
};
