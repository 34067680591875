import React, { forwardRef, useImperativeHandle } from "react";

import { v4 as uuidv4 } from "uuid";

const useIsMountedRef = () => {
  const isMountedRef = React.useRef(null);
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  });
  return isMountedRef;
};

/**
 *
 * PROPS
 *
 *    *list -- List of items
 *    *hasMore -- does the list have more to load
 *    *loadMore -- Loading function
 *    *loading -- know when loading
 *
 *    reverse -- boolean if this a reverse scrolling list or not
 *    loadingMsg -- Msg to display while loading
 *    loadComp -- () => <Comp>---</Comp> - renders customer loading component
 *
 *    style -- to style scrolling component
 *    setScrollPosition -- function to store scroll position
 *    scrollPosition -- scrollPosition
 *    threshold -- loadMore threshold
 *
 */

const InfinityScroll = forwardRef((props, ref) => {
  const isMounted = useIsMountedRef();
  const scrollArea = React.createRef();

  const [initial, setInitial] = React.useState(true);
  const [firstLoad, setFirstLoad] = React.useState(true);

  const [id_1, setId_1] = React.useState(uuidv4());

  const [temptHeight, setTemptHeight] = React.useState(0);
  const [firstValue, setFirstValue] = React.useState(null);
  const [lastValue, setLastValue] = React.useState(null);

  var reloading = false;

  React.useEffect(() => {
    const ele = document.getElementById(id_1);

    if (!initial) {
      reloading = false;
      if (props.list.length < 1) {
        setInitial(false);
        setFirstLoad(true);
      } else if (
        !props.reverse
          ? props.list[props.list.length - 1]._id !== lastValue
          : props.list[0]._id !== firstValue
      ) {
        setFirstValue(props.list[0]._id);
        setLastValue(props.list[props.list.length - 1]._id);

        if (!firstLoad) {
          calculatePosition();
        } else {
          setFirstLoad(false);
        }
      } else if (
        !props.reverse
          ? props.list[0]._id !== firstValue
          : props.list[props.list.length - 1]._id !== lastValue
      ) {
        setFirstValue(props.list[0]._id);
        setLastValue(props.list[props.list.length - 1]._id);
        pushToTop();
      }
    } else {
      setInitial(false);
      setFirstValue(props.list.length > 0 ? props.list[0]._id : "");
      setLastValue(
        props.list.length > 0 ? props.list[props.list.length - 1]._id : ""
      );
    }
  }, [props.list.length]);

  React.useEffect(() => {
    if (props.scrollPosition) {
      const ele = document.getElementById(id_1);
      ele.scrollTo({
        top: props.scrollPosition,
        behaviour: "smooth",
      });
    }
  }, []);

  useImperativeHandle(ref, () => ({
    pushToTop() {
      pushToTop();
    },
  }));

  const pushToTop = () => {
    const ele = document.getElementById(id_1);

    const x = props.reverse ? "100%" : 0;

    ele.scrollTo({
      top: x,
      behavior: "auto",
    });
  };

  const fetchMoreData = React.useCallback((e) => {
    try {
      if (props.setScrollPosition) {
        props.setScrollPosition(e);
      }
      if (e && !reloading) {
        if (!isMounted.current) return;

        if (
          (props.reverse
            ? parseInt(
                (
                  e.target.scrollTop - e.target.getBoundingClientRect().height
                ).toFixed(0)
              )
            : -parseInt(
                (
                  e.target.scrollTop + e.target.getBoundingClientRect().height
                ).toFixed(0)
              )) <=
            -scrollArea.current.scrollHeight +
              (props.threshold ? props.threshold : 20) &&
          !reloading &&
          props.hasMore
        ) {
          console.log(true);
          if (!isMounted.current) return;
          reloading = true;

          if (!isMounted.current) return;

          setTemptHeight(
            props.reverse
              ? e.target.scrollTop - e.target.getBoundingClientRect().height
              : e.target.scrollTop - e.target.getBoundingClientRect().height
          );

          if (!isMounted.current) return;

          props.loadMore();

          e.target.scrollTo({
            top: props.reverse
              ? e.target.scrollTop - e.target.getBoundingClientRect().height - 1
              : e.target.scrollTop +
                e.target.getBoundingClientRect().height -
                100,
            behavior: "smooth",
          });

          if (!isMounted.current) return;
        }
      }
    } catch (err) {
      console.log({ err });
    }
  });

  const calculatePosition = () => {
    if (!isMounted.current) return;
    const ele = document.getElementById(id_1);

    if (!isMounted.current) return;

    if (!isMounted.current) return;
    ele.scrollTo({
      top: props.reverse
        ? temptHeight + ele.getBoundingClientRect().height - 20
        : temptHeight + ele.getBoundingClientRect().height + 20,
      behavior: "auto",
    });
    if (!isMounted.current) return;
  };

  return (
    <React.Fragment>
      <div
        id={id_1}
        className="custom_scroll"
        ref={scrollArea}
        onScroll={fetchMoreData}
        style={{
          // marginTop: 5,
          // marginBottom: 5,
          padding: 0,
          // paddingTop: 5,
          // paddingBottom: 5,
          height: 100,
          width: "100%",
          backgroundColor: "white",
          flex: "auto",
          overflowY: "auto",
          display: "flex",
          flexDirection: props.reverse ? "column-reverse" : "column",
          justifyContent: "flex-start",
          ...props.style,
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          {/* {props.loadComp()} */}
          {props.loading ? (
            props.loadComp ? (
              props.loadComp()
            ) : (
              <p
                className="shadow"
                style={{
                  padding: 5,
                  textAlign: "center",
                  position: props.list.length > 1 ? "absolute" : "relative",
                  width: "100%",
                  top: props.reverse ? 0 : null,
                  bottom: props.reverse ? null : 0,
                  left: 0,
                  zIndex: 200,
                  backgroundColor: "black",
                  color: "#ccc",
                  opacity: 0.8,
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                {props.loadingMsg ? props.loadingMsg : "Loading..."}
              </p>
            )
          ) : null}
          {props.list.length > 0 ? (
            <React.Fragment>{props.children}</React.Fragment>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
});

export default InfinityScroll;
