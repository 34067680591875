const Colors = {
  primary: "#2f3e57",
  primary_darker: "#202b3c",
  darkBackground: "rgb(11 20 28)",
  lightBackground: "white",
  darModeText: "rgb(206 225 255)",
  lightModeText: "#474747",
  main: "#2f3e57",
  harmony_1: "#57482f",
  harmony_2: "#3e572f",
  harmony_3: "#572f3e",
  harmony_4: "#2f5748",
  secondary: "#8da3c9",
  tertiary: "#b8b8b8",
  complimentary: "#57482f",
  contrast: "#ebebeb",
  contrast_darker: "#464746",
  highContrast: "white",
  err: "#ff9494",
  critical: "#E91010",
  good: "#7ADF7A",
  unique: "#CB59FC",
  caution: "#E9E924",
  transparent_low: "rgba(47,62,87,0.3)",
  transparent_high: "rgba(47,62,87,0.9)",
  transparent_full: "rgba(255, 255, 255, 0)",
  serious: "#E72727",
  invalid: "#ccc",
};

export default Colors;
