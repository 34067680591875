import React from "react";
import Colors from "../../constants/Colors";

export const Reg = (props) => (
  <div
    className="shadow"
    style={{
      width: 200,
      marginBottom: 20,
      marginLeft: 10,
      marginRight: 10,
      height: 310,
      backgroundColor: "white",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      // justifyContent: "center",
      alignItems: "center",
      borderRadius: "7px 7px 5px 5px",
      marginTop: 20,
      // overflow: "hidden",
    }}
  >
    <div
      style={{
        backgroundColor: Colors.primary,
        width: "100%",
        borderRadius: "5px 5px 0px 0px",
        padding: 5,
      }}
    >
      <p
        style={{
          textAlign: "center",
          fontSize: 20,
          margin: 0,
          fontWeight: "white",
          color: "white",
        }}
      >
        Regular User
      </p>
    </div>
    <div
      style={{
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 10,
        marginTop: 10,
        width: "100%",
        height: 70,
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p
        style={{
          color: "#606060",
          textAlign: "center",
          fontSize: 30,
          marginLeft: 10,
          marginRight: 10,
          marginBottom: 0,
          marginTop: 0,
          fontWeight: "bold",
        }}
      >
        FREE
      </p>
      <p
        style={{
          color: Colors.primary,
          textAlign: "center",
          fontSize: 15,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          fontWeight: "bold",
          marginTop: -10,
        }}
      >
        No Hidden Fees
      </p>
    </div>
    <div style={{}}>
      <p style={{ textAlign: "center", margin: 0 }}>Buy/Sell Items</p>
      {/* <p style={{ textAlign: "center", margin: 0 }}>Limited Active Items</p>
      <p
        style={{
          textAlign: "center",
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          fontSize: 10,
          marginTop: -5,
        }}
      >
        20 Active items at a time
      </p> */}
      <p style={{ textAlign: "center", margin: 0 }}>5 Images per Item</p>
      <p
        style={{
          textAlign: "center",
          marginBottom: 5,
          marginLeft: 5,
          marginRight: 5,
          marginTop: 30,
          fontSize: 15,
          lineHeight: 1.1,
          color: "#606060",
        }}
      >
        Register for FREE and upgrade to Pro User later.
      </p>
    </div>

    <button
      className="universalBtn"
      style={{
        width: 150,
        marginLeft: "auto",
        marginRight: "auto",
        color: "white",
        backgroundColor: Colors.primary,
        position: "absolute",
        alignSelf: "center",
        bottom: "-8%",
        // borderRadius: 0,
      }}
      onClick={() => props.setUserType("reg")}
    >
      Register
    </button>
  </div>
);

export const Pro = (props) => (
  <div
    className="shadow"
    style={{
      width: 200,
      margin: 10,
      height: 310,
      backgroundColor: "white",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      // justifyContent: "center",
      alignItems: "center",
      borderRadius: "7px 7px 5px 5px",
      marginTop: 20,
      marginBottom: 20,
      // overflow: "hidden",
    }}
  >
    <div
      style={{
        backgroundColor: Colors.primary,
        width: "100%",
        borderRadius: "5px 5px 0px 0px",
        padding: 5,
      }}
    >
      <p
        style={{
          textAlign: "center",
          fontSize: 20,
          margin: 0,
          fontWeight: "white",
          color: "white",
        }}
      >
        Pro User
      </p>
    </div>
    <div
      style={{
        // backgroundColor: "#ccc",
        marginBottom: 10,
        marginTop: 10,
        width: "100%",
        height: 70,
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p
        style={{
          color: "#606060",
          textAlign: "center",
          fontSize: 30,
          margin: 0,
          fontWeight: "bold",
        }}
      >
        $10
      </p>
      <p
        style={{
          color: Colors.primary,
          textAlign: "center",
          fontSize: 15,
          margin: 0,
          fontWeight: "bold",
          marginTop: -10,
        }}
      >
        /month
      </p>
      <p
        style={{
          color: "#606060",
          textAlign: "center",
          fontSize: 10,
          margin: 0,
          fontWeight: "bold",
          marginTop: -5,
        }}
      >
        Yearly $8.50/Month
      </p>
    </div>
    <div style={{}}>
      <p style={{ textAlign: "center", margin: 0 }}>Buy/Sell Items</p>
      {/* <p style={{ textAlign: "center", margin: 0 }}>Unlimited Active Items</p> */}
      <p
        style={{
          textAlign: "center",
          margin: 0,
        }}
      >
        15 Images per Item
      </p>
      <p
        style={{
          textAlign: "center",
          margin: 0,
          // lineHeight: 1,
        }}
      >
        1 Free Item Promo
      </p>
      <p
        style={{
          textAlign: "center",
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: -5,
          fontSize: 10,
        }}
      >
        Expires when subscription expires
      </p>
      <p
        style={{
          textAlign: "center",
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: -5,
          fontSize: 10,
        }}
      >
        Transferrable to all items
      </p>
      <p style={{ textAlign: "center", margin: 0 }}>Pro Seller Benefits</p>
      <p
        style={{
          textAlign: "center",
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: -5,
          fontSize: 10,
        }}
      >
        Verified Seller Badge
      </p>
      <p
        style={{
          textAlign: "center",
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: -5,
          fontSize: 10,
        }}
      >
        Reduced Rates on Services
      </p>
      <p
        style={{
          textAlign: "center",
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: -5,
          fontSize: 10,
        }}
      >
        Item Inventory Details
      </p>
    </div>

    <button
      className="universalBtn"
      style={{
        width: 150,
        marginLeft: "auto",
        marginRight: "auto",
        color: "white",
        backgroundColor: Colors.primary,
        position: "absolute",
        alignSelf: "center",
        bottom: "-8%",
        // borderRadius: 0,
      }}
      onClick={() => props.setUserType("pro")}
    >
      Register
    </button>
  </div>
);
