/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { lazy, Suspense } from "react";

import { createBrowserHistory, createMemoryHistory } from "history";

import {
  BrowserRouter as TheRouter,
  Switch,
  Route,
  Redirect,
  StaticRouter,
} from "react-router-dom";

import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import StaticBar from "./Components/StaticBar";

import Connection from "./Components/Auth/Connection";

import { useIsMountedRef, useWindowSize } from "./utils/customHooks";

import { deviceDetect } from "react-device-detect";

// import { Provider } from "react-redux";
// import store from "./store/helpers/reducer_index";

import { callServer } from "./store/helpers/actionHelper";
import { useSelector, useDispatch } from "react-redux";

import { fireAnalytics } from "./utils/firebase";
import ReactGA from "react-ga";
import Reset from "./Pages/Reset";
import Colors from "./constants/Colors";
import { SocketProvider } from "./socket/SocketProvider";
import LoadComp from "./Components/LoadComp";

import FetchUser from "./Components/Auth/FetchUser";
import EmailVerify from "./Pages/EmailVerify";

const Home = lazy(() => import("./Pages/Home"));
const ItemDetail = lazy(() => import("./Pages/ItemDetail"));
const SearchPage = lazy(() => import("./Pages/SearchPage"));
const User = lazy(() => import("./Pages/User"));
const ManageAccount = lazy(() => import("./Pages/ManageAccount"));
const NotFound = lazy(() => import("./Pages/NotFound"));
const LikedItems = lazy(() => import("./Pages/LikedItems"));

ReactGA.initialize("G-Q5VMGDZ4S2", {
  debug: true,
  testMode: true,
});

const history =
  typeof window !== "undefined"
    ? createBrowserHistory()
    : createMemoryHistory({
        initialEntries: [],
      });

const defaultSearchData = {
  search: "",
  category: {
    title: "All Categories",
    value: {
      mainCat: "all",
      subCat: {
        value: "all",
      },
    },
  },
  location: { title: "All", value_0: "all", value_1: "all" },
};

const App = () => {
  const isMountRef = useIsMountedRef();
  const searchRef = React.useRef();
  const navRefFunc = React.useRef();
  const homeRefFunc = React.useRef();
  const dispatch = useDispatch();

  const online = useSelector((state) => !!state.auth.token);
  const live = useSelector((state) => state.connection.live);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const limits = useSelector((state) => state.limits);
  const darkMode = useSelector((state) => state.display.darkMode);

  const [searchData, setSearchData] = React.useState(defaultSearchData);

  const [width, height] = useWindowSize();
  const [loading, setLoading] = React.useState(false);
  const [initial, setInitial] = React.useState(true);
  const [ready, setReady] = React.useState(false);
  const [statLoad, setStaticLoad] = React.useState(false);
  const [showAlerts, setShowAlerts] = React.useState(true);
  const [alert, setAlert] = React.useState({});
  const [minAppHeight, setMinAppHeight] = React.useState(0);

  const [hideNav, setHideNav] = React.useState(false);

  React.useEffect(() => {}, [ReactGA, limits]);

  React.useEffect(async () => {
    // Notification.requestPermission();
    if (live) {
      try {
        await fetchLimits();
        // await fetchLocations();
        await fetchCurrency();
        await fetchConditions();
        await fetchCategories();
      } catch (err) {
        console.log({ err });
      }
    }
  }, [live]);

  React.useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    body.style.backgroundColor = darkMode
      ? Colors.darkBackground
      : Colors.lightBackground;
  }, [darkMode]);

  React.useEffect(() => {
    console.log({ online });
    if (online) {
      if (userInfo.location) {
        setSearchData({
          ...searchData,
          location: {
            title: userInfo.location.title,
            value_0: userInfo.location.value_0,
            value_1: "all",
          },
        });
      }
    } else {
      setSearchData(defaultSearchData);
    }
  }, [online]);

  React.useEffect(() => {}, [window.innerHeight]);

  React.useEffect(() => {
    checkMinAppHeight();
  }, [width < 870, window.innerHeight]);

  const checkMinAppHeight = () => {
    // if (!isMountRef.current) return;
    // var value = 0;
    // const ele_1 = document.getElementById("navBar");
    // if (ele_1) {
    //   value = value + ele_1.getBoundingClientRect().height;
    // }
    // const ele_2 = document.getElementById("footerBar_0");
    // if (ele_2) {
    //   value = value + ele_2.getBoundingClientRect().height;
    // }
    // const ele_3 = document.getElementById("footerBar_1");
    // if (ele_3) {
    //   value = value + ele_3.getBoundingClientRect().height;
    // }
    // setMinAppHeight(value);
  };

  const fetchLimits = async () => {
    if (!isMountRef.current) return;

    try {
      const resData = await callServer({
        apiMethod: "get",
        endPoint: "server/initial/limits",
      });
      if (!isMountRef.current) return;

      dispatch({ type: "UPDATE_LIMITS", limits: resData.limits });
    } catch (err) {
      console.log({ err });
    }
  };

  // const fetchLocations = async () => {
  //   if (!isMountRef.current) return;

  //   try {
  //     const resData = await callServer({
  //       apiMethod: "get",
  //       endPoint: "server/initial/locations",
  //     });
  //     if (!isMountRef.current) return;

  //     dispatch({ type: "UPDATE_LOCS", locations: resData.locations });
  //   } catch (err) {
  //     console.log({});
  //   }
  // };

  const fetchCategories = async () => {
    try {
      if (!isMountRef.current) return;
      const resData = await callServer({
        apiMethod: "get",
        endPoint: "server/initial/categories",
      });
      if (!isMountRef.current) return;
      dispatch({ type: "UPDATE_CATS", categories: resData.categories });
      setStaticLoad(true);
    } catch (err) {
      console.log({ err });
      setStaticLoad(true);
    }
  };

  const fetchCurrency = async () => {
    try {
      if (!isMountRef.current) return;
      const resData = await callServer({
        apiMethod: "get",
        endPoint: "server/location/fetch-currencies",
      });
      if (!isMountRef.current) return;
      dispatch({ type: "UPDATE_CUR", currencies: resData.currencies });
    } catch (err) {
      console.log({ err });
    }
  };

  const fetchConditions = async () => {
    try {
      if (!isMountRef.current) return;
      const resData = await callServer({
        apiMethod: "get",
        endPoint: "server/initial/conditions",
      });
      if (!isMountRef.current) return;
      dispatch({ type: "UPDATE_COND", conditions: resData.conditions });
    } catch (err) {
      console.log({ err });
    }
  };

  const LegalRedirect = () => {
    React.useEffect(() => {
      window.location.href = "https://bidsquawk.com/legal.html"; // Redirect to external URL
    }, []);

    return null; // No UI is rendered
  };

  const ContactRedirect = () => {
    React.useEffect(() => {
      window.location.href = "https://bidsquawk.com/contact.html"; // Redirect to external URL
    }, []);

    return null; // No UI is rendered
  };

  return (
    <React.Fragment>
      <SocketProvider>
        <div
          style={{
            backgroundColor: darkMode
              ? Colors.darkBackground
              : Colors.lightBackground,
            minHeight: height,
            maxWidth: width,
          }}
        >
          {/* {width < 870 && !deviceDetect().isBrowser ? (
        <React.Fragment>Mobile</React.Fragment>
      ) : (
        <React.Fragment>PC</React.Fragment>
      )} */}
          <Connection
            logo={"/logo.svg"}
            loading={loading}
            statLoad={statLoad}
            setLoading={setLoading}
            navRefFunc={navRefFunc}
          />
          <RouterHistory>
            <React.Fragment>
              {hideNav ? null : (
                <NavBar
                  ready={ready}
                  ref={navRefFunc}
                  searchData={searchData}
                  setSearchData={setSearchData}
                  searchRef={searchRef}
                  loading={loading}
                  setLoading={setLoading}
                  showAlerts={showAlerts}
                  setShowAlerts={setShowAlerts}
                  alert={alert}
                  setAlert={setAlert}
                  homeRefFunc={homeRefFunc}
                  ReactGA={ReactGA}
                >
                  <FetchUser
                    navRefFunc={navRefFunc}
                    setSearchData={setSearchData}
                    searchData={searchData}
                    ready={ready}
                    statLoad={statLoad}
                    setReady={setReady}
                    ReactGA={ReactGA}
                  />
                </NavBar>
              )}
            </React.Fragment>
            {true ? (
              <>
                <React.Fragment>
                  {ready && live && statLoad ? (
                    <Switch>
                      <Route exact path="/">
                        <Suspense fallback={<LoadComp />}>
                          <Home
                            ready={ready}
                            navRefFunc={navRefFunc}
                            initial={initial}
                            setInitial={setInitial}
                            searchData={searchData}
                            setSearchData={setSearchData}
                            online={online}
                            loading={loading}
                            setLoading={setLoading}
                            ref={homeRefFunc}
                            ReactGA={ReactGA}
                          />
                        </Suspense>
                      </Route>

                      <Route exact path="/legal" component={LegalRedirect} />
                      <Route
                        exact
                        path="/contact"
                        component={ContactRedirect}
                      />

                      <Route path="/verify">
                        <Suspense fallback={<LoadComp />}>
                          <EmailVerify id={2} />
                        </Suspense>
                        {/* <Route path="/validate/:userId/:verifyCode">
                          <Suspense fallback={<LoadComp />}>
                            <EmailVerify id={2} />
                          </Suspense>
                        </Route>

                        <Route path="*">
                        </Route> */}
                      </Route>

                      <Route exact path="/search/:searchText">
                        <Suspense fallback={<LoadComp />}>
                          <SearchPage
                            online={online}
                            navRefFunc={navRefFunc}
                            ref={searchRef}
                            searchData={searchData}
                            loading={loading}
                            setLoading={setLoading}
                            ReactGA={ReactGA}
                          />
                        </Suspense>
                      </Route>
                      <Route exact path="/item/:itemId">
                        <Suspense fallback={<LoadComp />}>
                          <ItemDetail
                            online={online}
                            navRefFunc={navRefFunc}
                            loading={loading}
                            setLoading={setLoading}
                            ReactGA={ReactGA}
                          />
                        </Suspense>
                      </Route>
                      <Route exact path="/user/:userId">
                        <Suspense fallback={<LoadComp />}>
                          <User
                            online={online}
                            navRefFunc={navRefFunc}
                            initial={initial}
                            setInitial={setInitial}
                            loading={loading}
                            setLoading={setLoading}
                            ReactGA={ReactGA}
                          />
                        </Suspense>
                      </Route>
                      <Route exact path="/reset/:userId">
                        <Suspense fallback={<LoadComp />}>
                          <Reset setHideNav={setHideNav} />
                        </Suspense>
                      </Route>

                      {/*
            
    
                Protect Pages 
            
            
                */}

                      <AuthRoute path={"/likes"} online={online}>
                        <Suspense fallback={<LoadComp />}>
                          <LikedItems
                            online={online}
                            redux={{}}
                            ReactGA={ReactGA}
                          />
                        </Suspense>
                      </AuthRoute>
                      <AuthRoute path={"/settings"} online={online}>
                        <Suspense fallback={<LoadComp />}>
                          <ManageAccount
                            online={online}
                            redux={{}}
                            ReactGA={ReactGA}
                          />
                        </Suspense>
                      </AuthRoute>
                      <AuthRoute path="/my-profile/:userId" online={online}>
                        <Suspense fallback={<LoadComp />}>
                          <User
                            online={online}
                            navRefFunc={navRefFunc}
                            initial={initial}
                            setInitial={setInitial}
                            loading={loading}
                            setLoading={setLoading}
                            ReactGA={ReactGA}
                          />
                        </Suspense>
                      </AuthRoute>

                      {/*             
                
    
                404 Redirect 
                
                
                */}

                      <Route path="/404">
                        <Suspense fallback={<LoadComp />}>
                          <NotFound />
                        </Suspense>
                      </Route>

                      <Route>
                        <Redirect to="/404" ReactGA={ReactGA} />
                      </Route>
                    </Switch>
                  ) : (
                    <div
                      id="appWindow"
                      style={{
                        minHeight:
                          window.innerHeight - minAppHeight < 300
                            ? 300
                            : window.innerHeight - minAppHeight,
                        backgroundColor: darkMode
                          ? Colors.primary_darker
                          : Colors.primary,
                      }}
                    ></div>
                  )}
                </React.Fragment>
              </>
            ) : null}
            {hideNav ? null : live ? (
              <React.Fragment>
                {online ? <div style={{ marginBottom: 200 }} /> : null}
                {width < 870 && !deviceDetect().isBrowser ? (
                  <>
                    <div style={{ height: 150 }} />
                    <StaticBar
                      navRefFunc={navRefFunc}
                      loading={loading}
                      setLoading={setLoading}
                      ReactGA={ReactGA}
                    />
                  </>
                ) : null}
              </React.Fragment>
            ) : null}
          </RouterHistory>
        </div>
        {hideNav ? null : live ? (
          <React.Fragment>
            {width < 870 && !deviceDetect().isBrowser ? null : <Footer />}
          </React.Fragment>
        ) : null}
      </SocketProvider>
    </React.Fragment>
  );
};

const RouterHistory = (props) => {
  // ReactGA.pageview(history.location.pathname);

  const online = useSelector((state) => state.auth.token);
  const userInfo = useSelector((state) => state.auth.userInfo);

  React.useEffect(() => {
    if (online) {
      ReactGA.set({ userId: userInfo._id });
    }
  }, [online]);

  return typeof window !== "undefined" ? (
    <TheRouter history={history}>{props.children}</TheRouter>
  ) : (
    <StaticRouter>{props.children}</StaticRouter>
  );
};

const AuthRoute = (props) => {
  const online = useSelector((state) => state.auth.token);
  const userInfo = useSelector((state) => state.auth.userInfo);

  if (!online) {
    return <Redirect to={{ pathname: "/", ReactGA }} />;
  } else {
    return (
      <Route exact path={props.path}>
        {props.children}
      </Route>
    );
  }
};

export const Loading = (props) => (
  <div
    style={{
      position: "relative",
      bottom: 0,
      justifySelf: "center",
      marginLeft: "auto",
      marginRight: "auto",
      zIndex: 30,
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "center",

      width: "100%",
    }}
  >
    <div
      style={{
        padding: 10,

        borderRadius: 20,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <img
        alt="logo"
        className={"App-logo"}
        src={"/logo.svg"}
        style={{ width: 100 }}
      />
    </div>
  </div>
);

export default App;
