import React, { forwardRef, useRef, useImperativeHandle } from "react";
import Colors from "../../constants/Colors";
import { useWindowSize } from "../../utils/customHooks";

import NumberFormat from "react-number-format";

import Messages from "./Messages";
import Alerts from "./Alerts";

const AlertsMsgs = forwardRef((props, ref) => {
  const messagesRef = useRef();
  const [width, height] = useWindowSize();
  // const [showTab, setShowTab] = React.useState(1);
  const [showTab, setShowTab] = React.useState(2);

  const [msgCount, setMsgCount] = React.useState(0);
  const [alertCount, setAlertCount] = React.useState(0);

  React.useEffect(() => {
    // console.log({ window });
    props.setTotalCount(msgCount + alertCount);
  }, [msgCount, alertCount]);

  useImperativeHandle(ref, () => ({
    refreshMsgs() {
      if (messagesRef) {
        if (messagesRef.current) {
          messagesRef.current.fetchChatsRef();
        }
      }
    },
    setTab(state) {
      if (state) setShowTab(state);
    },
  }));

  return (
    <React.Fragment>
      <div
        // className="shadow"
        style={{
          overflow: "hidden",
          //   borderRadius: 5,
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "rgba(0,0,0,0)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: width < 900 ? "center" : "space-around",
            marginBottom: -1,
            zIndex: 5,
          }}
        >
          <div
            style={{
              width: "30%",
              minWidth: 120,
              // backgroundColor: "white",
              backgroundColor: Colors.primary_darker,
              borderRadius: "5px 5px 0px 0px",
              opacity: showTab === 1 || width >= 900 ? 1 : 0.5,
              cursor: "pointer",
              border: "1px solid " + Colors.primary_darker,
              borderBottom: 0,
              position: "relative",
              // bottom: -1
            }}
            onClick={() => setShowTab(1)}
          >
            <p style={{ margin: 2, textAlign: "center", color: "white" }}>
              Alerts
            </p>
            <NotifyCounter count={alertCount} />
          </div>
          <div
            style={{
              width: "30%",
              minWidth: 120,
              // backgroundColor: "white",
              backgroundColor: Colors.primary_darker,
              borderRadius: "5px 5px 0px 0px",
              opacity: showTab === 2 || width >= 900 ? 1 : 0.5,
              cursor: "pointer",
              border: "1px solid " + Colors.primary_darker,
              borderBottom: 0,
              position: "relative",
              // bottom: -1
            }}
            onClick={() => setShowTab(2)}
          >
            <p style={{ margin: 2, textAlign: "center", color: "white" }}>
              Messages
            </p>
            <NotifyCounter count={msgCount} />
          </div>
        </div>

        <div
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            height: "100%",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            className="custom_scroll"
            style={{
              width:
                showTab === 2 && width < 900 ? 0 : width < 900 ? "100%" : "50%",
              height: width >= 900 || showTab === 1 ? "100%" : 0,
              backgroundColor: "white",
              overflowY: "auto",
              // maxHeight: 600,
              overflowX: "hidden",
              borderRadius: 5,
              marginRight: 5,
              marginLeft: 5,
              // paddingTop: 20,
              paddingRight: 0,
              // paddingBottom: 20,
              flex: "auto",
              border: "1px solid " + Colors.primary_darker,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Alerts
              setShow={props.setShow}
              show={props.show}
              setAlertCount={setAlertCount}
              chatId={props.chatId}
              alertId={props.alertId}
              setAlertId={props.setAlertId}
              setChatId={props.setChatId}
            />
          </div>

          <div
            style={{
              width:
                showTab === 1 && width < 900 ? 0 : width < 900 ? "100%" : "50%",
              height: width >= 900 || showTab === 2 ? "100%" : 0,
              backgroundColor: "white",
              overflowY: "auto",
              // maxHeight: 600,
              overflowX: "hidden",
              borderRadius: 5,
              marginRight: 5,
              marginLeft: 5,
              // paddingTop: 20,
              paddingRight: 0,
              // paddingBottom: 20,
              flex: "auto",
              border: "1px solid " + Colors.primary_darker,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Messages
              setShow={props.setShow}
              show={props.show}
              ref={messagesRef}
              setMsgCount={setMsgCount}
              chatId={props.chatId}
              alertId={props.alertId}
              setAlertId={props.setAlertId}
              setChatId={props.setChatId}
              msgFunction={props.msgFunction}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

const NotifyCounter = (props) => (
  <React.Fragment>
    {props.count > 0 ? (
      <div
        style={{
          position: "absolute",
          margin: 0,
          // bottom: -15,
          top: 0,
          right: 0,
          backgroundColor: Colors.good,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 20,
          minWidth: 20,
          minHeight: 20,
        }}
      >
        <p
          style={{
            color: Colors.primary,
            margin: 3,
            fontSize: 10,
            fontWeight: "bold",
          }}
        >
          <NumberFormat
            value={props.count}
            displayType={"text"}
            thousandSeparator={true}
          />
        </p>
      </div>
    ) : null}
  </React.Fragment>
);

export default AlertsMsgs;
