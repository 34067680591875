import React from "react";
import {
  TiSocialFacebook,
  TiSocialInstagram,
  TiSocialTwitter,
} from "react-icons/ti";

const SocialButtons = (props) => {
  return (
    <React.Fragment>
      <span
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          ...props.socialStyle,
        }}
      >
        <TiSocialFacebook
          style={{
            marginBottom: 5,
            fontSize: 20,
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => {
            console.log("FB");
          }}
        />
        <TiSocialInstagram
          style={{
            marginBottom: 5,
            fontSize: 20,
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => {
            console.log("INSTA");
          }}
        />
        <TiSocialTwitter
          style={{
            marginBottom: 5,
            fontSize: 20,
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => {
            console.log("TWEET");
          }}
        />
      </span>
    </React.Fragment>
  );
};

export default SocialButtons;
