import { useContext } from "react";

import { SocketContext } from "./SocketProvider";

/**
 * Returns an object containing socket information and utility functions.
 *
 * @returns {{
 *   socketId: string,
 *   connected: boolean,
 *   socket: Socket,
 *   socketEmitListen: (event: string, data: any, callback: (data?: any) => void) => void,
 *   socketListenOn: (event: string, callback: (data?: any) => void) => void,
 *   socketListenOff: (event: string) => void
 * }}
 */
export const useSocket = () => {
  const socketTools = useContext(SocketContext);

  return socketTools;
};
