import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../constants/Colors";

import { callServer } from "../../store/helpers/actionHelper";
// import { socket } from "../../config/config";
import moment from "moment";

const FetchUser = (props) => {
  const dispatch = useDispatch();
  const online = useSelector((state) => state.auth.token);
  const darkMode = useSelector((state) => state.display.darkMode);

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    // console.log({ token: JSON.parse(token) });
    getPermissions();
    if (token) {
      validateUser(JSON.parse(token));
    } else {
      props.setReady(true);
    }
  }, []);

  // Fetch Abort
  // useEffect(() => {
  //   const fetchController = new AbortController();
  //   const signal = fetchController.signal;

  //   fetch("https://server.bidsquawk.com/server", { signal })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       // console.log({ res });
  //     })
  //     .catch((err) => {
  //       console.log({ err });
  //     });

  //   return () => {
  //     // fetchController.abort();
  //   };
  // }, []);

  const validateUser = async (token) => {
    try {
      const resData = await callServer({
        apiMethod: "get",
        endPoint: `server/auth/get-user-info`,
        token: token,
      });

      // console.log({ fetchedInfo: resData });

      // if (resData.userInfo.location) {
      //   props.setSearchData({
      //     ...props.searchData,
      //     location: {
      //       title: resData.userInfo.location.title,
      //       value_0: resData.userInfo.location.value_0,
      //       value_1: "all",
      //     },
      //   });
      // }

      dispatch({
        type: "AUTH",
        valid: resData.valid,
        userId: resData.userId,
        tokenInfo: resData.tokenInfo,
        userInfo: resData.userInfo,
        token: resData.token,
      });
    } catch (err) {
      console.log({ err });
    }
    props.setReady(true);
  };

  const getPermissions = async () => {
    try {
      // alert("TESTING");
      const audio = await navigator.permissions.query({ name: "midi" });
      // alert(JSON.stringify(audio));

      const permissions = await navigator.mediaDevices.enumerateDevices();
      // alert(JSON.stringify(permissions));

      // console.log({ permissions, audio });
    } catch (err) {
      console.log({ err });
    }
  };

  if (props.ready && props.statLoad) {
    return <React.Fragment />;
  } else {
    return (
      <React.Fragment>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: window.innerHeight,
            zIndex: 70,
            backgroundColor: darkMode ? Colors.darkBackground : Colors.primary,
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
          }}
        >
          <img
            alt="logo"
            className={"App-logo"}
            src={"/logo.svg"}
            style={{ maxWidth: 300 }}
          />
        </div>
      </React.Fragment>
    );
  }
};

export default FetchUser;
