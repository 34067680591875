/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { createContext, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// import { SERVER_URL } from "./config";
// import { updateSocketId } from "../store/actions/auth_action";
// import { useIsMountedRef } from "../utils/customHooks";

import io from "socket.io-client";

import { SERVER_URL } from "../config/config";
import { updateSocketId } from "../store/actions/auth_action";
import { useIsMountedRef } from "../utils/customHooks";

export const TIMEOUT = 8000;

const SocketContext = createContext(null);

const SocketProvider = (props) => {
  const isMount = useIsMountedRef();
  const dispatch = useDispatch();

  const socket = useRef({ id: "", connected: false, disconnected: true });

  const socketEmitListen = useRef(null);
  const socketListenOn = useRef(null);
  const socketListenOff = useRef(null);

  const online = useSelector((state) => !!state.auth.token);
  const storedSocket = useSelector((state) => state.socket.connection);

  const [socketId, setSocketId] = React.useState("");
  const [masterTab, setMasterTab] = React.useState(false);

  socketListenOn.current = useCallback(
    (event, callBack) => {
      if (!online) return;
      if (!!socket.current.id && event) {
        socket.current.on(event, (response) => callBack(response));
      }
    },
    [online]
  );

  socketListenOff.current = useCallback(
    (event) => {
      if (!online) return;
      if (!!socket.current.id && event) {
        socket.current.off(event);
      }
    },
    [online]
  );

  socketEmitListen.current = useCallback(
    (event, data, callBack) => {
      if (!online) return;
      if (!!socket.current.id && event) {
        socket.current.emit(event, data, (response) => {
          callBack(response);
        });
      }
    },
    [online]
  );

  React.useEffect(() => {
    if (!props.ntn) return;

    window.addEventListener("socketListenOn", (event_1, callBack_1) => {
      socketListenOn.current = (event = event_1, callBack = callBack_1) => {
        if (!!socket.current.id && event) {
          socket.current.on(event, (response) => callBack(response));
        }
      };
    });

    window.addEventListener("socketListenOff", (event_1) => {
      socketListenOff.current = (event = event_1) => {
        if (!!socket.current.id && event) {
          socket.current.off(event);
        }
      };
    });

    window.addEventListener(
      "socketEmitListen",
      (event_1, data_1, callBack_1) => {
        socketEmitListen.current = (
          event = event_1,
          data = data_1,
          callBack = callBack_1
        ) => {
          if (!!socket.current.id && event) {
            socket.current.emit(event, data, (response) => {
              callBack(response);
            });
          }
        };
      }
    );

    return () => {
      window.removeEventListener("socketListenOn", () => {});
      window.removeEventListener("socketListenOff", () => {});
      window.removeEventListener("socketEmitListen", () => {});
    };
  }, [online]);

  React.useEffect(() => {
    if (!isMount.current) return;

    online
      ? updateSocketId(socket.current.id)
          .then(() => {})
          .catch((err) => {
            console.log("Socket Comp ERR", { err });
          })
      : null;

    return () => {};
  }, [socket.current.id, online]);

  React.useEffect(() => {
    socket.current = online
      ? io(SERVER_URL, {
          path: "/bidSquawk_socket/",
          withCredentials: true,
          secure: true,
          transports: ["websocket"],
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionAttempts: Number.MAX_SAFE_INTEGER,
          autoConnect: true,
          cookie: true,
        })
      : { id: "" };

    var connect = online
      ? socket.current.on("connect", async () => {
          console.log("WebSocket Connected - ", socket.current.id);
          setSocketId(socket.current.id);
          localStorage.setItem("socketId", socket.current.id);
          updateSocketId(socket.current.id)
            .then((resData) => {
              console.log(
                "Registering socket.current with user's session - ",
                socket.current.id,
                {
                  resData,
                  socket,
                }
              );
            })
            .catch((err) => {
              console.log("Socket Comp ERR", { err });
            });
        })
      : null;

    return () => {
      if (socketId) localStorage.removeItem("socketId");
      connect = () => {};
      // Pass Socket Connection in Redux (Can work but not recommended)
      // if (storedSocket && masterTab) {
      //   dispatch({
      //     type: "DISCONNECT_SOCKET",
      //   });
      // }
      // //console.log(`Disconnecting socket.current: ${socket.current.id}`);
      socket.current.disconnect ? socket.current.disconnect() : null;
    };
  }, [online]);

  React.useEffect(() => {
    // console.log({ SOCKET: socket });
    if (socket.current.connected && !socket.current.disconnected) {
      dispatch({
        type: "CONNECT_SOCKET",
      });
    } else {
      dispatch({
        type: "DISCONNECT_SOCKET",
      });
    }
  }, [socket.current.connected]);

  return (
    <SocketContext.Provider
      value={{
        socketId: socket.current.id,
        connected: socket.current.connected,
        socket: socket.current,
        socketEmitListen: socketEmitListen.current,
        socketListenOn: socketListenOn.current,
        socketListenOff: socketListenOff.current,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
