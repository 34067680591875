/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import CategoryList from "./CatList";
import LocationSearch from "./LocationSearch";

import { FaSearch } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { VscLoading } from "react-icons/vsc";

import { useHistory } from "react-router-dom";

import { useWindowSize } from "../utils/customHooks";
import Colors from "../constants/Colors";

import { useSelector } from "react-redux";

const Search = (props) => {
  const history = useHistory();
  const [width, height] = useWindowSize();

  const userInfo = useSelector((state) => state.auth.userInfo);
  const online = useSelector((state) => state.auth.token);

  const [locationReady, setLocationReady] = React.useState(false);
  const [categoryReady, setCategoryReady] = React.useState(false);

  const [showCat, setShowCat] = React.useState(false);
  const [showLoc, setShowLoc] = React.useState(false);
  const [showList, setShowList] = React.useState(false);

  const [search, setSearch] = React.useState(props.searchData.search);
  const [value, setValue] = React.useState(props.searchData.category);

  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  var refreshCatString = JSON.stringify(props.searchData.category);

  React.useEffect(() => {
    setValue(props.searchData.category);
  }, [refreshCatString]);

  const showCategory = () => {
    if (width < 667) {
      setShowCat(true);
      const ele = document.getElementById("navCatList");
      if (ele) {
        // console.log({ ele });
        ele.style.height = "40px";
        ele.style.overflow = "visible";
        setTimeout(() => {
          ele.style.opacity = 1;
        }, 300);
      }
    }
  };

  const changeValue = (value) => {
    setValue(value);
  };

  const hideCategory = () => {
    setShowCat(false);
    if (width < 667) {
      const ele = document.getElementById("navCatList");
      if (ele) {
        // console.log({ ele });
        ele.style.opacity = 0;
        setTimeout(() => {
          ele.style.height = 0;
          ele.style.overflow = "hidden";
        }, 200);
      }
    }
  };

  const engageSearch = () => {
    if (!props.loading && categoryReady && value && props.searchData.location) {
      // if (props.online) {
      if (true) {
        history.push(`/search/${search || "all"}`);
        hideCategory();
        setShowLoc(false);
        setShowCat(false);
        setShowList(false);
        if (props.searchRef.current) {
          props.searchRef.current.startSearch(Date.now());
        }
      } else {
        props.setShowAuth(1);
      }
    }
  };

  React.useEffect(() => {
    props.setMounted(true);
    props.setSearchData({
      ...props.searchData,
      search,
      category: value,
    });
  }, [search, value]);

  return (
    <React.Fragment>
      <div
        style={{
          //   width: "100%",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: width > 667 ? "row" : "column",
          justifyContent: "center",
          alignItems: "center",
          //   marginBottom: width < 667 ? 10 : 0,
          //   marginTop: width < 700 ? 10 : 0,
          //   marginTop: width < 667 ? 3 : 0,
          marginLeft: "auto",
          marginRight: "auto",
          opacity: categoryReady ? 1 : 0.3,
          zIndex: 56,
          flex: "auto",

          //   height: "100%",
        }}
        onFocus={() => {
          showCategory();
          // console.log("FOCUS!!!!");
        }}
        // onBlur={() => {
        //   hideCategory();
        //   console.log("LEAVE");
        // }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            minHeight: 20,
            // marginTop: width < 612 ? 10 : 0,
            // marginBottom: width < 667 ? 10 : 0,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <div
            id={!props.loading ? "locationBtn" : ""}
            style={{
              backgroundColor: "white",
              // borderRight: "1px solid #ccc",
              borderRadius: "20px 0px 0px 20px",
              padding: 5,
              height: 30,
              minHeight: 20,
              width: 40,
              // fontSize: 15,
              position: "relative",
              cursor: "pointer",
              opacity: props.loading ? 0.8 : 1,
            }}
            onClick={() => {
              if (!props.loading && categoryReady) {
                hideCategory();
                setShowLoc((state) => !state);
              }
            }}
          >
            {showLoc && categoryReady ? (
              <IoMdClose
                color={"#ccc"}
                style={{
                  width: "100%",
                  marginBottom: 5,
                  fontSize: 20,
                  color: Colors.primary,
                }}
              />
            ) : (
              <MdLocationOn
                color={"#ccc"}
                style={{
                  width: "100%",
                  marginBottom: 5,
                  fontSize: 20,
                  color: Colors.primary,
                }}
              />
            )}
          </div>
          <div style={{ position: "relative" }}>
            <input
              type="text"
              name="item search"
              maxLength="120"
              placeholder="Search - Items & Users"
              value={search}
              onBlur={onBlur}
              onChange={(value) => {
                if (focused) {
                  setSearch(value.target.value);
                }
              }}
              disabled={props.loading}
              style={{
                backgroundColor: "white",
                transition: "300ms",
                width: width >= 1200 ? 300 : width < 450 ? 130 : 220,
                // width: "100%",
                outline: "none",
                border: "0px solid #ccc",
                borderRadius: "0px 0px 0px 0px",
                fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                lineHeight: 1,
                padding: 5,
                // fontSize: 15,
                height: 30,
                minHeight: 20,
                // transition: "1s",
                opacity: props.loading ? 0.8 : 1,
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  engageSearch();
                }
              }}
              onFocus={() => {
                setShowLoc(false);
                setShowCat(false);
                setShowList(false);
                onFocus();
              }}
            />
            <LocationSearch
              visible={showLoc}
              setVisible={setShowLoc}
              // width={width}
              location={props.searchData.location}
              changeLocation={(value) => {
                props.setSearchData({
                  ...props.searchData,
                  location: value,
                });
              }}
              mounted={props.mounted}
            />
          </div>
          <div
            id={
              props.loading ||
              !value ||
              !props.searchData.location ||
              !categoryReady
                ? ""
                : "searchBtn"
            }
            style={{
              backgroundColor: "white",
              // borderLeft: "1px solid #ccc",
              borderRadius: "0px 20px 20px 0px",
              padding: 5,
              height: 30,
              minHeight: 20,
              width: 40,
              // fontSize: 15,
              position: "relative",
              cursor: "pointer",
              opacity:
                props.loading || !value || !props.searchData.location ? 0.5 : 1,
            }}
            onClick={engageSearch}
          >
            {props.loading ? (
              <VscLoading
                className={"Spin-logo"}
                color={"#ccc"}
                style={{
                  fontSize: 18,
                  color: Colors.primary,
                  marginBottom: 5,
                  marginLeft: 5,
                }}
              />
            ) : (
              <FaSearch
                color={"#ccc"}
                style={{
                  fontSize: 18,
                  color: Colors.primary,
                  marginBottom: 5,
                  marginLeft: 5,
                }}
              />
            )}
          </div>
        </div>
        <div
          id={"navCatList"}
          style={{
            opacity: width > 667 ? 1 : 0,
            transition: "500ms",
            height: width > 667 ? 30 : 0,
            overflow: width > 667 ? "visible" : "hidden",
          }}
        >
          <div
            style={{
              // marginLeft: 20,
              // marginRight: 20,
              marginTop: width < 667 ? 10 : 0,
            }}
          >
            <CategoryList
              searchData={props.searchData}
              loading={props.loading}
              setLoading={props.setLoading}
              width={
                width >= 1200
                  ? 240
                  : width < 380
                  ? 210
                  : width < 700
                  ? 200
                  : 240
              }
              value={value}
              setValue={(value) => changeValue(value)}
              setCategoryReady={setCategoryReady}
              showList={showList}
              setShowList={() => setShowList((state) => !state)}
              mounted={props.mounted}
            />
          </div>
        </div>
      </div>
      {showCat ? (
        <div
          style={{
            position: "absolute",
            zIndex: 55,
            backgroundColor: "transparent",
            width: "100%",
            height: height,
            left: 0,
            top: 0,
          }}
          onClick={hideCategory}
        />
      ) : null}
      {/* <div
        style={{
          position: "absolute",
          zIndex: 55,
          backgroundColor: "transparent",
          width: "100%",
          height: height,
          left: 0,
          top: 0,
        }}
        // onClick={hideCategory}
      ></div> */}
    </React.Fragment>
  );
};

export default Search;
