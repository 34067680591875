/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable default-case */
const initialState = {
  live: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_CONNECTION":
      return {
        live: action.live,
      };
  }
  return state;
};
