// Validations
//
export const validateEmail = async (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const passwordValidate = async (password) => {
  const res = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{7,50}$/;
  return res.test(String(password));
};

export const phoneValidator = async (phone) => {
  // console.log({ phone });
  try {
    if (!phone || String(phone).length !== 10) {
      return false;
    }
    if (!Number.isInteger(parseInt(phone))) {
      return false;
    }
    if (isNaN(phone)) {
      return false;
    }
    // if (parseInt(phone.slice(0, 3)) !== 876) {
    //   return false;
    // }
    return true;
  } catch (err) {
    return false;
  }
};

export const urlValidator = async (url) => {
  if (!url || url.length < 3) return false;

  const res = RegExp(
    "(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[;&a-z\\d%_.~+=-@]*)?(\\#[-a-z\\d_@]*)?$",
    "i"
  );

  return res.test(String(url));
};

export const verifyToken = async (token) => {
  if (!token || token.length !== 6) {
    return false;
  }
  if (isNaN(token)) {
    return false;
  }
  if (!Number.isInteger(parseInt(token))) {
    return false;
  }

  return true;
};

export const compareValidator = async (a, b) => {
  if (a !== b) {
    return false;
  }
  return true;
};

export const isEmpty = async (value) => {
  if (value.length <= 0) {
    return false;
  }
  return true;
};

export const sortLocation = (appLocations, location) => {
  try {
    const pasredLocation = { value_0: location.value_0 };
    console.log({ appLocations });
    if (pasredLocation) {
      const value_0_values = appLocations.find(
        (x) => x.countryCode === pasredLocation.value_0
      );

      pasredLocation.value_1 = location.value_1;
      if (value_0_values) {
        const value_1_values = value_0_values.locations.find(
          (x) => x.value_1 === pasredLocation.value_1
        );

        if (value_1_values) {
          pasredLocation.title = value_1_values.title;

          if (location.value_2) {
            pasredLocation.value_2 = {
              value_2: location.value_2,
            };
            const value_2_values = value_1_values.value_2.find(
              (x) => x.value_2 === pasredLocation.value_2.value_2
            );

            if (value_2_values) {
              pasredLocation.value_2.title = value_2_values.title;

              if (location.value_3) {
                pasredLocation.value_2.value_3 = {
                  value_3: location.value_3,
                };

                const value_3_values = value_2_values.value_3.find(
                  (x) => x.value_3 === pasredLocation.value_2.value_3.value_3
                );

                if (value_3_values) {
                  pasredLocation.value_2.value_3.title = value_3_values.title;
                }
              }
            }
          }
        }
      }

      return pasredLocation;
    }
  } catch (err) {
    console.log({ err });
    return false;
  }
};

export function formatNumberWithCommas(number) {
  // Convert the number to a string and split it into integer and decimal parts
  const [integerPart, decimalPart] = number.toString().split(".");

  // Use a regular expression to add commas to the integer part
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  // Return the formatted number, including the decimal part if it exists
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
}

export function camelCaseToSentence(camelCaseString) {
  // Split the camel case string into words
  const words = camelCaseString.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

  // Capitalize each word and join them with spaces
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
