/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, forwardRef, useImperativeHandle } from "react";

import { useLocation, useParams, useHistory } from "react-router-dom";

import ModalView from "../ModalView";
import { Helmet } from "react-helmet";
import { callServer } from "../../store/helpers/actionHelper";

import {
  useIsMountedRef,
  useWindowSize,
  useMemoryState,
} from "../../utils/customHooks";

import { newItems } from "../../assets/data";
import Colors from "../../constants/Colors";

import { v4 as uuidv4 } from "uuid";

import InfinityScroll from "../InfinityScroll";

const Alerts = forwardRef((props, ref) => {
  const [width, height] = useWindowSize();
  const isMountRef = useIsMountedRef();

  const url_search = useLocation();
  const history = useHistory();
  const params = useParams();

  const [data, setData] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [alerts, setAlerts] = useMemoryState(
    "alerts",
    new Array(25).fill({ _id: uuidv4() }).map((x) => (x.x = { _id: uuidv4() }))
  );

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useImperativeHandle(ref, () => ({
    doAction() {
      console.log("Template");
    },
  }));

  const loadMore = async () => {
    try {
      setLoading(true);
      setTimeout(() => {
        let x = new Array(25)
          .fill({ _id: uuidv4() })
          .map((x) => (x.x = { _id: uuidv4() }));
        setAlerts([...alerts, ...x]);
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <React.Fragment>
      <ModalView
        style={{ maxWidth: 600 }}
        uniqueId={"AuthModal"}
        disable={false}
        visible={showModal}
        loading={loading}
        leftIcon={false}
        leftIconAction={() => {}}
        close={() => setShowModal(false)}
      ></ModalView>
      <div
        style={{
          // position: "sticky",
          top: 0,
          height: 80,
          width: "100%",
          backgroundColor: Colors.primary_darker,
        }}
      ></div>
      <InfinityScroll
        list={alerts}
        hasMore={true}
        loadMore={loadMore}
        loading={loading}
        reverse={false}
        style={{}}
        loadComp={() => <Loading style={{ top: null, bottom: 5 }} />}
      >
        {alerts.map((x, index) => (
          <div
            key={index}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 5,
              marginBottom: 5,
              width: "98%",
              height: 50,
              backgroundColor: Colors.harmony_4,
              borderRadius: 5,
            }}
          />
        ))}
      </InfinityScroll>
    </React.Fragment>
  );
});

const Loading = (props) => (
  <div
    style={{
      position: "absolute",
      top: 5,
      justifySelf: "center",
      marginLeft: "auto",
      marginRight: "auto",
      zIndex: 30,
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "center",

      width: "100%",
      ...props.style,
    }}
  >
    <div
      style={{
        padding: 10,

        borderRadius: 20,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <img
        alt="logo"
        className={"App-logo"}
        src={"/logo.svg"}
        style={{ width: 50 }}
      />
    </div>
  </div>
);

export default Alerts;
